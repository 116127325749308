// api
import axioApi, { setHeader } from 'api/root.api';

// plugin
import Cookies from 'js-cookie';

export const glbModelUpload = async (formData, projectId, modelId) => {
    const urlEnd = `/editor/project/v1/${projectId}/${modelId}/uploadGlb`;
    const response = await axioApi.post(urlEnd, formData);
    const result = response.data;
    return result;
};

export const modelUpload = async (body, token, projectId, modelId)=>{
    const urlEnd = `/editor/project/v1/${projectId}/${modelId}/uploadGltf`;
    // console.log(body);
    // setHeader('Access-Control-Allow-Origin', '*');
    setHeader('Authorization', `Bearer ${token}`);
    setHeader('Content-Type', 'multipart/form-data');
    // setHeader('Authorization', `Bearer ${token}`);
    const response = await axioApi.post(urlEnd, body);
    const result = response.data;
    return result;
};


export const getModelList = (projectId) => async () => {
    try {
        const urlEnd = `/editor/project/v1/${projectId}/modelList`;
        const token = Cookies.get('token');
        setHeader('Authorization', `Bearer ${token}`);
        const response = await axioApi.get(urlEnd);
        const result = response.data;
        //console.log('getModelList', result[0].tags, projectId, result[0].id);
        return result;
    } catch (error) {
        return {
            result: false,
            status: 'failed',
        };
    }
};

export const postTagSwitch = async (data, projectId, modelId) => {
    try {
        const urlEnd = `/editor/project/v1/${projectId}/${modelId}/updateModel`;
        const token = Cookies.get('token');
        const body = {
            tagSwitch: data,
        };
        setHeader('Authorization', `Bearer ${token}`);
        const response = await axioApi.post(urlEnd, body);
        const result = response.data;
        return result;
    } catch (error) {
        return {
            result: false,
            status: 'failed',
        };
    }
};
