import { 
    NEW_TAG_PANEL, 
    NEW_TAG_INPUT,
    NEW_TAG_CONTENT,
    NEW_TAG_URL,
    NEW_TAG_IMAGE_FILE,
    CLEAR_NEW_TAG_PANEL,
    OLD_TAG_PANEL,
    OLD_TAG_TITLE,
    OLD_TAG_CONTENT,
    OLD_TAG_URL,
    OLD_TAG_IMAGE_FILE,
    OLD_TAG_DELETE_IMAGE_FILE,
    CLEAR_OLD_TAG_PANEL,
    EDITING_TAGS,
    REMOVE_EDITING_TAG,
    EMPTY_EDITING_TAGS,
    REMOVE_NEW_TAG,
    SIDEBAR_EDIT_TAG,
    CHANGE_TAG,
} from 'redux/types/tag.types';

let INIT_TAG_PANEL = {
    newTags: [],
    oldTags: [],
    editingTags: [],
    sidebarEditingTag: {
        serialNumber: null,
        tag: {},
    },
};


const tagPanelReducer = (state = INIT_TAG_PANEL, action) => {

    const updateInput = ({ targetTags, targetInput, payload, targetId, filename }) => {
    
        const updatedInput = targetTags.map(tag => {
            if (tag.id === targetId) {
                tag[targetInput] = payload;
                if (targetInput === 'imageFile') {
                    tag.filename = filename ? filename : '';
                }
            }
            return tag;
        });

        return updatedInput;
    };
    
    
    switch (action.type) {
        // editing tags
        case SIDEBAR_EDIT_TAG:
            return {
                ...state, sidebarEditingTag: { ...action.payload },
            };

        case EDITING_TAGS:
            return {
                ...state, editingTags: [...state.editingTags, action.payload],
            };

        case REMOVE_EDITING_TAG:
            return {
                ...state, editingTags: [...action.payload],
            };

        case EMPTY_EDITING_TAGS:
            return {
                ...state, editingTags: action.payload,
            };

        // old tags
        case OLD_TAG_PANEL:
            return {
                ...state, oldTags: [ ...state.oldTags, ...action.payload],
            };
        
        case OLD_TAG_TITLE:
            const updateTitleSetting = {
                targetTags: state.oldTags,
                targetInput: 'title',
                payload: action.payload.inputValue,
                targetId: action.payload?.tagId,
            };
            const getUpdatedTitle = updateInput(updateTitleSetting);
            return {
                ...state, oldTags: [...getUpdatedTitle],
            };
        
        case OLD_TAG_CONTENT:
            const updateContentSetting = {
                targetTags: state.oldTags,
                targetInput: 'content',
                payload: action.payload.inputValue,
                targetId: action.payload?.tagId,
            };
            const getUpdatedContent = updateInput(updateContentSetting);

            return {
                ...state, oldTags: [...getUpdatedContent],
            };

        case OLD_TAG_URL:
            const updateUrlSetting = {
                targetTags: state.oldTags,
                targetInput: 'url',
                payload: action.payload.inputValue,
                targetId: action.payload?.tagId,
            };
            const getUpdatedUrl = updateInput(updateUrlSetting);

            return {
                ...state, oldTags: [...getUpdatedUrl],
            };

        case OLD_TAG_IMAGE_FILE:
            const updateImage64Setting = {
                targetTags: state.oldTags,
                targetInput: 'imageFile',
                payload: action.payload.inputValue,
                targetId: action.payload?.tagId,
                filename: action.payload?.filename,
            };
            const getUpdatedImage64 = updateInput(updateImage64Setting);

            return {
                ...state, oldTags: [...getUpdatedImage64],
            };

        case OLD_TAG_DELETE_IMAGE_FILE:
            const deleteImageSetting = {
                targetTags: state.oldTags,
                targetInput: 'icon',
                payload: '',
                targetId: action.payload?.tagId,
                filename: '',
            };
            const deleteImage = updateInput(deleteImageSetting);
    
            return {
                ...state, oldTags: [...deleteImage],
            };

        case CLEAR_OLD_TAG_PANEL:
            return {
                ...state, oldTags: action.payload,
            };
    


        // new tags
        case NEW_TAG_PANEL:
            return {
                ...state, newTags: [ ...state.newTags, action.payload],
            };

        case NEW_TAG_INPUT:
            const getInputOrderNumber = action.payload?.orderNumber;
            const tagInputUpdate = state.newTags.map(tag => {
                if (tag.orderNumber === getInputOrderNumber) {
                    tag.title = action.payload.title;
                }

                return tag;
            });

            return {
                ...state, newTags: [...tagInputUpdate],
            };

        case NEW_TAG_CONTENT:
            const getContentOrderNumber = action.payload.orderNumber;
            const tagContentUpdate = state.newTags.map(tag => {
                if (tag.orderNumber === getContentOrderNumber) {
                    tag.content = action.payload.content;
                }

                return tag;
            });

            return {
                ...state, newTags: [ ...tagContentUpdate],
            };
        case NEW_TAG_URL: {
            const getUrlOrderNumber = action.payload.orderNumber;
            const tagUrlUpdate = state.newTags.map(tag => {
                if (tag.orderNumber === getUrlOrderNumber) {
                    tag.url = action.payload.url;
                }
                return tag;
            });

            return {
                ...state, newTags: [ ...tagUrlUpdate],
            };
        }

        case NEW_TAG_IMAGE_FILE: {
            const getImageFileOrderNumber = action.payload.orderNumber;
            const tagUrlUpdate = state.newTags.map(tag => {
                if (tag.orderNumber === getImageFileOrderNumber) {
                    tag.imageFile = action.payload.imageFile;
                }
                return tag;
            });


            return {
                ...state, newTags: [ ...tagUrlUpdate],
            };
        }

        case CLEAR_NEW_TAG_PANEL:
            return {
                ...state, newTags: action.payload,
            };

        case REMOVE_NEW_TAG: {
            const rmOrderNumber = action.payload;
            const updateNewTags = state.newTags.filter( tag => +tag.orderNumber !== rmOrderNumber);
            return {
                ...state, newTags: [...updateNewTags],
            };
        }

        case CHANGE_TAG: {
            //console.log('CHANGE_TAG1', action, state);
            const newTags = state.newTags.map(tag => {
                if (tag.orderNumber === action.payload.orderNumber) {
                    //console.log('CHANGE_TAG2', tag);
                    tag.dataPosition = action.payload.dataPosition;
                    tag.dataNormal = action.payload.dataNormal;
                    tag.cameraOrbit = action.payload.cameraOrbit;
                    tag.cameraTarget = action.payload.cameraTarget;
                }
                return tag;
            });
            const oldTags = state.oldTags.map(tag => {
                if (tag.id === action.payload.id) {
                    tag.dataPosition = action.payload.dataPosition;
                    tag.dataNormal = action.payload.dataNormal;
                    tag.cameraOrbit = action.payload.cameraOrbit;
                    tag.cameraTarget = action.payload.cameraTarget;
                }
                return tag;
            });
            return {
                ...state, newTags: newTags, oldTags: oldTags,
            };
        }

        default: return state;
    }
};

export default tagPanelReducer;