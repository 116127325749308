import { IS_SAVE } from 'redux/types/isSave.types';

let IS_SAVE_RES = [];

const isSaveReducer = (state = IS_SAVE_RES, action) => {
    switch (action.type) {
        case IS_SAVE:
            return [action.payload];
        default: return state;
    }
};

export default isSaveReducer;