import { USER_PROJECT_NUM } from 'redux/types/userProjectNum.types';

let USER_PROJECT_NUM_RES = {
    userProjectNum: '',
};

const userProjectNumReducer = (state = USER_PROJECT_NUM_RES, action) => {
    switch (action.type) {
        case USER_PROJECT_NUM:
            return {
                ...state, userProjectNum: action.payload,
            };
        default: return state;
    }
};

export default userProjectNumReducer;