import { ICON } from 'redux/types/icon.types';

let ICON_RES = {
    icon: '',
};

const iconReducer = (state = ICON_RES, action) => {
    switch (action.type) {
        case ICON:
            return {
                ...state, icon: action.payload,
            };
        default: return state;
    }
};

export default iconReducer;