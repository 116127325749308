// react
import React, { useState, useEffect, useRef } from 'react';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { SWITCH } from 'redux/types/switch.types';
import { MODEL_IS_LOADING } from 'redux/types/model.types';
import { PERMISSION } from 'redux/types/permission.types';

//hdr file
import HDR_FILE from 'constants/hrd';

// styled components
import { StyledPreview } from './StyledPreview';
import { ReactComponent as UrlIcon } from './images/link.svg';

// components
import QRcode from 'components/QRcode/QRcode';
import ModalPopup from 'components/ModalPopup/ModalPopup';
import AnimateController from 'components/AnimateController/AnimateController';

// api
import { getModelList } from 'api/getModelList/getModelList';
import { getGlobalSwitch } from 'api/globalSwitch/globalSwitch.api';

//plugin
import clsx from 'classnames';
import '@google/model-viewer/dist/model-viewer';
import Cookies from 'js-cookie';
import * as dayjs from 'dayjs';

// utils
import { handleCameraMovement } from 'utils/customFunctions';

//img
// import model from './images/model.jpg';
import z1 from './images/1.svg';
import z2 from './images/2.svg';
import r1 from './images/3.svg';
import r2 from './images/4.svg';
import m1 from './images/5.svg';
import m2 from './images/6.svg';
import cFull from './images/cFull.svg';
import defaultModel from './images/defaul.svg';
import { ReactComponent as FullOpen } from './images/full.svg';
import { ReactComponent as FullClose } from './images/full-close.svg';
import { ReactComponent as TouchIcon } from './images/touch.svg';
import { ReactComponent as ArIcon } from './images/Ar.svg';
import check from './images/check.svg';
import namIcon from './images/namIcon.svg';
import tagIcon from './images/tagIcon.svg';


//i18n
import { useTranslation } from 'react-i18next';

// hooks
import useModal from 'hooks/useModal';

// config
import { modalConfig } from 'config/modal';

// react bootstrap
import ProgressBar from 'react-bootstrap/ProgressBar';

const Preview = (props) => {

    const projectId = Cookies.get('projectId');
    const store = useSelector(store => store);
    const dispatch = useDispatch();
    const [modelInfo, setModel] = useState([]);
    const { modalSetting, setModalSetting } = useModal();
    const { t, i18n } = useTranslation();
    const { modelListIsLoading, netStatus, refetchModelList, modelList } = props;

    const [hotspots, setHotspots] = useState([]);
    const [currentHotspot, setCurrentHotspot] = useState(null);
    const [sectionType, setSectionType] = useState('');
    const [xSectionType, setXSectionType] = useState('');
    const [smallPic, setSmallPic] = useState([]);
    const [showAr, setShowAr] = useState(false);

    const [modelDragged, setModelDragged] = useState(false);

    const onlyTitle = !currentHotspot?.content
        && !currentHotspot?.icon
        && !currentHotspot?.url;

    const fullCard = currentHotspot?.title
        && currentHotspot?.content
        && currentHotspot?.icon
        || currentHotspot?.url;

    const onlyTitleDesc = currentHotspot?.title
        && currentHotspot?.content
        && !currentHotspot?.icon
        && !currentHotspot?.url;

    const onlyTitleImage = currentHotspot?.title
        && !currentHotspot?.content
        && currentHotspot?.icon
        && !currentHotspot?.url;

    const decOver36 = currentHotspot?.content.length >= 36;
    useEffect(() => {
        if (!modelListIsLoading) {
            setModel(modelList);
            handleCameraMovement('preview');
        }
    }, [modelListIsLoading]);

    useEffect(() => {
        if (modelInfo?.length > 0) {
            let checkModelId = false;
            for (let item of modelInfo) {
                if (item.id === parseFloat(Cookies.get('modelId'))) {
                    checkModelId = true;
                }
            }
            if (!checkModelId) {
                return;
            }
            // console.log('here', modelInfo?.filter(model=>model.id == parseFloat(Cookies.get('modelId')))[0]);
            // console.log(modelInfo);
            // setHotspots(modelInfo[0]?.tags);
            let modelRes = modelInfo?.filter(model => model.id === parseFloat(Cookies.get('modelId')))[0];
            setHotspots(modelRes?.tags);
            setShowAr(modelRes?.viewModeSwitch);
            let sortAry = modelInfo.sort((a, b) => {
                return dayjs(b.lastUpdated).unix() - dayjs(a.lastUpdated).unix();
            });

            // console.log('usdz', modelRes?.usdzSrc, 'glb', modelRes?.glbSrc, 'gltf', modelRes?.gltfSrc);

            for (let i = 0; i < sortAry.length; i++) {
                if (sortAry[i].mainObject === true) {
                    let mainTemp = sortAry[i];
                    sortAry[i] = sortAry[0];
                    sortAry[0] = mainTemp;
                }
            }
            setSmallPic(sortAry);
        } else {
            setModalSetting({
                ...modalSetting,
                show: true,
                title: '',
                type: 'type12',
                coverSetting: true,
                handleOtherAction: true,
                otherBtnText: t('PleaseTryAgain'),
                handleConfirm: () => {
                    window.location.reload();
                },
            });
            return;
        }
        // console.log('hotspots', modelInfo);
    }, [modelInfo]);

    const handleHideInfoCard = () => {
        if (modelDragged === true) {
            setModelDragged(false);
            return;
        }

        document.querySelector('#interaction-prompt').setAttribute('slot', 'interaction-prompt');
        document.querySelector('#myViewer').setAttribute('interaction-prompt-style', 'basic');
        setCurrentHotspot(null);
    };

    const handleMouseMove = (MouseEvent) => {
        if (MouseEvent.buttons % 2 === 1) {
            if (modelDragged === false) {
                setModelDragged(true);
            }
        }
    };

    const handleDoubleClick = () => handleHideInfoCard();

    const handleClickHotspot = (tag, spotId) => (e) => {
        if (tag.id === currentHotspot?.id) {
            setCurrentHotspot(null);
            return;
        }
        document.querySelector('#interaction-prompt').setAttribute('slot', 'interaction-prompt');
        document.querySelector('#myViewer').setAttribute('interaction-prompt-style', 'basic');
        e.stopPropagation();
        const getSpot = document.querySelector(`#${spotId}`).getBoundingClientRect();
        const halfW = window.innerWidth / 2;
        const halfH = window.innerHeight / 2;
        const spotX = getSpot.x;
        const spotY = getSpot.y;

        const modelViewer = document.querySelector('#myViewer');
        modelViewer.cameraTarget = tag.cameraTarget;
        modelViewer.cameraOrbit = tag.cameraOrbit;

        const getSectionType = () => {
            if (spotX > halfW && spotY > halfH) return 'section4';
            if (spotX > halfW && spotY < halfH) return 'section2';
            if (spotX < halfW && spotY > halfH) return 'section1';
            return 'section3';
        };

        const getXSectionType = () => {
            if (spotX < halfW) return 'xSection1';
            return 'xSection2';
        };

        setCurrentHotspot(tag);
        setSectionType(getSectionType());

        if (!fullCard) {
            setXSectionType(getXSectionType());
        }
        return;
    };

    const [exposure, setExposure] = useState(store.shadowCard?.shadowCard?.exposure);

    const [shadowIntensity, setShadowIntensity] = useState(store.shadowCard?.shadowCard?.shadow_intensity);

    const [shadowSoftness, setShadowSoftness] = useState(store.shadowCard?.shadowCard?.shadow_softness);

    useEffect(() => {
        setExposure(store.shadowCard?.shadowCard?.exposure);
    }, [store.shadowCard?.shadowCard?.exposure]);

    useEffect(() => {
        setShadowIntensity(store.shadowCard?.shadowCard?.shadowIntensity);
    }, [store.shadowCard?.shadowCard?.shadowIntensity]);

    useEffect(() => {
        setShadowSoftness(store.shadowCard.shadowCard?.shadowSoftness);
    }, [store.shadowCard?.shadowCard?.shadowSoftness]);

    const [hdrSky, setHdrSky] = useState('');

    useEffect(() => {
        if (store.hdrSky?.hdrSky === 'neutral') {
            setHdrSky('neutral');
        } else if (store.hdrSky?.hdrSky === 'default') {
            setHdrSky('');
        } else if (store.hdrSky?.hdrSky === 'spruit sunrise') {
            setHdrSky(HDR_FILE.SPRUIR_SUNRISE);
        } else if (store.hdrSky?.hdrSky === 'aircraft workshop') {
            setHdrSky(HDR_FILE.AIRCRAFT_WORKSHOP);
        } else if (store.hdrSky?.hdrSky === 'music hall') {
            setHdrSky(HDR_FILE.MUSIC_HALL);
        } else if (store.hdrSky?.hdrSky === 'pillars') {
            setHdrSky(HDR_FILE.PILLARS);
        } else if (store.hdrSky?.hdrSky === 'whipple creek') {
            setHdrSky(HDR_FILE.WHIPPLE_CREEK);
        }
    }, [store.hdrSky?.hdrSky]);

    const [showRemind, setShowRemind] = useState(false);
    const [fullBool, setFull] = useState(false);
    const [opa, setOpa] = useState('');

    const remindEvent = () => {
        if (store.model.isLoading) {
            return;
        }
        setShowRemind(!showRemind);
    };

    useEffect(() => {
        if (showRemind) {
            setOpa('opa');
        } else {
            setOpa('');
        }
    }, [showRemind]);

    const fullEvent = () => {
        if (store.model.isLoading) {
            return;
        }
        setFull(!fullBool);
    };

    useEffect(() => {
        if (fullBool === true) {
            document.documentElement.webkitRequestFullScreen();
        } else {
            document.webkitExitFullscreen();
        }
    }, [fullBool]);

    const [isShowSmall, setSmall] = useState(store.switch?.objectInfoSwitch);

    useEffect(() => {
        getGlobalSwitch(Cookies.get('projectId'), Cookies.get('token')).then((res) => {
            // console.log(res.globalSwitch);
            setSmall(res.globalSwitch);
            dispatch({ type: SWITCH, payload: Boolean(res.globalSwitch) });
        });
    }, []);

    const previewLink = (modelId) => () => {
        const token = Cookies.get('token');
        const encodeToken = encodeURIComponent(token);
        const encodeisPublish = encodeURIComponent(Cookies.get('isPublish'));
        if (window.location.hostname.indexOf('localhost') > -1) {
            window.open(`http://${window.location.hostname}:3020/preview?projectId=${Cookies.get('projectId')}&modelsId=${modelId}&token=${encodeToken}&isPublish=${encodeisPublish}&preview=true`, '_self');
        }

        if (window.location.hostname.indexOf('-dev') > -1) {
            window.open(`https://areditor-dev.istaging.com/preview?projectId=${Cookies.get('projectId')}&modelsId=${modelId}&token=${encodeToken}&isPublish=${encodeisPublish}&preview=true`, '_self');
        }

        if (window.location.hostname.indexOf('areditor.istaging.com') > -1) {
            window.open(`https://areditor.istaging.com/preview?projectId=${Cookies.get('projectId')}&modelsId=${modelId}&token=${encodeToken}&isPublish=${encodeisPublish}&preview=true`, '_self');
        }
    };


    const [QRshow, QRsetShow] = useState(false);

    const childCloseEvent = (val) => {
        QRsetShow(val);
    };

    const QRshowEvent = () => {
        QRsetShow(true);
    };

    const [modelLoadingProgress, setModelLoadingProgress] = useState(0);
    const myViewe = useRef();
    const getModelViewer = myViewe.current;
    useEffect(() => {
        setModalSetting({
            show: false,
        });
        if (modelInfo?.length > 0) {
            let checkModelId = false;
            for (let item of modelInfo) {
                if (item.id === parseFloat(Cookies.get('modelId'))) {
                    checkModelId = true;
                }
            }
            if (!checkModelId) {
                dispatch({ type: PERMISSION, payload: JSON.parse(atob(Cookies.get('token').split('.')[1])) });
                setModalSetting({
                    ...modalSetting,
                    show: true,
                    title: '',
                    type: 'type12',
                    coverSetting: true,
                    mode: 'preview',
                    handleOtherAction: true,
                    otherBtnText: t('PleaseTryAgain'),
                    handleConfirm: () => {
                        window.location.reload();
                    },
                });
                return;
            }
            getModelViewer.addEventListener('progress', (e) => {
                const getProgress = e.detail.totalProgress;
                dispatch({ type: MODEL_IS_LOADING, payload: getProgress === 1 ? false : true });
                setModelLoadingProgress(+getProgress.toFixed(2));
            });
            getModelViewer.addEventListener('error', (e) => {
                setModalSetting({
                    ...modalSetting,
                    show: true,
                    title: '',
                    type: 'type12',
                    coverSetting: true,
                    handleOtherAction: true,
                    otherBtnText: t('PleaseTryAgain'),
                    handleConfirm: () => {
                        window.location.reload();
                    },
                });
            });
        }
    }, [modelInfo]);
    // ======================= animation ========================
    const [animationList, setAnimationList] = useState(null);
    const [currentAnimate, setCurrentAnimate] = useState(null);
    const [mainAnimateSwitch, setMainAnimateSwitch] = useState(null);

    const [animatePlayStatus, setAnimatePlayStatus] = useState(false);
    const [showAnimateHidden, setShowAnimateHidden] = useState(false);
    const [currentPlayTime, setCurrentPlayTime] = useState('0.00');
    const [currentPercent, setcurrentPercent] = useState(0);
    const [showTootltip, setShowTootltip] = useState(null);
    const [tootltipItemName, setTootltipItemName] = useState(null);
    const [tootltipItemTop, setTootltipItemTop] = useState(0);
    const [tootltipTime, setTootltipTime] = useState(null);
    const [showTootltipTime, setShowTootltipTime] = useState(false);
    const [tootltipTimePositionX, setTootltipTimePositionX] = useState(0);
    const [isAfterScrub, setIsAfterScrub] = useState(false);
    const [isOpenTag, setIsOpenTag] = useState(true);

    useEffect(() => {
        if (modelInfo?.length > 0) {
            let mainModel = modelInfo?.filter((model) => model.id === parseFloat(Cookies.get('modelId')));
            setMainAnimateSwitch(mainModel[0].annotationSwitch);
            // 初始獲得動畫資料
            let List = [];
            const getModelViewer = document.querySelector('#myViewer');
            getModelViewer.addEventListener('load', async (e) => {
                for (let item of getModelViewer.availableAnimations) {
                    if (!item) return;
                    await getModelViewer.setAttribute('animation-name', item);
                    List.push({ name: item, duration: getModelViewer.duration.toFixed(2), ownSwitch: true, disable: false });
                }
                if (List.length === 0) {
                    return;
                }
                // 判斷有無設定
                if (JSON.parse(mainModel[0].animationItemSwitch)) {
                    for (let i = 0; i < Object.keys(JSON.parse(mainModel[0].animationItemSwitch)).length; i++) {
                        List[i].ownSwitch = JSON.parse(mainModel[0].animationItemSwitch)[i].ownSwitch;
                    }
                }
                let defaultCurrent = List.find((item) => item.ownSwitch === true);
                setAnimationList(List);
                setCurrentAnimate(defaultCurrent);
                if (mainModel[0].tags.length === 0 && mainModel[0].annotationSwitch) {
                    setIsOpenTag(false);
                    await getModelViewer.setAttribute('animation-name', defaultCurrent.name);
                    getModelViewer.play();
                    setAnimatePlayStatus(true);
                }
            });
        }
    }, [modelInfo]);

    useEffect(() => {
        const getModelViewer = document.querySelector('#myViewer');
        if (animatePlayStatus) {
            var interval = setInterval(() => {
                setCurrentPlayTime(getModelViewer.currentTime.toFixed(2));
                let percent = (getModelViewer.currentTime / getModelViewer.duration) * 100;
                setcurrentPercent(percent);
            }, 1);
        }
        return () => {
            clearInterval(interval);
        };
    }, [animatePlayStatus]);

    useEffect(() => {
        setCurrentPlayTime('0.00');
        setcurrentPercent(0);
    }, [currentAnimate]);

    useEffect(() => {
        if (isAfterScrub && animatePlayStatus) {
            var mytimmer = setTimeout(() => {
                const getModelViewer = document.querySelector('#myViewer');
                getModelViewer.play();
                setAnimatePlayStatus(true);
            }, 100);
        }
        return () => {
            clearTimeout(mytimmer);
        };
    }, [isAfterScrub]);

    const handleChangeAnimate = async (item) => {
        const getModelViewer = document.querySelector('#myViewer');
        getModelViewer.pause();
        await getModelViewer.setAttribute('animation-name', item.name);
        setCurrentAnimate(item);
        if (animatePlayStatus) {
            getModelViewer.play();
            setAnimatePlayStatus(true);
        }
    };

    const handlePlayStatus = () => {
        const getModelViewer = document.querySelector('#myViewer');
        if (animatePlayStatus) {
            getModelViewer.pause();
        } else {
            getModelViewer.play();
        }
        setAnimatePlayStatus(!animatePlayStatus);
    };

    const onScrub = () => {
        setIsAfterScrub(true);
        const getModelViewer = document.querySelector('#myViewer');
        getModelViewer.pause();
        let value = (tootltipTime * 100) / getModelViewer.duration;
        getModelViewer.currentTime = tootltipTime;
        setcurrentPercent(value);
        setCurrentPlayTime(tootltipTime);
    };

    const handleAfterScrub = () => {
        setIsAfterScrub(false);
        if (animatePlayStatus && tootltipTime == 0.00) {
            const getModelViewer = document.querySelector('#myViewer');
            getModelViewer.play();
        }
    };

    const handleHoverTooltips = (name, e) => {
        // 比對的是英文或數字
        var regExp = /[a-zA-Z0-9]/;
        if (name.length < 15 && regExp.test(name)) {
            return;
        } else if (name.length < 7 && !regExp.test(name)) {
            return;
        }
        setTootltipItemName(name);
        let Y = e.target.getBoundingClientRect().bottom;
        setTootltipItemTop(Y - 10);
        setShowTootltip(true);
    };

    const handleshowTooltipTime = (e) => {
        const getModelViewer = document.querySelector('#myViewer');
        let event = window.event;
        let inputidth = e.target.clientWidth;
        let X = e.target.getBoundingClientRect().left;
        let mouseX = event.pageX;
        let nowMouseTootipTime = (((mouseX - X) / inputidth) * getModelViewer.duration).toFixed(2);
        if (nowMouseTootipTime < 0 || nowMouseTootipTime > getModelViewer.duration.toFixed(2)) {
            setShowTootltipTime(false);
            return;
        }
        if (mouseX - X < 0) {
            nowMouseTootipTime = 0.001;
            nowMouseTootipTime = nowMouseTootipTime.toFixed(2);
        }
        setTootltipTime(nowMouseTootipTime);
        setShowTootltipTime(true);
        setTootltipTimePositionX(mouseX - X + 10);
    };

    const handleChangeMode = async (e) => {
        const getModelViewer = document.querySelector('#myViewer');
        if (isOpenTag) {
            // tag轉animation
            if (!currentAnimate) return;
            let defaultCurrent = animationList.find((item) => item.ownSwitch === true);
            setCurrentAnimate(defaultCurrent);
            getModelViewer.setAttribute('animation-name', defaultCurrent.name);
            setcurrentPercent(0);
            setCurrentPlayTime('0.00');
            getModelViewer.currentTime = 0;
            getModelViewer.play();
            setAnimatePlayStatus(true);
        } else {
            getModelViewer.pause();
            getModelViewer.setAttribute('animation-name', '');
            setAnimatePlayStatus(false);
        }
        setIsOpenTag(!isOpenTag);
    };

    return (
        <StyledPreview className="flexCenter" decOver36={decOver36} onlyTitle={onlyTitle}
            onlyTitleDesc={onlyTitleDesc}
            onlyTitleImage={onlyTitleImage}
            fullCard={fullCard}
            animatePlayStatus={animatePlayStatus}
            showAnimateHidden={showAnimateHidden}
            isOpenTag={isOpenTag}
            showTootltip={showTootltip}
        >
            {
                modelLoadingProgress < 1 && (
                    <div className="loadingProgressContainer flexCenter flexY">
                        <div className="progressText">
                            iStaging
                        </div>
                        <div className="progressBarPanel">
                            <ProgressBar now={modelLoadingProgress} min={0} max={1} />
                        </div>
                    </div>
                )
            }
            {
                QRshow && <QRcode onCallParent={childCloseEvent} />
            }
            {
                netStatus &&
                (<ul className={clsx('rightRemind')}>
                    <li onClick={remindEvent} className={clsx(opa, {
                        'loadingDefault': store.model.isLoading,
                    })}>
                        <div className='blackRemind'>
                            <div className="tt">
                                {t('gesture')}
                            </div>
                            <div className="angle"></div>
                        </div>
                        <TouchIcon />
                        {
                            showRemind && <div className="pop">
                                <div className='top'>
                                    {t('gesture')}
                                    <img src={close} onClick={remindEvent} alt="" />
                                </div>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                {t('scale')}
                                            </td>
                                            <td>
                                                <img src={z1} alt="" />
                                            </td>
                                            <td>
                                                <img src={z2} alt="" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {t('rotation')}
                                            </td>
                                            <td>
                                                <img src={r1} alt="" />
                                            </td>
                                            <td>
                                                <img src={r2} alt="" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{t('position')}</td>
                                            <td>
                                                <img src={m1} alt="" />
                                            </td>
                                            <td>
                                                <img src={m2} alt="" />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        }
                    </li>
                    {/*
                    <li onClick={fullEvent} className={clsx({
                        'loadingDefault': store.model.isLoading,
                    })}>
                        <div className="blackRemind">
                            <div className="tt">
                                {t('fullScreenPrompt')}
                            </div>
                            <div className="angle"></div>
                        </div>
                        {
                            fullBool ? <FullClose /> : <FullOpen />
                        }
                    </li> */}
                </ul>)
            }
            {/* isShowSmall 點選照片切換物件，當有物件時顯示 */}
            {
                netStatus && isShowSmall && <div className='smallBarPic'>
                    <ul>
                        {
                            smallPic.map((item) => {
                                return (
                                    <li key={item.id} onClick={previewLink(item.id)}>
                                        <img src={item.icon !== '' ? item.icon : defaultModel} alt="" />
                                    </li>
                                );
                            })
                        }
                    </ul>
                </div>
            }
            {/* 當有物件在 下載時 顯示 */}
            {
                modelLoadingProgress < 1 && <div className='smallBarPic'>
                    <ul>
                        {
                            smallPic.map((item) => {
                                return (
                                    <li key={item.id} className={clsx('smallPicHolder', {
                                        'loadingDefault': store.model.isLoading,
                                    })}>
                                    </li>
                                );
                            })
                        }
                    </ul>
                </div>
            }
            {
                showAr && <div className="arIcon" onClick={QRshowEvent} >
                    <div className="blackRemind">
                        <div className="tt">
                            {t('arProjection')}
                        </div>
                        <div className="angle"></div>
                    </div>
                    <ArIcon />
                </div>
            }{
                netStatus &&
                <model-viewer
                    ar
                    camera-controls
                    ref={myViewe}
                    id="myViewer"
                    // src="./assets/ball/scene.gltf"
                    src={modelInfo?.length > 0 && `${modelInfo?.filter((model) => model.id === parseFloat(Cookies.get('modelId')))[0]?.lastUploadFileType === 'gltf' ? modelInfo?.filter((model) => model.id === parseFloat(Cookies.get('modelId')))[0]?.gltfSrc : modelInfo?.filter((model) => model.id === parseFloat(Cookies.get('modelId')))[0]?.glbSrc}`}
                    // src={`${modelInfo[0].gltfSrc}`}
                    ios-src="./assets/ball/Small_One_Handed_Axe.usdz"
                    poster=""
                    ar-modes="webxr scene-viewer quick-look"
                    seamless-poster=""
                    loading="auto"
                    onDoubleClick={handleDoubleClick}
                    onClick={handleHideInfoCard}
                    onMouseMove={handleMouseMove}
                    shadow-intensity={shadowIntensity}
                    shadow-softness={shadowSoftness}
                    exposure={exposure}
                    environment-image={hdrSky}
                    interaction-prompt-style={!isOpenTag ? 'basic' : 'wiggle'}
                >
                    <div id="interaction-prompt"></div>
                    {
                        !isOpenTag && <div slot="interaction-prompt"></div>
                    }
                    {
                        isOpenTag && hotspots?.map((spot, index) => {
                            return (
                                <button
                                    key={spot.id}
                                    className="hotspot"
                                    id={`hotspot-${index}`}
                                    name={`hotspot-${index}`}
                                    slot={`hotspot-${index}`}
                                    data-tag-id={spot.id}
                                    data-position={spot.dataPosition}
                                    data-normal={spot.dataNormal}
                                    data-orbit={spot.cameraOrbit}
                                    data-target={spot.cameraTarget}
                                    onClick={handleClickHotspot(spot, `hotspot-${index}`)}
                                >
                                    {index + 1}
                                    <div className={clsx('infoCard',
                                        {
                                            'show': spot.id === currentHotspot?.id,
                                            [sectionType]: fullCard,
                                            [xSectionType]: !fullCard,
                                        })}
                                    onClick={(e) => e.stopPropagation()}
                                    onMouseEnter={(e) => e.stopPropagation()}
                                    onMouseLeave={(e) => e.stopPropagation()}
                                    onMouseMove={(e) => e.stopPropagation()}
                                    onMouseDown={(e) => e.stopPropagation()}
                                    onMouseUp={(e) => e.stopPropagation()}
                                    >
                                        <div className="title flexStartCenter">{currentHotspot?.title}</div>
                                        {currentHotspot?.content && <div className="content">{currentHotspot?.content}</div>}
                                        {currentHotspot?.icon && <div className="image">
                                            <img src={currentHotspot?.icon} alt='hotspot img' />
                                        </div>}
                                        {currentHotspot?.url && (
                                            <a href={currentHotspot?.url} className="urlPanel flexCenter" target="_blank"
                                                rel="noopener noreferrer">
                                                <UrlIcon className="urlIcon" />
                                                <div className="url flexStartCenter"><span>{currentHotspot?.url}</span></div>
                                            </a>
                                        )}
                                    </div>
                                </button>
                            );
                        })
                    }
                </model-viewer>
            }
            {
                !isOpenTag && mainAnimateSwitch && animationList?.length > 0 &&
                <div className="animation_conainer">
                    <div className="animation_bar">
                        <input
                            type="range"
                            step="0.01"
                            value={currentPercent}
                            min="0"
                            max="100"
                            onMouseMove={(e) => { handleshowTooltipTime(e); }}
                            onClick={() => { setCurrentPlayTime(tootltipTime); }}
                            onMouseLeave={() => { setShowTootltipTime(false); }}
                            onMouseUp={handleAfterScrub}
                            onChange={(e) => { onScrub(e.target.value); }}
                            style={{ backgroundSize: `${currentPercent}% 100%` }} />
                        {
                            showTootltipTime &&
                            <div className="animation_bar_tooltip" style={{ left: `${tootltipTimePositionX}px` }}>
                                {tootltipTime}
                            </div>
                        }
                    </div>
                    <div className="animation_control">
                        <div className="animation_control_icon_box" onClick={() => { handlePlayStatus(); }}>
                            <div className="icon"></div>
                        </div>
                        <div className="animation_control_chose" onClick={() => { setShowAnimateHidden(!showAnimateHidden); }}>
                            <div className="animation_control_chose_icon">
                            </div>
                            <div className="animation_control_chose_name">
                                {currentAnimate?.name}
                            </div>
                        </div>
                        <div className="animation_control_duration">
                            {currentPlayTime}
                        </div>
                    </div>
                    {
                        animationList && showAnimateHidden &&
                        <div className="animation_hidden_list" onMouseLeave={() => { setShowAnimateHidden(false); }}>
                            {animationList.map((item) => {
                                return (
                                    item.ownSwitch && <div
                                        className={clsx('animation_hidden_list_item', {
                                            'currentPlay': item.name === currentAnimate?.name,
                                        })}
                                        key={item.name}
                                        onClick={() => { handleChangeAnimate(item); }}>
                                        <div className="check">
                                            {
                                                currentAnimate?.name === item.name && <img src={check} />
                                            }
                                        </div>
                                        <div className="name" onMouseEnter={(e) => { handleHoverTooltips(item.name, e); }} onMouseLeave={() => setShowTootltip(false)}>{item.name}</div>
                                    </div>
                                );
                            })}
                        </div>
                    }
                </div>
            }
            {
                <div className="animation_hidden_list_tooltip" style={{ top: `${tootltipItemTop}px` }}>
                    {tootltipItemName}
                </div>
            }
            {
                hotspots?.length > 0 && mainAnimateSwitch && animationList?.length > 0 && <div className="choseOpenMode" onClick={handleChangeMode}>
                    {
                        isOpenTag ?
                            <div className='icon'>
                                <img src={namIcon} alt="" />
                                <span>物件動畫</span>
                            </div> :
                            <div className='icon'>
                                <img src={tagIcon} alt="" />
                                <span>物件標籤</span>
                            </div>
                    }</div>
            }
            {props.children}
            {modalSetting.show && (
                <ModalPopup
                    modalConfig={modalConfig[modalSetting.type]}
                    setModalSetting={setModalSetting}
                    modalSetting={modalSetting}
                />
            )}
        </StyledPreview>
    );
};

export default Preview;
