import {
    SET_OBJECT_EXPOSURE,
    SET_OBJECT_SHADOW_INTENSITY,
    SET_OBJECT_SHADOW_SOFTNESS,
} from 'redux/types/objectLight.types';


let INIT_LIGHTS = {
    exposure: null,
    shadowIntensity: null,
    shadowSoftness: null,
};


const objectLightReducer = (state = INIT_LIGHTS, action) => {

    switch (action.type) {
        case SET_OBJECT_EXPOSURE:
            return {
                ...state, exposure: action.payload,
            };
        
        case SET_OBJECT_SHADOW_INTENSITY:
            return {
                ...state, shadowIntensity: action.payload,
            };
        case SET_OBJECT_SHADOW_SOFTNESS:
            return {
                ...state, shadowSoftness: action.payload,
            };

        default: return state;
    }
};


export default objectLightReducer;

