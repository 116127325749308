export const dataURItoBlob = (dataURI) => {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type:mimeString });
};

export const getFileExt = (filename) => {
    const parts = filename.split('.');
    return parts[parts.length - 1];
};


export const isImage = (filename) => {
    const ext = getFileExt(filename);
    switch (ext.toLowerCase()) {
        case 'jpg':
        case 'jpeg':
        case 'png':
            //etc
            return true;
    }
    return false;
};


export const checkFileSize = (size) => {
    const calcFile = size / (1024 ** 2);
    const toFixedCalcFile = calcFile.toFixed(2);

    if (toFixedCalcFile > 2) return false;
    return true;
};