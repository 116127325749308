// react
import React, { useState, useEffect } from 'react';

// redux
import { useSelector, useDispatch } from 'react-redux';

// redux types
import { TAG_SWITCH } from 'redux/types/switch.types';
import { SIDEBAR_EDIT_TAG } from 'redux/types/tag.types';

// styled components
import { StyledObjectTagEditPanel } from './StyledObjectTagEditPanel';

// components
import ModalPopup from 'components/ModalPopup/ModalPopup';
import Switch from '../Switch/Switch';

// hooks
import useModal from 'hooks/useModal';

// icons
import { ReactComponent as ExclamationIcon } from 'assets/icons/exclamation.svg';

// react icons
import { ReactComponent as DeleteIcon } from './icons/delete-icon.svg';
import { AiFillPlusCircle } from 'react-icons/ai';

// api
import {
    deleteDeleteTag,
} from 'api/objectTag/tag.api';
import { postTagSwitch } from 'api/model/model.api';

// config
import { modalConfig } from 'config/modal';

// plugins
import clsx from 'classnames';
import Cookies from 'js-cookie';

//i18n
import { useTranslation } from 'react-i18next';


const DefaultAddTag = ({ tagSwitch }) => {

    const { t } = useTranslation();

    return (
        <div className={tagSwitch ? 'defaultAddTag flexCenter flex-column' : 'closeTag defaultAddTag flexCenter flex-column'}>
            <AiFillPlusCircle />
            <p>{t('objectTagDescription1')}</p>
            <p>{t('objectTagDescription2')}</p>
        </div>
    );
};

const ObjectTagEditPanel = ({
    projectId,
    modelId,
    refetchModelList,
}) => {
    const store = useSelector(store => store);
    const dispatch = useDispatch();
    const storeTagSwitch = store.switch.objectTagSwitch;
    const [hoverTagId, setHoverTagId] = useState('');

    const [editContainerTags, setEditContainerTags] = useState([]);
    const { modalSetting, setModalSetting } = useModal();
    const [tagSwitch, setTagSwitch] = useState(storeTagSwitch);
    const { t } = useTranslation();

    useEffect(() => {
        const oldTags = store.tagPanel.oldTags;
        setEditContainerTags([...oldTags]);
    }, [store.tagPanel.newTags, store.tagPanel.oldTags]);

    useEffect(() => {
        setTagSwitch(storeTagSwitch);
    }, [storeTagSwitch]);

    const handleClickTagSwitch = () => {
        setTagSwitch(!tagSwitch);
        dispatch({ type: TAG_SWITCH, payload: !tagSwitch });
    };

    const handleHover = (tagId) => () => {
        if (!store.switch.objectTagSwitch) return;
        setHoverTagId(tagId);
    };
    const handleLeave = () => () => setHoverTagId('');


    const handleDeleteTagApiCall = async (projectId, modelId, tagId) => {
        const response = await deleteDeleteTag(projectId, modelId, tagId);
        const resPostTagSwitch = await postTagSwitch(tagSwitch, projectId, modelId);
        if (response.status === 'success') {
            await refetchModelList(projectId);
            // document.querySelectorAll(`[data-tag-id="${tagId}"]`).forEach(e => e.remove());
        } else {
            setModalSetting({
                ...modalSetting,
                show: true,
                type: 'type28',
                otherBtnText: t('sure'),
                handleConfirm: () => {
                },
            });
        }
    };

    const handleDeleteTag = (tag) => async (e) => {
        e.stopPropagation();
        setModalSetting({
            ...modalSetting,
            show: true,
            title: tag?.title,
            type: 'type1',
            handleConfirm: () => {
                if (tag.id) return handleDeleteTagApiCall(projectId, modelId, tag.id);
            },
        });
    };


    const handleClickSideBarTag = (serialNumber, tag) => () => {
        // if (!tagSwitch) return;
        if (store.tagPanel.sidebarEditingTag.serialNumber !== serialNumber) {
            dispatch({ type: SIDEBAR_EDIT_TAG, payload: { serialNumber, tag } });
        } else {
            dispatch({ type: SIDEBAR_EDIT_TAG, payload: { serialNumber: null, tag: {} } });
            for (let i = 0; i < document.querySelectorAll('.editPanel ').length; i++) {
                document.querySelectorAll('.editPanel ')[i].style.display = 'none';
            }
        }
    };

    return (
        <StyledObjectTagEditPanel disableTag={!store.switch.objectTagSwitch}>

            <div className="switchPanel flexBetweenCenter">
                <div className="name">{t('myTag')}</div>
                <div className="tagSwitch" onClick={handleClickTagSwitch}>
                    <Switch turn={tagSwitch} />
                </div>
            </div>

            <div className="tagContainer">
                {editContainerTags?.length === 0 && <DefaultAddTag tagSwitch={tagSwitch} />}
                {editContainerTags?.map((tag, idx) => {
                    const isEditinOldTag = store.tagPanel.editingTags.indexOf(tag.id) !== -1;
                    const serialNumber = idx + 1;
                    return (
                        <div
                            key={tag.id || tag.orderNumber}
                            className={clsx('myTags', 'flexStartCenter', {
                                'hoverMyTags': tagSwitch,
                            })}
                            onMouseEnter={handleHover(tag.id)}
                            onMouseLeave={handleLeave()}
                            onClick={handleClickSideBarTag(serialNumber, tag)}
                        >
                            <div className={clsx('tagNumber', 'flexCenter', {
                                'disabledTagNumber': !tagSwitch,
                            })}>{serialNumber}</div>
                            <div className="tagTitle">{tag.title}</div>
                            <div
                                className={clsx('deleteIcon', 'flexCenter',
                                    {
                                        'showDeleteIcon': tag.id === hoverTagId,
                                    })}
                                onClick={handleDeleteTag(tag)}
                            >
                                <DeleteIcon />
                            </div>
                            {
                                isEditinOldTag && <ExclamationIcon className="exclamationIcon" />
                            }
                            <div className={clsx('unsaveObjectTooltip', {
                                'showEditingToolTip': tag.id === hoverTagId && isEditinOldTag,
                            })}>
                                {t('notSaveTagsPrompt')}

                                <div className="arrowLeft" />
                            </div>
                        </div>
                    );
                })}
            </div>
            {modalSetting.show && (
                <ModalPopup
                    modalConfig={modalConfig[modalSetting.type]}
                    setModalSetting={setModalSetting}
                    modalSetting={modalSetting}
                />
            )}
        </StyledObjectTagEditPanel>
    );
};

export default ObjectTagEditPanel;