const env = window.location.hostname === 'localhost' ? 'local' : process.env.BUILD_ENV || process.env.REACT_APP_BUILD_ENV;
const HDR_FILE = {
    local: {
        SPRUIR_SUNRISE: 'https://xrbu2.s3.ap-northeast-1.amazonaws.com/asset/dev/hdr/spruit_sunrise_1k_HDR.hdr',
        AIRCRAFT_WORKSHOP: 'https://xrbu2.s3.ap-northeast-1.amazonaws.com/asset/dev/hdr/aircraft_workshop_01_1k.hdr',
        MUSIC_HALL:'https://xrbu2.s3.ap-northeast-1.amazonaws.com/asset/dev/hdr/music_hall_01_1k.hdr',
        PILLARS:'https://xrbu2.s3.ap-northeast-1.amazonaws.com/asset/dev/hdr/pillars_1k.hdr',
        WHIPPLE_CREEK:'https://xrbu2.s3.ap-northeast-1.amazonaws.com/asset/dev/hdr/whipple_creek_regional_park_04_1k+(1).hdr',
    },
    development: {
        SPRUIR_SUNRISE: 'https://xrbu2.s3.ap-northeast-1.amazonaws.com/asset/dev/hdr/spruit_sunrise_1k_HDR.hdr',
        AIRCRAFT_WORKSHOP: 'https://xrbu2.s3.ap-northeast-1.amazonaws.com/asset/dev/hdr/aircraft_workshop_01_1k.hdr',
        MUSIC_HALL:'https://xrbu2.s3.ap-northeast-1.amazonaws.com/asset/dev/hdr/music_hall_01_1k.hdr',
        PILLARS:'https://xrbu2.s3.ap-northeast-1.amazonaws.com/asset/dev/hdr/pillars_1k.hdr',
        WHIPPLE_CREEK:'https://xrbu2.s3.ap-northeast-1.amazonaws.com/asset/dev/hdr/whipple_creek_regional_park_04_1k+(1).hdr',
    },
    test: {
        test: 'test.com',
    },
    production: {
        SPRUIR_SUNRISE: 'https://xrbu2.s3.ap-northeast-1.amazonaws.com/asset/hdr/spruit_sunrise_1k_HDR.hdr',
        AIRCRAFT_WORKSHOP: 'https://xrbu2.s3.ap-northeast-1.amazonaws.com/asset/hdr/aircraft_workshop_01_1k.hdr',
        MUSIC_HALL:'https://xrbu2.s3.ap-northeast-1.amazonaws.com/asset/hdr/music_hall_01_1k.hdr',
        PILLARS:'https://xrbu2.s3.ap-northeast-1.amazonaws.com/asset/hdr/pillars_1k.hdr',
        WHIPPLE_CREEK:'https://xrbu2.s3.ap-northeast-1.amazonaws.com/asset/hdr/whipple_creek_regional_park_04_1k+(1).hdr',
    },
};

export default HDR_FILE[env];
