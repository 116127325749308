const env = window.location.hostname === 'localhost' ? 'local' : process.env.BUILD_ENV || process.env.REACT_APP_BUILD_ENV;

const SERVER = {
    local: {
        SERVER_URL: 'https://ar-api-dev.istaging.com',
        JSON: 'https://jsonplaceholder.typicode.com',
        EDITOR_URL: 'http://localhost:3020',
        // EDITOR_URL: 'http://192.168.6.195:3020',
        VIEWER_URL: 'http://localhost:3021',
        PLATFORM_URL: 'http://localhost:3022',
        ISTAGING: 'https://ar-dev.istaging.com',
        GET_DOMAIN: 'localhost',
    },
    development: {
        SERVER_URL: 'https://ar-api-dev.istaging.com',
        JSON: 'https://jsonplaceholder.typicode.com',
        EDITOR_URL: 'https://areditor-dev.istaging.com',
        VIEWER_URL: 'https://arviewer-dev.istaging.com',
        PLATFORM_URL: 'https://armaker-dev.istaging.com',
        ISTAGING: 'https://ar-dev.istaging.com',
        GET_DOMAIN: '.istaging.com',
    },
    test: {
        SERVER_URL: 'https://ar-api-test.istaging.com',
        JSON: 'https://jsonplaceholder.typicode.com',
        EDITOR_URL: 'https://areditor-test.istaging.com',
        VIEWER_URL: 'https://arviewer-test.istaging.com',
        PLATFORM_URL: 'https://armaker-test.istaging.com',
        ISTAGING: 'https://ar-billing-test.istaging.com',
        GET_DOMAIN: '.istaging.com',
    },
    production: {
        SERVER_URL: 'https://ar-api.istaging.com',
        JSON: 'https://jsonplaceholder.typicode.com',
        EDITOR_URL: 'https://areditor.istaging.com',
        VIEWER_URL: 'https://arviewer.istaging.com',
        PLATFORM_URL: 'https://armaker.istaging.com',
        ISTAGING: 'https://ar.istaging.com',
        GET_DOMAIN: '.istaging.com',
    },
};

export default SERVER[env];