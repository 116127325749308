import Cookies from 'js-cookie';

export const handleRerouteToARMaker = (type) => {
    const hostname = window.location.hostname;
    if (hostname === 'localhost') {
        window.open('http://localhost:3022/cardList?lang=' + Cookies.get('lang'), type, 'noopener, noreferrer');
    }

    if (hostname === 'areditor-dev.istaging.com') {
        window.open('https://armaker-dev.istaging.com/cardList?lang=' + Cookies.get('lang'), type, 'noopener, noreferrer');
    }

    if (hostname === 'areditor-test.istaging.com') {
        window.open('https://armaker-test.istaging.com/cardList?lang=' + Cookies.get('lang'), type, 'noopener, noreferrer');
    }

    if (hostname === 'areditor.istaging.com' ) {
        window.open('https://armaker.istaging.com/cardList?lang=' + Cookies.get('lang'), type, 'noopener, noreferrer');
    }
};