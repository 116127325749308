import { 
    TITLE_LENGTH_ERROR,
    DESC_LENGTH_ERROR,
} from 'redux/types/editPanel.types';

let initState = {
    titleLengthError: false,
    descLengthError: false,
};

const editPanelReducer = (state = initState, action) => {
    switch (action.type) {
        case TITLE_LENGTH_ERROR:
            return {
                ...state, titleLengthError: action.payload,
            };
    
        case DESC_LENGTH_ERROR:
            return {
                ...state, descLengthError: action.payload,
            };

        default: return state;
    }
};


export default editPanelReducer;