// react
import React, { useEffect } from 'react';

// styled components
import { StyledArOpen } from './StyledArOpen';

// common
import SidebarContainer from 'common/SidebarContainer/SidebarContainer';


const ObjectLight = ({ modelList, refetchModelList, netStatus, modelListIsLoading, setIsAnimation, setIsObjectTag }) => {
    useEffect(() => {
        setIsAnimation(false);
        setIsObjectTag(false);
    }, []);
    return (
        <SidebarContainer
            modelList={modelList}
            refetchModelList={refetchModelList}
            modelListIsLoading={modelListIsLoading}
            netStatus={netStatus}
        >
            <StyledArOpen className="flexCenter">
            </StyledArOpen>
        </SidebarContainer>
    );
};

export default ObjectLight;
