// react
import React, { useState, useEffect } from 'react';
import { Link, useLocation, useBlocker, useNavigate } from 'react-router-dom';

// redux
import { useSelector } from 'react-redux';

// styled components
import { StyledNavigationBar } from './StyledNavigationBar';

// components
import ModalPopup from 'components/ModalPopup/ModalPopup';

//icons
import background from './images/background.svg';
import style from './images/style.svg';
import info from './images/info.svg';
import tag from './images/tag.svg';
import animate from './images/animate.svg';
import objLight from './images/objLight.svg';
import env from './images/env.svg';
import ar from './images/ar.svg';

// hooks
import useModal from 'hooks/useModal';

// config
import { modalConfig } from 'config/modal';

//i18n
import { useTranslation } from 'react-i18next';

// plugin
import clsx from 'classnames';

const NavigationBar = (props) => {

    const { t, i18n } = useTranslation();
    const { netStatus, modelListIsLoading, modelStatus } = props;

    const navivations = [
        // {
        //     id: 1,
        //     name: t('commingSoon'),
        //     icons: background,
        //     link: '#',
        //     isLink: 'link noLink',
        // },
        // {
        //     id: 2,
        //     name: t('commingSoon'),
        //     icons: style,
        //     link: '#',
        //     isLink: 'link noLink',
        // },
        {
            id: 3,
            name: t('objectInformation'),
            icons: info,
            link: '/',
            isLink: 'link',
        },
        {
            id: 4,
            name: t('objectTag'),
            icons: tag,
            link: '/objectTag',
            isLink: 'link',
        },
        {
            id: 5,
            name: t('objectAnimation'),
            icons: animate,
            link: '/objectAnimation',
            isLink: 'link',
        },
        {
            id: 6,
            name: t('objectBrightness'),
            icons: objLight,
            link: '/objectLight',
            isLink: 'link',
        },
        {
            id: 7,
            name: t('environmentLighting'),
            icons: env,
            link: '/envLight',
            isLink: 'link',
        },
        {
            id: 8,
            name: t('arProjection'),
            icons: ar,
            link: '/arOpen',
            isLink: 'link',
        },
    ];

    const store = useSelector(store => store);
    const location = useLocation();
    const [pathname, setPathname] = React.useState('');
    const { modalSetting, setModalSetting } = useModal();
    const [modelViewerLoadingStatus, setModelViewerLoadingStatus] = useState(true);


    useEffect(() => {
        setPathname(location.pathname);
    }, [location.pathname]);
    
    // useEffect(() =>{

    //     // if (location.pathname === '/objectTag' && targetRoute === '/objectTag') {
    //     //     return; 
    //     // }

    //     // console.log(`this is location.pathname ${123}`, location.pathname);
    //     if (window.location.pathname === '/objectTag') {
    //         // console.log(`this is targetRoute ${123}`, targetRoute);
    //         // if (!targetRoute) return;
    //         setIsBlocking(true);
    //         // if (getNewTagsLength < 1 && getEditingTagsLength < 1) {
    //         //     console.log(`this is getNewTagsLength ${123}`, getNewTagsLength);
    //         //     console.log(`this is getEditingTagsLength ${123}`, getEditingTagsLength);
    //         //     setIsBlocking(false);
    //         //     return navigate(targetRoute);
    //         // }

    //         // if (getNewTagsLength > 0 && getEditingTagsLength > 0) {
    //         //     return setIsBlocking(true);
    //         // }

    //     }
    // }, [targetRoute]);

    // useEffect(() =>{

    //     if (!isBlocking && location.pathname === '/objectTag') {
    //         navigate(targetRoute);
    //     }
    // }, [isBlocking]);

    // useBlocker(
    //     (e) => {
    //         console.log('111');
    //         // for (let i = 0; i < document.querySelectorAll('.editPanel').length;i++){
    //         //     document.querySelectorAll('.editPanel')[i].style.display = 'none';
    //         // }
    //     },

    // );


    // const handleTargetRoute = (pathname) => () => {
    //     // console.log(`this is pathname ${123}`, pathname);
    //     setTargetRoute(pathname);
    // };

    return (
        <StyledNavigationBar isLoading={store.model.isLoading}>
            {
                netStatus && navivations.map(nav => {
                    return (
                        <div
                            key={nav.id}
                            className={clsx('nav flexCenter', nav.isLink, { 'currentNav': nav.link === pathname })}
                        >
                            <Link to={nav.link} draggable="false">
                                <div className="navIcon flexCenterEnd">
                                    <img src={nav.icons} draggable="false" />
                                </div>
                                <div className="navName">
                                    <div className="angle"></div>
                                    <div className="nn">{nav.name}</div>
                                </div>
                            </Link>
                        </div>
                    );
                })
            }
            {
                modalSetting.show && (
                    <ModalPopup
                        modalConfig={modalConfig[modalSetting.type]}
                        setModalSetting={setModalSetting}
                        modalSetting={modalSetting}
                    />
                )
            }
        </StyledNavigationBar >
    );
};

export default NavigationBar;
