// react
import React, { useEffect } from 'react';

// styled components
import { StyledObjectTag } from './StyledObjectTag';

// common
import SidebarContainer from 'common/SidebarContainer/SidebarContainer';


const ObjectTag = ({ modelList, refetchModelList, netStatus, modelListIsLoading, setIsAnimation, setIsObjectTag }) => {
    useEffect(() => {
        setIsAnimation(false);
        setIsObjectTag(true);
    }, []);
    return (
        <SidebarContainer
            modelList={modelList}
            refetchModelList={refetchModelList}
            modelListIsLoading={modelListIsLoading}
            netStatus={netStatus}
        >
            <StyledObjectTag />
        </SidebarContainer>
    );
};

export default ObjectTag;
