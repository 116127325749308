import { USER_INFO } from 'redux/types/userInfo.types';

let USER_INFO_RES = {
    userInfo: '',
};

const userInfoReducer = (state = USER_INFO_RES, action) => {
    switch (action.type) {
        case USER_INFO:
            return {
                ...state, userInfo: action.payload,
            };
        default: return state;
    }
};

export default userInfoReducer;