import styled from 'styled-components';
//images
import namIcon from './images/namIcon.svg';
import pauseIcon from './images/pauseIcon.svg';
import playIcon from './images/playIcon.svg';

export const StyledAnimateController = styled.div`
.animation{
    &_conainer{
        width:calc(100% - 343px);
        padding:0 48px;
        position:absolute;
        bottom:0px;
        right:0px;
        margin-bottom:20px;
    }
    &_bar{
        width: 100%;
        height: 32px;
        &_tooltip{
            word-break: break-all;
            word-wrap:break-word;
            min-width: 80px;
            text-align:center;
            left: 360px;
            top:-50px;
            border-radius: 4px;
            position: absolute;
            background: rgba(0, 0, 0, 0.87);
            font-weight: 500;
            font-size: 14px;
            line-height: 140%;
            letter-spacing: 0.02em;
            color: #FFFFFF;
            padding:10px;
            &:before{
                content:'';
                position: absolute;
                width: 0;
                height: 0;
                left: 50%;
                top:30px ;
                transform:translateX(-50%) rotate(-90deg);
                border-style: solid;
                border-width: 15px 12px 15px 0;
                border-color: transparent #000000DE transparent transparent;
            }
        }
        input[type="range"] {
            cursor:pointer;
            -webkit-appearance: none;
            width: 100%;
            height: 4px;
            background: rgba(0, 0, 0, 0.12);
            border-radius: 4px;
            background-image:linear-gradient(79.78deg, #ED0973 0%, #FF744E 100%);
            // background-size: 70% 100%;
            background-repeat: no-repeat;
        }
          /* Input Thumb */
        input[type="range"]::-webkit-slider-thumb {
            -webkit-appearance: none;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            background: #fff;
            box-shadow: 0 0 2px 0 #555;
            cursor:pointer;
            transition: background .3s ease-in-out;
        }
        
        input[type="range"]::-moz-range-thumb {
            -webkit-appearance: none;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            background: #fff;
            box-shadow: 0 0 2px 0 #555;
            transition: background .3s ease-in-out;
            }
        input[type="range"]::-ms-thumb {
            -webkit-appearance: none;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            background: #fff;
            box-shadow: 0 0 2px 0 #555;
            transition: background .3s ease-in-out;
        }
    }
    &_control{
        display:flex;
        align-items:center;
        &_icon_box{
            margin-right:42px;
            cursor:pointer;
            &:hover{
                background: rgba(0, 0, 0, 0.87);
                border-radius: 8px;
                .icon{
                    background-color: #fff;
                }
            }
            .icon{
                width:48px;
                height:48px;
                display:flex;
                justify-content:center;
                align-items:center;
                background-color: #000;
                -webkit-mask:${props => props.animatePlayStatus ? `url(${pauseIcon}) no-repeat center` : `url(${playIcon}) no-repeat center`};
                mask: ${props => props.animatePlayStatus ? `url(${pauseIcon}) no-repeat center` : `url(${playIcon}) no-repeat center`};
            }
        }
        &_chose{
            display:flex;
            justify-content:center;
            align-items:center;
            padding:10px 24px;
            border-radius: 4px;
            margin-right:24px;
            border: 1px solid rgba(0, 0, 0, 0);
            background:${props=>props.showAnimateHidden ? 'rgba(0, 0, 0, 0.87)' : ''} ;
            &:hover{
                background: rgba(0, 0, 0, 0.87);
                border: 1px solid rgba(0, 0, 0, 0.87);
                cursor:pointer;
                .animation_control_chose_name{
                    color:#fff;
                }
                .animation_control_chose_icon{
                    background-color: #fff;
                }
            }
            &_icon{
                width:24px;
                height:24px;
                margin-right:8px; 
                background-color:${props=>props.showAnimateHidden ? '#fff' : '#000'} ;
                -webkit-mask: url(${namIcon}) no-repeat center;
                mask: url(${namIcon}) no-repeat center;
            }
            &_name{
                font-weight: 900;
                font-size: 16px;
                line-height: 24px;
                color:${props=>props.showAnimateHidden ? '#fff' : '#333333'} ;
                width: 120px;
                overflow:hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
        &_duration{
            font-weight: 700;
            font-size: 16px;
            line-height: 140%;
            color: #666666;
        }
    }
    &_hidden_list{
        position: absolute;
        left: 137px;
        bottom: 55px;
        background: rgba(0, 0, 0, 0.87);
        border: 1px solid rgba(255, 255, 255, 0.12);
        border-radius: 4px;
        padding:15px;
        width:204px;
        max-height:215px;
        overflow-y: scroll;
        overflow-x: hidden;
        &::-webkit-scrollbar-track
        {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
            border-radius: 4px;
            background-color: rgba(0,0,0,0);
            margin: 0px;
        }
        &::-webkit-scrollbar
        {
            width: 4px;
            background-color: rgba(0,0,0,0);
        }

        &::-webkit-scrollbar-thumb
        {
            border-radius: 4px;
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
            background-color: rgba(255,255,255,0.3);
            ;
        }
        &_item{
            display: flex;
            align-items:center;
            width: 175px;
            height: 36px;
            cursor:pointer;
            &.currentPlay{
                .name{
                    color: rgba(255, 255, 255, 1);
                }
            }
            .check{
                width: 24px;
                height: 24px;
                margin-right:5px;
            }
            .name{
                flex-grow: 1;
                max-width:145px;
                color: rgba(255, 255, 255, 0.5);
                font-weight: 500;
                font-size: 16px;
                line-height: 140%;
                overflow:hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                &:hover{
                    color:#fff;
                }
            }
        }
        &_tooltip{
            word-break: break-all;
            word-wrap:break-word;
            width: 130px;
            left: 700px;
            top:10px;
            border-radius: 4px;
            position: fixed;
            background: rgba(0, 0, 0, 0.87);
            font-weight: 500;
            font-size: 14px;
            line-height: 140%;
            letter-spacing: 0.02em;
            color: #FFFFFF;
            padding:10px;
            visibility:${props=>props.showTootltip ? 'visible' : 'hidden'};
            transform:translateY(-50%);
            &:before{
                content:'';
                position: absolute;
                width: 0;
                height: 0;
                left: -10px;
                top: 50%;
                transform:translateY(-50%);
                border-style: solid;
                border-width: 10px 14px 10px 0;
                border-color: transparent #000000DE transparent transparent;
            }
        }
    }
}
`;