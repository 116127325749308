// react
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// styled components
import { StyledEditContainer } from './StyledEditContainer';

// components
import ObjectTagEditPanel from 'components/ObjectTagEditPanel/ObjectTagEditPanel';
import ObjectInfo from '../ObjectInfo/ObjectInfo';
import ObjectLight from '../ObjectLight/ObjectLight';
import ObjectAnimation from '../ObjectAnimation/ObjectAnimation';
import EnvLight from '../EnvLight/EnvLight';
import ArOpen from '../ArOpen/ArOpen';

//i18n
import { useTranslation } from 'react-i18next';

const EditContainer = ({ projectId, modelId, tags, refetchModelList, netStatus, modelListIsLoading, modelStatus }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const [pathname, setPathname] = React.useState('');

    useEffect(() => {
        setPathname(location.pathname);
    });

    const renderRouter = () => {
        switch (pathname) {
            case '/':
                return {
                    title: t('objectInformation'),
                    component: <ObjectInfo
                        netStatus={netStatus}
                        modelListIsLoading={modelListIsLoading}
                        modelStatus={modelStatus} 
                    />,
                };
            case '/objectTag':
                return {
                    title: t('objectTag'),
                    component: <ObjectTagEditPanel
                        tags={tags}
                        projectId={projectId}
                        modelId={modelId}
                        refetchModelList={refetchModelList}
                        modelStatus={modelStatus}
                    />,
                };
            case '/objectLight':
                return {
                    title: t('objectBrightness'),
                    component: <ObjectLight />,
                };
            case '/objectAnimation':
                return {
                    title: t('objectAnimation'),
                    component: <ObjectAnimation />,
                };
            case '/envLight':
                return {
                    title: t('environmentLighting'),
                    component: <EnvLight />,
                };
            case '/arOpen':
                return {
                    title: t('arProjection'),
                    component: <ArOpen />,
                };
            default:
                return {
                    title: '',
                    component: <></>,
                };
        }
    };

    return (
        <StyledEditContainer>
            <div className='panelTitle'>
                {renderRouter().title}
            </div>
            <div>
                {renderRouter().component}
            </div>
        </StyledEditContainer>
    );
};

export default EditContainer;
