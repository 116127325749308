import styled from 'styled-components';

export const StyledMobilePopup = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999;
    background: #1F1F1F;
    color: rgba(255, 255, 255, 0.87);
    display: flex;
    align-items: center;
    justify-content: center;

    .mobile_popup {
        width: 240px;
        text-align: center;

        &_icon {
            width: 36.8px;
            height: 36.8px;
            margin: 0 auto;
            margin-bottom: 16px;
        }

        &_title {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.02em;
            color: rgba(255, 255, 255, 0.87);
            margin-bottom: 8px;
        }

        &_description {
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.02em;
            color: rgba(255, 255, 255, 0.6);
        }

        &_loading {
            animation: rotation 1s infinite;
        }

        @keyframes rotation {
            from {
                transform: rotate(0deg);
            }
            to {
                transform: rotate(360deg);
            }
        }
    }
`;