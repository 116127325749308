import styled from 'styled-components';

export const StyledObjectInfo = styled.div`
    padding: 25px;
    position: relative;
    .rightBar{
        width: 98px;
        z-index: 5;
        position: absolute;
        right: -99px;
        top:30px;
        height: 480px;
        background: rgba(0, 0, 0, 0.87);
        border-radius: 0px 24px 24px 0px;
        text-align:center;
        overflow: auto;
        z-index: 1;
        padding-top: 30px;
        .imgList{
            margin-bottom: 20px;
            border-radius: 10px;
            cursor: pointer;
            display: block;
            margin-left: 23px;
            width: 48px;
            height: 48px;
            object-position: center center;
            object-fit: cover;
            border:1px solid rgba(255,255,255,0.12);
            &:hover{
                /* border:1px solid #fff; */
            }
        }
    }
    .title{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 15px;
        .name{
            font-size: 14px;
            color: rgba(255,255,255,0.6);
        }
    }
    .uploadPic{
        width: 100%;
        height: 135px;
        border: 1px dashed rgba(255, 255, 255, 0.12);
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        flex-wrap: wrap;
        background-color: #1b1b1b;
        border-radius: 4px;
        position:relative;
        &:hover{
            background-color: rgba(0,0,0,0.05);
            border:1px solid  rgba(255,255,255,0.12);
            .fileBtn{
                background: rgba(255, 255, 255, 0.6);
            }
        }
        .word{
            width: 100%;
            text-align:center;
            font-size: 12px;
            color: rgba(255, 255, 255, 0.5);
            padding: 0px 50px 20px 50px;
        }
        .fileBtn{
            padding: 4px 16px;
            background: rgba(255, 255, 255, 0.38);
            border-radius: 40px;
            text-align: center;
            line-height: 24px;
            color: #fff;
            font-size: 14px;
            cursor: pointer;
            font-weight: normal;
            .icon{
                font-size: 20px;
                padding-right: 8px;
                cursor: pointer;
            }
        }
    }
    .picHolder{
        width: 229px;
        height: 135px;
        // position:absolute;
        cursor: pointer;
        border: 1px solid rgba(255,255,255,0.12);
        background:#2e2e2e;
        border-radius: 4px;
        overflow: hidden;
    }
    .disabledObjInfo{
        opacity: 0.6;
        top:61px;
        pointer-events: none;
        z-index:1000;
        .stopClickMask{
            width: 100%;
            height: 100%;
            position: absolute;
            left:0;
            top: 0;
            z-index: 2;
        }
    }
    .modelList{
        width: 100%;
        margin-bottom: 20px;
        .picContainer {
            width: 100%;
            height: 135px;
            cursor: pointer;
            border: 1px solid rgba(255,255,255,0.12);
            border-radius: 4px;
            overflow: hidden;
        }
        .pic{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
            border-radius: 4px;
        }
        .ctrlBar{
            color: rgba(255,255,255,0.6);
            padding-top: 5px;
            .icon{
                cursor: pointer;
                display: inline-block;
                width: 24px;
                height: 24px;
                text-align: center;
                margin-right: 10px;
                &:hover{
                    background-color: rgba(255, 255, 255, 0.12);
                    border-radius: 4px;
                }
            }
        }
        .stopClickMask{
            width: 100%;
            height: 100%;
            position: absolute;
            left:0;
            top: 0;
            z-index: 2;
        }
        &.holder{
            position:absolute;
            top:61px;
            .ctrlBar{
                padding-top:10px;
            }
        }
    }
    .stopClickMask{
        width: 100%;
        height: 100%;
        position: absolute;
        left:0;
        top: 0;
        z-index: 2;
    }
    .isLoadingPic{
        width: 100%;
        height: 100%;
        position: absolute;
        background: #1B1B1B;
        border: 1px dashed rgba(255, 255, 255, 0.12);
        border-radius: 4px;
        left:0;
        top: 0;
        z-index: 2;
        .title{
            position: absolute;
            left:50%;
            top:28px;
            transform:translateX(-50%);
            font-weight: 500;
            font-size: 12px;
            line-height: 150%;
            text-align: center;
            letter-spacing: 0.02em;
            color: rgba(255, 255, 255, 0.8);
        }
        .text{
            width: 195px;
            height: 18px;
            top:76px;
            left:50%;
            display:flex;
            justify-content:space-between;
            transform:translateX(-50%);
            position: absolute;
            font-weight: 500;
            font-size: 12px;
            line-height: 150%;
            display: flex;
            align-items: center;
            letter-spacing: 0.02em;
            color: rgba(255, 255, 255, 0.5);
            .name{
                width: 163px;
                height: 18px;
                overflow:hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
        .progressBar{
            position: absolute;
            width: 195px;
            left:50%;
            transform:translateX(-50%);
            height: 4px;
            top: 102px;
            background: rgba(255, 255, 255, 0.12);
            border-radius: 12px;
        }
        .bar{
            height: 4px;
            width:${props => ((props.progress / 100) * 195) + 'px'};
            background: #54C68F;
            border-radius: 12px;
        }
    }
    input[type="file"]{
        display: none;
    }
`;