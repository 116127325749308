import { PERMISSION } from 'redux/types/permission.types';

let PERMISSION_RES = {
    permission: '',
};

const permissionReducer = (state = PERMISSION_RES, action) => {
    switch (action.type) {
        case PERMISSION:
            return {
                ...state, permission: action.payload,
            };
        default: return state;
    }
};

export default permissionReducer;