import { 
    VIEW_MODE_NORMAL,
    VIEW_MODE_PREVIEW,
} from 'redux/types/viewMode.types';



let INIT_VIEW_STATE = {
    mode: 'normal',
};


const viewModeReducer = (state = INIT_VIEW_STATE, action) => {
    switch (action.type) {
        case VIEW_MODE_NORMAL:
            return {
                ...state, mode: action.payload,
            };

        case VIEW_MODE_PREVIEW:
            return {
                ...state, mode: action.payload,
            };


    
        default:
            return state;
    }
};


export default viewModeReducer;