// react
import React, { useEffect } from 'react';

// styled components
import { StyledObjectAnimation } from './StyledObjectAnimation';

// common
import SidebarContainer from 'common/SidebarContainer/SidebarContainer';


const ObjectAnimation = ({ modelList, refetchModelList, netStatus, modelListIsLoading, setIsAnimation, setIsObjectTag }) => {
    useEffect(() => {
        setIsAnimation(true);
        setIsObjectTag(false);
    }, []);
    return (
        <SidebarContainer
            modelList={modelList}
            refetchModelList={refetchModelList}
            modelListIsLoading={modelListIsLoading}
            netStatus={netStatus}
        >
            <StyledObjectAnimation className="flexCenter">
            </StyledObjectAnimation>
        </SidebarContainer>
    );
};

export default ObjectAnimation;
