import { HDR_SKY } from 'redux/types/hdrSky.types';

let HDR_SKY_RES = {
    hdrSky: '',
};

const hdrSkyReducer = (state = HDR_SKY_RES, action) => {
    switch (action.type) {
        case HDR_SKY:
            return {
                ...state, hdrSky: action.payload,
            };
        default: return state;
    }
};

export default hdrSkyReducer;