// react
import React, { useEffect } from 'react';
// styled components
import { StyledEnvLight } from './StyledEnvLight';

// common
import SidebarContainer from 'common/SidebarContainer/SidebarContainer';


const EnvLight = ({ modelList, refetchModelList, netStatus, modelListIsLoading, setIsAnimation, setIsObjectTag }) => {
    useEffect(() => {
        setIsAnimation(false);
        setIsObjectTag(false);
    }, []);
    return (
        <SidebarContainer
            modelList={modelList}
            refetchModelList={refetchModelList}
            modelListIsLoading={modelListIsLoading}
            netStatus={netStatus}
        >
            <StyledEnvLight>
            </StyledEnvLight>
        </SidebarContainer>
    );
};

export default EnvLight;
