import styled from 'styled-components';

export const StyledPublishPop = styled.div`
    position: absolute;
    right: 0;
    z-index: 9999;
    top:60px;
    background-color: #fff;
    padding: 16px 24px;
    box-shadow: 0px 0px 4px 0px #00000040;
    border-radius: 4px;
    text-align:center;
    img{
        margin-bottom: 10px;
    }
    .title{
        font-size: 14px;
        color: #333333;
        font-weight: bold;
        line-height: 20px;
    }
    .text{
        color: rgba(0,0,0,0.6);
        font-size: 12px;
        padding-top: 4px;
        padding-bottom: 50px;
        line-height: 18px;
    }
    a{
        background-color: #FF744E;
        border-radius: 4px;
        width: 172px;
        font-size: 14px;
        font-weight: bold;
        height: 32px;
        line-height: 32px;
        text-align:center;
        display: block;
        margin: 0 auto;
        color: #fff;
        text-decoration: none;
        &:hover{
            background-color: rgba(255,158,131,0.7);
        }
    }
`;