import styled from 'styled-components';

//images
import check from './images/check.svg';

export const StyledObjectAnimation = styled.div`
width:100%;
height:100%;
overflow:auto;
&::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
    border-radius: 4px;
    background-color: rgba(0,0,0,0);
    margin:50px 0px 0px 0px;
}
&::-webkit-scrollbar
{
    width: 4px;
    background-color: rgba(0,0,0,0);
}

&::-webkit-scrollbar-thumb
{
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
    background-color: rgba(255,255,255,0.3);
    ;
}
.switch_setting{
    width:100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position:sticky;
    top:0px;
    padding: 25px 25px 10px;
    z-index:99999;
    .name{
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: rgba(255,255,255,0.6);
    }
    .switch{
        &.disable{
            opacity:0.15;
        }
    }
}
.noAnime{
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: rgba(255, 255, 255, 0.2);
    position:absolute;
    top:189px;
    left:132px;
}
.animate{
    &_list{
        margin:30px 0px;
        &.disable{
            position:relative;
            .mask{
                position:absolute;
                top:0;
                left:0;
                display:block;
                width:100%;
                height:100%;
                z-index:10;
            }
            .animate_item{
                color: rgba(255, 255, 255, 0.12);
                &.currentPlay{
                    .checkIcon{
                        .icon{
                            background-color:rgba(255, 255, 255, 0.12);
                        }
                    }
                }
                &_switch{
                    opacity:0.12;
                }
            }
        }
        .mask{
            display:none;
            width:100%;
            height:100%;
        }
    }
    &_item{
        width: 235px;
        height: 32px;
        margin:0px auto;
        margin-top:20px;
        display:flex;
        align-items:center;
        color: rgba(255, 255, 255, 0.5);
        &:hover{
            color: rgba(255, 255, 255, 0.87);
        }
        &.currentPlay{
            color: rgba(255, 255, 255, 0.87);
        }
        &.closeOwnSwitch{
            color:rgba(255, 255, 255, 0.12);
            cursor:default;
        }
        .checkIcon{
            width:24px;
            height: 24px;
            display:flex;
            justify-content:center;
            align-items:center;
            margin-right:5px;
            .icon{
                width:24px;
                height: 24px;
                background-color:rgba(255, 255, 255, 0.87);
                -webkit-mask: url(${check}) no-repeat center;
                mask: url(${check}) no-repeat center;
            }
        }
        &_name{
            cursor:pointer;
            width: 90px;
            font-weight: 500;
            font-size: 14px;
            line-height: 140%;
            overflow:hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            flex-grow:1;
        }
        &_duration{
            margin-right:16px;
            margin-left:10px;

        }
        &_switch{
            &.disable{
                opacity:0.15;
            }
        }
    }
    &_tooltip{
        word-break: break-all;
        word-wrap:break-word;
        width: 130px;
        left: 360px;
        border-radius: 4px;
        position: absolute;
        background: rgba(0, 0, 0, 0.87);
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: 0.02em;
        color: #FFFFFF;
        padding:10px;
        &:before{
            content:'';
            position: absolute;
            width: 0;
            height: 0;
            left: -10px;
            top: 50%;
            transform:translateY(-50%);
            border-style: solid;
            border-width: 10px 14px 10px 0;
            border-color: transparent #000000DE transparent transparent;
        }
    }
}
`;