// react
import React from 'react';

// styled components
import { StyledModalPopupContainer } from './StyledModalPopup';

// react icons
import { AiOutlineClose } from 'react-icons/ai';

//i18n
import { useTranslation } from 'react-i18next';

// plugin
import clsx from 'classnames';

const alertType = ['alert-format', 'alert-storage', 'alert-tag-name', 'fail-save', 'deleteErr'];
const confirmType = ['file-delete', 'delete', 'exit-editor', 'exit-tag', 'file-save', 'file-preview', 'file-upload', 'file-upload2', 'file-publish-update'];

const ModalPopup = ({
    modalConfig,
    setModalSetting,
    modalSetting,
}) => {
    const { icon, type, desc, notice } = modalConfig;
    const { title } = modalSetting;
    const { t } = useTranslation();

    const handleCloseModal = () => {
        if (modalSetting.handleOtherAction) {
            modalSetting.handleConfirm();
        } else {
            setModalSetting({
                ...modalSetting,
                show: false,
            });
        }
    };

    const handlModalConfirm = () => {
        modalSetting.handleConfirm();
        return setModalSetting({
            ...modalSetting,
            show: false,
        });
    };


    const confirmButtons = () => {
        return (
            <div className={clsx('confirmButtons', 'flexBetweenCenter', {
                'flexReverseRow': type === 'file-upload' || type === 'file-upload2' || type === 'file-publish-update',
            })}>
                <div className="confirmButton flexCenter" onClick={handlModalConfirm}>
                    {(type === 'file-delete' || type === 'delete') && t('delete')}
                    {(type === 'exit-editor' || type === 'exit-tag' || type === 'file-share') && t('leave')}
                    {type === 'file-save' && t('saveAndPublic')}
                    {type === 'file-publish-update' && t('saveAndUpdate')}
                    {type === 'file-upload' && t('upgradenow')}
                    {type === 'file-preview' && t('saveAndPreview')}
                    {type === 'file-copy' && t('duplicatedDraft')}
                    {type === 'file-close' && t('unpublic')}
                    {type === 'file-upload2' && t('common.contactNow')}

                </div>
                <div
                    className="cancelButton flexCenter"
                    onClick={handleCloseModal}
                >
                    {t('cancel')}

                </div>
            </div>
        );
    };

    const alertButton = () => {
        return (
            <div className="alertButton flexCenter">
                <div
                    className="okButton flexCenter"
                    onClick={handleCloseModal}
                >
                    {modalSetting.otherBtnText || t('ok')}
                </div>
            </div>
        );
    };



    const renderDeleteDesc = () => {
        let sliceTitle = title ? title : ' ';
        if (sliceTitle.length > 15) {
            sliceTitle = `${sliceTitle.slice(0, 15)}...`;
        }
        desc[1] = `"${sliceTitle}"`;
        const joinDesc = desc.join(' ');
        return joinDesc;
    };

    const renderNormalDesc = () => {
        return desc;
    };

    return (
        <StyledModalPopupContainer className="flexCenter" type={type} cover={modalSetting.coverSetting} mode={modalSetting.mode}>
            <div className="modalPanel">
                <div
                    className="topSection flexEndCenter"
                    onClick={handleCloseModal}
                    style={{ 'display': modalSetting.coverSetting ? 'none' : 'visiable' }}
                >
                    <AiOutlineClose />
                </div>

                <div className="bottomSection flexStartCenter flex-column">
                    <div className="iconPanel">
                        <img src={icon} alt="icon" />
                    </div>
                    <div className="descPanel">
                        {type === 'delete' && renderDeleteDesc()}
                        {type !== 'delete' && type !== 'file-upload' && type !== 'file-upload2' && renderNormalDesc()}
                        {type.indexOf('file-upload') > -1 && <p dangerouslySetInnerHTML={{ __html: modalSetting.desc }}></p>}
                    </div>
                    <div className='titlePanel'></div>
                    {
                        type.indexOf('file-upload') > -1 ?
                            <div className="noticePanel" dangerouslySetInnerHTML={{ __html: notice }}>
                            </div> :
                            <div className="noticePanel">
                                {notice}
                            </div>
                    }
                    <div className="buttonPanel">
                        {confirmType.indexOf(type) !== -1 && confirmButtons()}
                        {alertType.indexOf(type) !== -1 && alertButton()}
                    </div>
                </div>

            </div>
        </StyledModalPopupContainer>
    );
};

export default ModalPopup;
