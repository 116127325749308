import styled from 'styled-components';

export const StyledEditPanel = styled.div`
    padding: 10px 12px;
    position: absolute;
    width: 240px;
    height: auto;
    max-height: 450px;
    background-color: #292929;
    border: 1px solid rgba(255, 255, 255, 0.12);
    border-radius: 8px;
    z-index: ${props => props.theme.zIndex.LayerTen};

    input, textarea {
        &:hover {
            outline: none;
            border: 1px solid rgba(255, 255, 255, 0.6);
        }
    }

    .actionPanel {
        margin-bottom: 8px;

        .deleteIconTag {
            /* margin-right: 17px; */
            font-size: 20px;
            
            path {
                fill:rgba(255, 255, 255, 0.6);
            }
        }

        .saveBtn {
            padding: 0px 4px;
            border: 0 none;
            cursor: pointer;
            -webkit-border-radius: 5px;
            border-radius: 5px; 
            min-width: 45px;
            width: auto;
            height: 24px;
            background: #8858C8;
            color: #fff;
            border-radius: 4px;
            font-size: 14px;
            font-weight: 500;

            &:hover {
                background: rgba(187, 134, 252, 1);
            }

            .lds-dual-ring {
                display: inline-block;
                width: 14px;
                height: 14px;
                margin-right: 2px;
            }

            .lds-dual-ring:after {
                position: relative;
                top: 2px;
                content: " ";
                display: block;
                width: 14px;
                height: 14px;
                border-radius: 50%;
                border: 2px solid #fff;
                border-color: #fff transparent #fff transparent;
                animation: lds-dual-ring 1.2s linear infinite;
            }
        }
    }

    .titleInput {
        padding: 8px;
        width: 100%;
        height: 36px;
        background: #1B1B1B;
        border: 1px solid rgba(255, 255, 255, 0.12);
        border-radius: 4px;
        color: #fff;
        &:focus {
            outline: none;
            border: 1px solid rgba(255, 255, 255, 0.6);
        }
    }

    .titleCharNumber, .textAreaCharNumber {
        margin-bottom: 12px;
        width: 100%;
        text-align: right;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.6);
    }

    .redText {
        color: #F34F66;
    }

    .textArea {
        padding: 8px;
        width: 100%;
        height: 76px;
        min-height: 76px;
        max-height: 76px;
        background: #1B1B1B;
        border: 1px solid rgba(255, 255, 255, 0.12);
        border-radius: 4px;
        color: #fff;
        &:focus {
            border: 1px solid rgba(255, 255, 255, 0.6);
            outline: none;
        }
    }

    .uploadLabel {
        position: relative;
        margin-bottom: 12px;
        padding: 0px 40px;
        width: 100%;
        height: 135px;
        background: #1B1B1B;
        border: 3px dashed rgba(255, 255, 255, 0.12);
        border-radius: 4px;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.5);
        text-align: center;
        &:hover {
            border: 1px solid rgba(255, 255, 255, 0.6);
        }

        input {
            display: none;
        }

        .previewImage {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: contain;
            background-color:#333;
        }
    }

    .uploadBtn {
        margin-top: 20px;
        padding: 8px 16px;
        background: rgba(255, 255, 255, 0.38);
        border-radius: 40px;
        color: #fff;

        svg {
            margin-right: 5px;
            font-size: 20px;
        }

        span {
            font-size: 14px;
            font-weight: 500;
        }

        &:hover {
            cursor: pointer;
            background: rgba(255, 255, 255, 0.6);
        }
    }

    .imageActionPanel {
        width: 100%;
        margin-bottom: 12px;

        svg {

            &:hvoer {
                cursor: pointer;
            }

            path {
                fill:rgba(255, 255, 255, 0.6);
            }
        }

        .deleteIconPanel {
            width: 24px;
            height: 24px;
            margin-right: 20px;

            &:hover {
                background: rgba(255, 255, 255, 0.12);
                border-radius: 4px;
            }
        }

        .deleteIconImage {
            
        }

        .updateIconPanel {
            width: 24px;
            height: 24px;

            &:hover {
                cursor: pointer;
                background: rgba(255, 255, 255, 0.12);
                border-radius: 4px;
            }
        }

        .updateIconImage {
            &:hover {
                cursor: pointer;
            }
        }
    }

    .urlInput {
        padding: 8px;
        width: 100%;
        height: 36px;
        background: #1B1B1B;
        border: 1px solid rgba(255, 255, 255, 0.12);
        border-radius: 4px;
        color: #fff;
        &:focus {
            outline: none;
            border: 1px solid rgba(255, 255, 255, 0.6);
        }
    }
    .isLoadingPic{
        width: 214px;
        height: 131px;
        position: absolute;
        background: #1B1B1B;
        border: 1px dashed rgba(255, 255, 255, 0.12);
        border-radius: 4px;
        left:14px;
        top: 222px;
        z-index: 2;
        .title{
            position: absolute;
            left:50%;
            top:28px;
            transform:translateX(-50%);
            font-weight: 500;
            font-size: 12px;
            line-height: 150%;
            text-align: center;
            letter-spacing: 0.02em;
            color: rgba(255, 255, 255, 0.8);
        }
        .text{
            width: 195px;
            height: 18px;
            top:76px;
            left:50%;
            display:flex;
            justify-content:space-between;
            transform:translateX(-50%);
            position: absolute;
            font-weight: 500;
            font-size: 12px;
            line-height: 150%;
            display: flex;
            align-items: center;
            letter-spacing: 0.02em;
            color: rgba(255, 255, 255, 0.5);
            .name{
                width: 163px;
                height: 18px;
                overflow:hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
        .progressBar{
            position: absolute;
            width: 195px;
            left:50%;
            transform:translateX(-50%);
            height: 4px;
            top: 102px;
            background: rgba(255, 255, 255, 0.12);
            border-radius: 12px;
        }
        .bar{
            height: 4px;
            width:${props => ((props.progress / 100) * 195) + 'px'};
            background: #54C68F;
            border-radius: 12px;
            transition:all ease .3s;
        }
    }
`;