import styled from 'styled-components';

export const StyledNavBottom = styled.div`
    width: 100%;
    height: 50px;
    .objectSelectorPanel {
        padding: 0 0 0 20px;
        width: 343px;
        height: 100%;
        position: relative;
        cursor: pointer;
        &:hover{
            background-color: #FDEAF2;
        }
        .popModelList{
            background-color: #fff;
            position: absolute;
            top:70px;
            width: 352px;
            border-radius: 8px;
            box-shadow: inset 0px -1px 0px #CCCCCC;
            .angle{
                position: absolute;
                top:-10px;
                right: 35px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 12px 14px 12px;
                border-color: transparent transparent #ffffff transparent;
            }
            .title{
                font-size: 14px;
                color: #666;
                font-weight:bold;
                border-bottom: 1px solid #ccc;
                padding: 10px 15px;
                cursor: auto;
            }
            .block{
                width: 100%;
                height: 200px;
                overflow: auto;
                margin-top: 10px;
                margin-bottom: 10px;
                cursor: auto;
                li{
                    display: flex;
                    justify-content: space-between;
                    padding: 11px 15px;
                    cursor: pointer;
                    &:hover{
                        background-color: #FFF1ED;
                    }
                    >div{
                        display: flex;
                        align-items: center;
                    }
                    .icon{
                        padding-right: 5px;
                        width: 29px;
                        svg{
                            position: relative;
                            top:-2px;
                            left:0;
                        }
                    }
                    .name{
                        width: 140px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        font-size: 14px;
                        color: #333;
                        font-weight:bold;
                    }
                    .isMaster{
                        cursor: pointer;
                        padding: 2px 8px;
                        background-color: #FFD8CE;
                        color: #FF744E;
                        line-height: 20px;
                        font-size: 12px;
                        text-align:center;
                        border-radius: 40px;
                    }
                    .isMasterNone{
                        opacity: 0;
                    }
                    .point{
                        cursor: pointer;
                        position: relative;
                        .pointIcon {
                            width: 24px;
                            height: 24px;
                            border-radius: 4px;
                            padding: 5px;
                            &:hover{
                                background: #FFD8CE;
                            }
                        }
                        .ctrlModel{
                            z-index: 3;
                            position: fixed;
                            width: 178px;
                            background-color: #fff;
                            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
                            border-radius: 4px;
                            li{
                                padding:10px 15px;
                                color: #333;
                                cursor: pointer;
                                font-size: 14px;
                                display: flex;
                                width: 100%;
                                justify-content: flex-start;
                                align-items: center;
                                &:last-child{
                                    border-top: 1px solid rgba(0,0,0,0.12);
                                }
                                .ii{
                                    padding-right: 5px;
                                }
                                svg{
                                    font-size: 16px;
                                    position: static;
                                    margin-left: 0;
                                }
                            }
                            .notClick{
                                pointer-events: none;
                                color: #CCCCCC;
                            }
                        }
                        svg{
                            position: relative;
                            top:-2px;
                            left:0;
                            margin-left: 15px;
                            font-size: 14px;
                        }
                    }
                }
            }
            .limitation {
                padding: 23px 40px 23px 48px;
                font-weight: 500;
                font-size: 13px;
                line-height: 140%;
                letter-spacing: 0.02em;
                color: #999999;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                .highlightWord {
                    color: #ED0973
                }
            }
            .btnCon{
                padding-top: 15px;
                padding-bottom: 15px;
                width: 100%;
                box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.1);
                text-align:center;
                .uploadModel{
                    display: inline-block;
                    min-width: 136px;
                    padding: 0 10px;
                    height: 32px;
                    border: 1px solid #ED2975;
                    color: #ED2975;
                    background: #fff;
                    border-radius: 40px;
                    text-align: center;
                    text-decoration: none;
                    font-size: 14px;
                    line-height: 30px;
                    margin: 0 auto;
                    &:hover {
                        background-color: #ED2975;
                        color: #fff;
                        svg {
                            color: #fff;
                        }
                    }
                    svg {
                        color: #ED2975;
                        position: static;
                    }
                }
                .disableBtn {
                    display: inline-block;
                    min-width: 136px;
                    padding: 0 10px;
                    height: 32px;
                    border: 1px solid #FAB5D5;
                    color: #FAB5D5;
                    background: #fff;
                    border-radius: 40px;
                    text-align: center;
                    text-decoration: none;
                    font-size: 14px;
                    line-height: 30px;
                    margin: 0 auto;
                    svg {
                        color: #FAB5D5;
                        position: static;
                    }
                }
            }
        }
        img{
            margin-right: 10px;
        }
        svg {
            font-size: 24px;
        }

        svg:nth-of-type(1) {
            position: absolute; 
            right:15px;
            top:10px;
        }

        svg:nth-of-type(2) {
            margin-left: auto;
        }

        span {
            margin-right: 8px;
            font-weight: 500;
            font-size: 14px;
            letter-spacing: 0.02em;
            color: #333333;
            display:inline-block;
            max-width:137px;
            overflow:hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
        }

        .objectTag {
            margin-right: 40px;
            padding: 2px 8px;
            background: #FFD8CE;
            border: 1px solid #FFD8CE;
            border-radius: 40px;
            font-size: 12px;
            color: #FF744E;
            white-space: nowrap;
        }
        .objectTagNone{
            display: none;
        }
    }
    .loadingDefault{
        opacity:0.3;
        cursor:default;
    }
    .modelViewerActionPanel {
        padding: 0px 54px 0px 24px;
        width: calc(100% - 343px);
        height: 100%;
        border: 1px solid #C4C4C4;

        .leftPanel {
            img{
                cursor: pointer;
                &:nth-child(2){
                    margin-left: 30px;
                }
            }
        }
        .rightPanel{
            cursor: pointer;
        }
    }
`;