import axios from 'axios';
import SERVER from 'constants/server';

const axioApi = axios.create({
    baseURL:SERVER.SERVER_URL,
});

export const getErrorMsg = (err) => err?.response?.data.msg || err?.response?.data.message;

export const setHeader = (key, value) => {
    axioApi.defaults.headers.common[key] = value;
};

export const setTimeOut = (time) => {
    axioApi.defaults.timeout = time;
};

export default axioApi;