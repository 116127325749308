import styled from 'styled-components';

export const StyledEditContainer = styled.div`
    width: 280px;
    height: 100%;
    background: #232323;
    /* overflow: auto; */

    .panelTitle {
        color: #fff;
        min-height: 50px;
        padding: 12px 16px;
        border-bottom: 1px solid #fff;
    }
`;