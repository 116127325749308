import axioApi, { setHeader } from 'api/root.api';

export const getGlobalSwitch = async (projectId, token)=>{
    const urlEnd = `/editor/project/v1/${projectId}/switch`;
    // setHeader('Access-Control-Allow-Origin', '*');
    setHeader('Authorization', `Bearer ${token}`);
    const response = await axioApi.get(urlEnd);
    const result = response.data;
    return result;
};

export const postGlobalSwitch = async (body, projectId, token)=>{
    const urlEnd = `/editor/project/v1/${projectId}/switch`;
    // setHeader('Access-Control-Allow-Origin', '*');
    setHeader('Content-Type', 'application/json');
    setHeader('Authorization', `Bearer ${token}`);
    const response = await axioApi.post(urlEnd, body);
    const result = response.data;
    return result;
};