export const NEW_TAG_PANEL = 'NEW_TAG_PANEL';
export const NEW_TAG_INPUT = 'NEW_TAG_INPUT';
export const NEW_TAG_CONTENT = 'NEW_TAG_CONTENT';
export const NEW_TAG_URL = 'NEW_TAG_URL';
export const NEW_TAG_IMAGE_FILE = 'NEW_TAG_IMAGE_FILE';
export const CLEAR_NEW_TAG_PANEL = 'CLEAR_NEW_TAG_PANEL';
export const REMOVE_NEW_TAG = 'REMOVE_NEW_TAG';


export const OLD_TAG_PANEL = 'OLD_TAG_PANEL';
export const OLD_TAG_TITLE = 'OLD_TAG_TITLE';
export const OLD_TAG_CONTENT = 'OLD_TAG_CONTENT';
export const OLD_TAG_URL = 'OLD_TAG_URL';
export const OLD_TAG_IMAGE_FILE = 'OLD_TAG_IMAGE_FILE';
export const OLD_TAG_DELETE_IMAGE_FILE = 'OLD_TAG_DELETE_IMAGE_FILE';
export const CLEAR_OLD_TAG_PANEL = 'CLEAR_OLD_TAG_PANEL';


export const EDITING_TAGS = 'EDITING_TAGS';
export const REMOVE_EDITING_TAG = 'REMOVE_EDITING_TAG';
export const EMPTY_EDITING_TAGS = 'EMPTY_EDITING_TAGS';


export const SIDEBAR_EDIT_TAG = 'SIDEBAR_EDIT_TAG';
export const CHANGE_TAG = 'CHANGE_TAG';
