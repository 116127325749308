import { SHADOW_CARD } from 'redux/types/shadowCard.types';

let SHADOW_CARD_RES = {
    shadowCard:{
        exposure: 1,
        shadowIntensity:0,
        shadowSoftness:0,
    },
};

const shadowCardReducer = (state = SHADOW_CARD_RES, action) => {
    switch (action.type) {
        case SHADOW_CARD:
            return {
                shadowCard: action.payload,
            };
        default: return state;
    }
};

export default shadowCardReducer;