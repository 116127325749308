import styled from 'styled-components';

export const StyledApp = styled.div`
    width: 100vw;
    max-width: 1920px;
    overflow:hidden;
    display: grid;
    grid-template-columns: 343px 1fr;
    z-index: ${props => props.theme.zIndex.LayerTen};
    .navSection {
        grid-column: 1 / 3;
        grid-row: 1 / 2;
    }

    .sidebarSection {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }

    .modelSection {
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }

    & * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    .flexY {
        flex-direction: column;
    }

    .hidden {
        display: none;
    }

    .flexCenter {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .flexStartCenter {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .flexEndCenter {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .flexBetweenCenter {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .flexCenterStart {
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }

    .flexCenterEnd {
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }

    .flexCenterBetween {
      display: flex;
      justify-content: center;
      align-items: space-between;
    }

    .flexStart {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }
`;