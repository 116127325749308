import styled from 'styled-components';

export const StyledModelAr = styled.div`
    width: 100vw;
    position: absolute;
    top:56px;
    /* background-color: mistyrose; */
    height: 100%;
    top:0;
    
    .isLoadingPanel {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: ${props => props.theme.zIndex.LayerTen};
        background: mistyrose
    }
    #myViewer {
        width: 100%;
        height: 100%;
        position: absolute;
        left:0;
        bottom: 0;
        opacity:0;
        .hotspot {
            z-index: ${props => props.theme.zIndex.LayerNine};
            position: relative;
            width: 28px;
            height: 28px;
            border-radius: 50%;
            border: 2px solid #fff;
            background: #E60363D9;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            &:hover {
                cursor: pointer;
                box-shadow: 0px 0px 0px 6px rgba(230,3,99,0.4);
            }
        }

        .infoCard {
            padding: 10px;
            z-index: ${props => props.theme.zIndex.LayerTen};
            position: absolute;
            display: none;
            width: 200px;
            height: auto;
            max-height: 320px;
            background: rgba(230, 3, 99, 0.85);
            border-radius: 8px;
        }

        .section1 {
            right: 30px;
            bottom: 0px;
        }

        .section2 {
            left: 30px;
            bottom: -245px;
        }

        .section3 {
            right: 30px;
            bottom: -245px;
        }

        .section4 {
            left: 30px;
            bottom: 0px;
        }
        .xSection1 {
            right: 30px;
            bottom: 0px;
        }

        .xSection2 {
            left: 30px;
            bottom: 0px;
        }

        .show {
            display: block;
        }

        .title {
            text-align: left;
            margin-bottom: ${props => props.onlyTitle ? '0px' : '8px'};
            width: 100%;
            min-height: 20px;
            height: auto;
            font-size: 14px;
        }

        .content {
            text-align: left;
            margin-bottom: 10px;
            width: 100%;
            height: 80px;
            font-size: 14px;
            overflow: auto;
        }

        .image {
            margin-bottom: 14px;
            width: 100%;
            height: 100px;
        }

        .urlPanel {
            text-align: left;
            width: 100%;
            height: 22px;
            font-size: 12px;

            .urlIcon {
                width: 20px;
                height: 20px;

                path {
                    fill: white;
                }
            }

            .url {
                top: 50%;
                flex: 1;
                width: calc(100% - 20px);
                height: 22px;

                & > span {
                    vertical-align: middle;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }

    .rightRemind{
        position: absolute;
        right: 30px;
        top: 30px;
        z-index: 10;
        li{
            width: 40px;
            height: 40px;
            margin-bottom: 20px;
            cursor: pointer;
            list-style: none;
            line-height: 40px;
            text-align:center;
            position: relative;
            box-shadow: 0px 0px 5px rgb(0 0 0 / 20%);
            border-radius: 8px;
            .blackRemind{
                display:none;
                align-items:center;
                position: absolute;
                right:45px;
                top:3px;
                @media all and (max-width:1024px) {
                    display:none;
                }
                .tt{
                    background: #000;
                    height:32px;
                    line-height:32px;
                    border-radius:4px;
                    padding:0 7px;
                    color:#fff;
                    font-size:14px;
                    white-space:nowrap;
                }
                .angle{
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 6px 0 6px 14px;
                    border-color: transparent transparent transparent #000000;
                }
            }
            svg{
                    fill:rgba(0,0,0,0.8);
                }
            &:hover{
                background-color: rgba(0,0,0,0.8);
                svg{
                    fill:#fff;
                }
                .blackRemind{
                    display:flex;
                    @media all and (max-width:1024px) {
                        display:none;
                    }
                }
            }
            &:last-child{
                margin-bottom: 0;
            }
            img{
                width: 35px;
            }
            .pop{
                position: absolute;
                width: 260px;
                height: 252px;
                background-color: rgba(0,0,0,0.87);
                padding:12px 24px;
                border-radius: 8px;
                top: 0;
                left: -270px;
                .top{
                    color: rgba(255,255,255,0.87);
                    font-size: 14px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    img{
                        width: 16px;
                        cursor: pointer;
                    }
                }
                table{
                    margin:0 auto;
                    width: 90%;
                    tr{
                        td{
                            font-size:14px;
                            color: #fff;
                            width: 50%;
                            text-align:left;
                            padding: 10px 0;
                            border-bottom: 1px solid rgba(255,255,255,0.12);
                            &:nth-child(2){
                                width: 25%;
                                text-align:right;
                            }
                            &:nth-child(3){
                                width: 25%;
                                text-align:right;
                            }
                        }
                        &:last-child{
                            td{
                                border:none;
                            }
                        }
                    }
                }
            }
        }
        .opa{
            opacity: 1;
        }
    }
    .arIcon{
        width: 40px;
        height: 40px;
        right: 30px;
        bottom: 30px;
        cursor: pointer;
        list-style: none;
        line-height: 40px;
        text-align:center;
        position: fixed;
        z-index: 2;
        box-shadow: 0px 0px 5px rgb(0 0 0 / 20%);
        border-radius: 8px;
        background-color: #fff;
        &:hover{
            background-color: rgba(0,0,0,0.8);
            svg{
                fill: #fff;
            }
            .blackRemind{
                display:flex;
                @media all and (max-width:1024px) {
                    display:none;
                }
            }
        }
        .blackRemind{
                display:none;
                align-items:center;
                position: absolute;
                right:45px;
                top:3px;
                @media all and (max-width:1024px) {
                    display:none;
                }
                .tt{
                    background: #000;
                    height:32px;
                    line-height:32px;
                    border-radius:4px;
                    padding:0 7px;
                    color:#fff;
                    font-size:14px;
                    white-space:nowrap;
                }
                .angle{
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 6px 0 6px 14px;
                    border-color: transparent transparent transparent #000000;
                }
            }
        svg{
            fill:rgba(0,0,0,0.8);
            width: 42px;
            position: relative;
            top:-2px;
        }
    }
    .smallBarPic{
        z-index: 10;
        width: 98px;
        height: 480px;
        position: absolute;
        left:0;
        top:100px;
        background-color: rgba(0,0,0,0.87);
        border-radius: 0 20px 20px 0;
        ul{
            margin-left: 30px;
            margin-top: 20px;
            margin-right: 0;
            margin-bottom: 0;
            padding: 0;
            li{
                width: 48px;
                height: 48px;
                margin-bottom: 15px;
                cursor: pointer;
                img{
                    border:1px solid rgba(255,255,255,0.4);
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center center;
                    border-radius: 4px;
                }
            }
        }
    }
`;