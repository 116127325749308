import { useState, useEffect } from 'react';

// styled components
import { StyledQRcode } from './StyledQRcode';

// redux
import { useSelector } from 'react-redux';

// react icon
import { AiOutlineClose } from 'react-icons/ai';

// plugin
import QRCode from 'qrcode.react';
import Cookies from 'js-cookie';

// i18n
import { useTranslation } from 'react-i18next';

// constants
import SERVER from 'constants/server';

const QRcode = (props) => {
    const store = useSelector(store => store);
    const closeQR = () => {
        props.onCallParent(false);
    };

    const [defaultUrl, setDefaultUrl] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        const secret = encodeURIComponent(store.secret.secret);
        const projectId = Cookies.get('projectId');
        const modelId = Cookies.get('modelId');
        const query = `?secret=${secret}&projectId=${projectId}&modelId=${modelId}&openExternalBrowser=1`;
        setDefaultUrl(`${SERVER.EDITOR_URL}/modelAr${query}`);
    }, []);

    return (
        <StyledQRcode>
            <div className='closeIcon' onClick={closeQR}>
                <AiOutlineClose />
            </div>
            <div className='qrcode_container'>
                <h1>
                    {t('arProjectionDevicePrompt')}
                </h1>
                <span>
                    {t('deviceSupportPrompt')}
                </span>
                <QRCode
                    className="qrcode"
                    id="bill_qr_code_url"
                    value={defaultUrl}
                    size={180}
                    fgColor="#000000"
                />
                <h1>
                    {t('qrcodeScanPrompt')}
                </h1>
                <span>
                    {t('scanQrcodePrompt')}
                </span>
            </div>
        </StyledQRcode>
    );
};

export default QRcode;