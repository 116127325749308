import styled from 'styled-components';

export const StyledModalPopupContainer = styled.div`
    position: fixed;
    top: 0;
    left: ${props=>props.mode === 'preview' ? '50%' : '0px'};
    transform:${props=>props.mode === 'preview' ? 'translateX(-50%)' : 'translateX(0%)'};;
    width: ${props=>props.mode === 'editor' ? 'calc(100vw - 343px)' : props.mode === 'preview' ? '50vw' : '100vw '};
    height: 100vh;
    z-index: ${props => props.theme.zIndex.LayerTen};
    background:${props=>props.cover ? '#fff' : 'rgba(0, 0, 0, 0.8)'};
    margin-top:${props=>props.cover ? '56px' : '0px'};
    margin-left:${props=>props.mode === 'editor' ? '343px' : '0px'};

    .modalPanel {
        width: 560px;
        height: 360px;
        background: #FFFFFF;
        border-radius: 12px;
    }

    .topSection {
        padding: 25px 15px;
        width: 100%;
        height: 48px;
        border-radius: 12px 12px 0px 0px;
        svg {
            font-size: 30px;
            fill: #222;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .bottomSection {
        padding: 10px 32px 32px 32px;
        width: 100%;
        height: calc(100% - 48px);
        align-content: center;
    }

    .iconPanel {
        margin-bottom: 29px;

        img {
            width: 72px;
            height: 72px;
        }
    }

    .descPanel {
        margin-bottom: 4px;
        color: #333;
        font-weight: 500;
        font-size: 15px;
        span{
            color: #f34f66;
        }
    }

    .noticePanel {
        margin-bottom: 50px;
        color: rgba(0, 0, 0, 0.6);
        font-weight: 500;
        font-size: 13px;
        span{
            color: #f34f66;
        }
    }

    .cancelButton {
        background: rgba(0, 0, 0, 0.05);
        padding:8px 24px;
        border: 1px solid #F2F2F2;
        border-radius: 8px;
        order:${props => props.type === 'file-save' ? 1 : props.type === 'file-preview' ? 1 : 2};
        &:hover {
            cursor: pointer;
            background: rgba(0, 0, 0, 0.12);
        }
    }
    .confirmButtons{
        display: flex;
        flex-direction: row;
        column-gap: 24px;
    }
    .confirmButton {
        background: ${props => props.type === 'delete' ? '#F5314D' : props.type === 'file-delete' ? '#F5314D' : '#27282C'};
        order:${props => props.type === 'file-save' ? 2 : props.type === 'file-preview' ? 2 : 1};
        border-radius: 8px;
        color: #FFFFFF;
        padding:8px 24px;
        cursor: pointer;
        
        &:hover { 
            background: ${props => props.type === 'delete' ? '#F34F66' : props.type === 'file-delete' ? '#F34F66' : '#47484B'};
        }
    }

    .okButton {
        background: #ED0973;
        border-radius: 8px;
        padding:12px 24px;
        color: #fff;
        cursor: pointer;

        &:hover {
            background: #F2539D;
        }
    }
    .flexReverseRow {
        flex-direction: row-reverse;
    }

`;