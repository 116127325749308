import { GET_MODEL_LIST, GET_TAG_LIST, MODEL_IS_LOADING } from 'redux/types/model.types';



let INIT_MODE = {
    tagList: [],
    modelList: [],
    isLoading: true,
};


const modelReducer = (state = INIT_MODE, action) => {
    switch (action.type) {
        case GET_TAG_LIST:
            return {
                ...state, tagList: [...action.payload],
            };

        case GET_MODEL_LIST:
            return {
                ...state, modelList: [...action.payload],
            };

        case MODEL_IS_LOADING:
            return {
                ...state, isLoading: action.payload,
            };
        

        default:
            return state;
    }


};

export default modelReducer;