import styled from 'styled-components';

export const StyledLoading = styled.div`
    background-color: rgba(0,0,0,0.8);
    position: fixed;
    left:0;
    top:0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    .whiteBg{
        width: 560px;
        height: 360px;
        background-color: #fff;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        flex-wrap: wrap;
        position: relative;
        #loading-wrapper{
            width: 72px;
            height: 72px;
            margin-bottom: 30px;
        }
        #loading-content {
            display: block;
            position: relative;
            width: 100%;
            height: 100%;
            margin:0 auto;
            border: 2px solid #F00;
        }

        #loading-content:after {
            content: "";
            position: absolute;
            border: 2px solid #0F0;
            left: 15px;
            right: 15px;
            top: 15px;
            bottom: 15px;
        }

        #loading-content:before {
            content: "";
            position: absolute;
            border: 2px solid #00F;
            left: 5px;
            right: 5px;
            top: 5px;
            bottom: 5px;
        }

        #loading-content {
            border: 2px solid transparent;
            border-top-color: #54C68F;
            border-bottom-color: #54C68F;
            border-radius: 50%;
            animation: loader 2s linear infinite;
        }

        #loading-content:before {
            border: 2px solid transparent;
            border-top-color: #54C68F;
            border-bottom-color: #54C68F;
            border-radius: 50%;
            animation: loader 3s linear infinite;
        }

        #loading-content:after {
            border: 2px solid transparent;
            border-top-color: #54C68F;
            border-bottom-color: #54C68F;
            border-radius: 50%;
            animation: loader 1.5s linear infinite;
        }
        .title{
            width: 100%;
            text-align:center;
            font-size: 15px;
            color: #333333;
            font-weight: bold;
            padding-bottom: 2px;
        }
        .txt{
            text-align:center;
            font-size: 13px;
            color: rgba(0,0,0,0.6);
            width: 100%;
        }
    }

    @keyframes loader {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;