// react
import { useState, useEffect } from 'react';

// components
import MobilePopup from 'components/MobilePopup/MobilePopup';

// styled components
import { StyledModelAr } from './StyledModelAr';

// api
import { getArModel } from 'api/getArModel/getArModel';

// plugin
import '@google/model-viewer/dist/model-viewer';

const ModelAr = () => {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const [modelInfo, setModel] = useState([]);
    const [popupType, setPopupType] = useState('');
    
    useEffect(() => {
        const secret = decodeURIComponent(urlParams.get('secret'));
        const projectId = urlParams.get('projectId');
        const modelId = urlParams.get('modelId');

        // 取得AR投放素材
        getArModel(secret, projectId, modelId).then((res) => {
            setPopupType('偵測裝置中');
            setModel(res);
            // 判斷回傳內容有值
            if (res !== []) {
                // 當使用者使用ios系統開啟，判斷物件是否缺少 USDZ
                if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
                    if (res?.usdzSrc === '') {
                        setPopupType('無法使用');
                    } else {
                        const getMovelViewer = document.querySelector('#myViewer');
                        getMovelViewer.addEventListener('load', () => {
                            setPopupType('偵測裝置成功');
                            getMovelViewer.activateAR();
                        });
                    }
                } else {
                    // 其餘系統不需判斷檔案，直接開啟AR投放
                    const getMovelViewer = document.querySelector('#myViewer');
                    getMovelViewer.addEventListener('load', () => {
                        setPopupType('偵測裝置成功');
                        getMovelViewer.activateAR();
                    });
                }
            } else {
                setPopupType('QRCode已失效');
            }
        }).catch(() => {
            setPopupType('QRCode已失效');
        });
    }, []);

    return (
        <StyledModelAr className="flexCenter">
            <MobilePopup type={popupType} />
            <model-viewer
                ar
                camera-controls
                id="myViewer"
                src={`${modelInfo?.lastUploadFileType === 'gltf' ? modelInfo?.gltfSrc : modelInfo?.glbSrc}`}
                ios-src={modelInfo?.usdzSrc}
                poster=""
                ar-modes="webxr scene-viewer quick-look"
                seamless-poster=""
                loading="auto"
            >
            </model-viewer>
        </StyledModelAr>
    );
};

export default ModelAr;
