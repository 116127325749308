import { SWITCH, TAG_SWITCH, AR_SWITCH } from 'redux/types/switch.types';

let USER_SWITCH = {
    objectInfoSwitch: '',
    objectTagSwitch: '',
    objectArSwitch: '',
};

const switchReducer = (state = USER_SWITCH, action) => {
    switch (action.type) {
        case SWITCH:
            return {
                ...state, objectInfoSwitch: action.payload,
            };
        case TAG_SWITCH:
            return {
                ...state, objectTagSwitch: action.payload,
            };
        case AR_SWITCH:
            return {
                ...state, objectArSwitch: action.payload,
            };
        default: return state;
    }
};

export default switchReducer;