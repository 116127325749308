const tapDistance = 2;

export const handleCameraMovement = (viewMode) => {
    const modelViewerID = viewMode === 'preview' ? '#myViewer' : '#myEditor';
    const getModelViewer = document.querySelector(modelViewerID); 
    getModelViewer.addEventListener('contextmenu', ( e )=> { e.preventDefault(); return false; } );
    let panning = false;
    let panX = [0, 0, 0];
    let panY = [0, 0, 0];
    let startX = 0;
    let startY = 0;
    let lastX = 0;
    let lastY = 0;
    let metersPerPixel = 0;
    let target = { x: 0, y: 0, z: 0 };

    const startPan = () => {
        const orbit = getModelViewer.getCameraOrbit();
        const { theta, phi, radius } = orbit;
        const psi = theta - getModelViewer.turntableRotation;
        metersPerPixel = (2 * radius) / getModelViewer.getBoundingClientRect().height;
        target = getModelViewer.getCameraTarget();
        panX = [-Math.cos(psi), 0, Math.sin(psi)];
        panY = [
            -Math.cos(phi) * Math.sin(psi),
            Math.sin(phi),
            -Math.cos(phi) * Math.cos(psi),
        ];
        getModelViewer.interactionPrompt = 'none';
    };

    const movePan = (thisX, thisY) => {
        const dx = (thisX - lastX) * metersPerPixel;
        const dy = (thisY - lastY) * metersPerPixel;
        lastX = thisX;
        lastY = thisY;

        target.x += dx * panX[0] + dy * panY[0];
        target.y += dx * panX[1] + dy * panY[1];
        target.z += dx * panX[2] + dy * panY[2];
        getModelViewer.cameraTarget = `${target.x}m ${target.y}m ${target.z}m`;

        // This pauses turntable rotation
        // this.modelViewer.dispatchEvent(new CustomEvent(
        //     'camera-change', { detail: { source: 'user-interaction' } }
        // ));
    };

    const recenter = (pointer) => {
        panning = false;
        if (Math.abs(pointer.clientX - startX) > tapDistance ||
            Math.abs(pointer.clientY - startY) > tapDistance) return;
        const rect = getModelViewer.getBoundingClientRect();
        const x = pointer.clientX - rect.left;
        const y = pointer.clientY - rect.top;
        const hit = getModelViewer.positionAndNormalFromPoint(x, y);
        if (!hit) {
            getModelViewer.cameraTarget = 'auto auto auto';
        }
    };

    getModelViewer.addEventListener('mousedown', (event) => {

        if (panning) return;

        startX = event.clientX;
        startY = event.clientY;

        panning = event.button === 2 || event.ctrlKey || event.metaKey || event.shiftKey;
        if (!panning) return;
        lastX = startX;
        lastY = startY;
        startPan();
        event.stopPropagation();
    });

    getModelViewer.addEventListener('mousemove', (event) => {
        if (!panning) return;

        movePan(event.clientX, event.clientY);
        event.stopPropagation();
    });

    getModelViewer.addEventListener('mouseup', (e) => {
        panning = false;
        recenter(e);
    });


};