import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// styled components
import { StyledObjectInfo } from './StyledObjectInfo';

//components
import Switch from '../Switch/Switch';
import ModalPopup from 'components/ModalPopup/ModalPopup';
import LoadingMask from 'components/LoadingMask/LoadingMask';

// hooks
import useModal from 'hooks/useModal';

// config
import { modalConfig } from 'config/modal';

//api
import { smallPicUpload } from 'api/smallPicUpload/smallPicUpload.api';
import { applyModelIcon } from 'api/applyModelIcon/applyModelIcon.api';
import { getModelList } from 'api/getModelList/getModelList';

//react icon
import { AiOutlineCloudUpload } from 'react-icons/ai';

//plugin
import Cookies from 'js-cookie';
import * as dayjs from 'dayjs';

//redux
import { SWITCH } from 'redux/types/switch.types';
import { ICON } from 'redux/types/icon.types';
import { GET_MODEL_LIST } from 'redux/types/model.types';


//images
import defaultModel from './images/defaul.svg';
import Union from './images/Union.svg';
import trash from './images/trash.svg';

//i18n
import { useTranslation } from 'react-i18next';

function sortFn(modelList) {
    let sortAry = modelList.sort((a, b) => {
        return dayjs(b.lastUpdated).unix() - dayjs(a.lastUpdated).unix();
    });

    for (let i = 0; i < sortAry.length; i++) {
        if (sortAry[i].mainObject === true) {
            let mainTemp = sortAry[i];
            sortAry[i] = sortAry[0];
            sortAry[0] = mainTemp;
        }
    }

    return sortAry;
}

const ObjectInfo = (props) => {

    const { t, i18n } = useTranslation();
    const store = useSelector(store => store);
    const dispatch = useDispatch();
    const projectId = parseInt(Cookies.get('projectId'));
    const [status, setStatus] = React.useState(store.switch?.objectInfoSwitch);
    const [smallPicList, setSmallList] = React.useState(store.model?.modelList);
    const [imgUrl, setUrl] = React.useState('');
    const { netStatus } = props;
    const [picHolder, setPicHolder] = React.useState(true);
    const [modelName, setModelName] = React.useState('');
    const [picUploadProgress, setPicUploadProgress] = React.useState(0);

    const setStatusEvent = () => {
        if (store.model.isLoading) return;
        dispatch({ type: SWITCH, payload: !status });
        setStatus(!status);
    };

    React.useEffect(() => {
        let modelList = store.model?.modelList.slice(0);
        if (modelList.find(model => model.id === parseFloat(Cookies.get('modelId'))) !== '') {
            if (modelList.find(model => model.id === parseFloat(Cookies.get('modelId')))?.lastUploadFileType === 'gltf') {
                setModelName(modelList.find(model => model.id === parseFloat(Cookies.get('modelId')))?.gltfName);
            } else {
                setModelName(modelList.find(model => model.id === parseFloat(Cookies.get('modelId')))?.glbName);
            }
        }
        setSmallList(store.model?.modelList);
    }, [store.model?.modelList]);

    React.useEffect(() => {
        setUrl(store.icon?.icon);
    }, [store.icon?.icon]);

    React.useEffect(() => {
        setStatus(store.switch?.objectInfoSwitch);
    }, [store.switch?.objectInfoSwitch]);

    const [isUpload, setIsUpload] = React.useState(null);
    const [isUploadBool, setUploadBool] = React.useState(false);
    const { modalSetting, setModalSetting } = useModal();
    const [uploadIng, setUploadIng] = React.useState(false);

    const handleErrorResponse = (modalType) => {
        setModalSetting({
            ...modalSetting,
            show: true,
            title: '',
            type: modalType,
            handleConfirm: () => { },
        });
    };

    React.useEffect(() => {
        setUploadIng(false);
    }, []);

    React.useEffect(() => {
        if (imgUrl === '') {
            setUploadBool(false);
        } else {
            // 首次載入，且有照片
            setUploadBool(true);
            setPicHolder(true);
            setTimeout(() => {
                setPicHolder(false);
            }, 300);
        }

        if (isUpload !== null) {
            let fileData = new FormData();
            fileData.append('file', isUpload);
            setUploadIng(true);
            const handleUploadBar = (progress) => {
                setPicUploadProgress(progress);
            };
            smallPicUpload(fileData, Cookies.get('token'), Cookies.get('projectId'), Cookies.get('modelId'), handleUploadBar).then((res) => {
                dispatch({ type: ICON, payload: res.fileDownloadUri });
                getModelList(projectId).then((modelList) => {
                    dispatch({ type: GET_MODEL_LIST, payload: sortFn(modelList) });
                });
                setUploadBool(true);
                let render = new FileReader();
                render.readAsDataURL(isUpload);
                render.addEventListener('load', () => {
                    setUrl(render.result);
                    // console.log(render.result);
                    setUploadIng(false);
                    let imgList = document.querySelectorAll('.imgList');

                    for (let i = 0; i < imgList.length; i++) {
                        if (imgList[i].getAttribute('data-id') === Cookies.get('modelId')) {
                            imgList[i].setAttribute('src', store.icon?.icon);
                        }
                    }
                    // store.icon?.icon
                });
            }).catch((err) => {
                // alert();
                setIsUpload(null);
                setUploadIng(false);
                handleErrorResponse('type26');
            });
        }


    }, [imgUrl, isUpload]);

    const uploadSmailPic = (e) => {
        if (e.target.files[0] === undefined) {
            return;
        }
        if (e.target.files[0]?.type != 'image/jpeg' && e.target.files[0]?.type != 'image/png' && e.target.files[0] != null) {
            setModalSetting({
                ...modalSetting,
                show: true,
                type: 'type2',
            });
            return;
        }
        if (e.target.files[0]?.size / (1024 * 1024) > 2) {
            setModalSetting({
                ...modalSetting,
                show: true,
                type: 'type3',
            });
            return;
        }
        setIsUpload(e.target.files[0]);
        setUrl('');
        setTimeout(() => {
            setPicHolder(false);
        }, 300);
    };

    const deletePicEvent = () => {
        setModalSetting({
            ...modalSetting,
            show: true,
            type: 'type7',
            handleConfirm: () => {
                applyModelIcon({ icon: '' }, Cookies.get('token'), Cookies.get('projectId'), Cookies.get('modelId')).then((res) => {
                    setUploadBool(false);
                    setIsUpload(null);
                    setUrl('');
                    dispatch({ type: ICON, payload: '' });
                    getModelList(projectId).then((modelList) => {
                        dispatch({ type: GET_MODEL_LIST, payload: sortFn(modelList) });
                    });
                }).catch((err) => {
                    handleErrorResponse('type19');

                });
            },
        });
    };


    // src 載入狀況
    React.useEffect(() => {
        setTimeout(() => {
            setPicHolder(false);
        }, 300);
    }, [imgUrl]);

    return (
        <StyledObjectInfo progress={picUploadProgress}>
            {modalSetting.show && (
                <ModalPopup
                    modalConfig={modalConfig[modalSetting.type]}
                    setModalSetting={setModalSetting}
                    modalSetting={modalSetting}
                />
            )}
            <div className="rightBar" style={{ display: status && !store.model.isLoading ? 'block' : 'none' }} draggable="false">
                {/* {isUploadBool ? <img src={imgUrl} className="imgList" /> : <img src={defaultModel} className="imgList" />} */}
                {
                    smallPicList.map((item) => {
                        return (
                            item.icon !== '' ? <img key={item.id} data-id={item.id} src={item.icon} className="imgList" draggable="false" /> : <img key={item.id} data-id={item.id} src={defaultModel} className="imgList" draggable="false" />
                        );
                    })
                }
            </div>
            {
                netStatus &&
                <div className="title">
                    <div className="name">{t('objectThumbnail')}</div>
                    <div className="switch" onClick={setStatusEvent}>
                        <Switch turn={status} />
                    </div>
                </div>
            }
            {
                picHolder && <div className="modelList holder">
                    {
                        <div className="picHolder"></div>
                    }
                    {!picHolder && <div className="ctrlBar">
                        <span className="icon">
                            <img src={trash} />
                        </span>
                        <label htmlFor="file" className="icon">
                            <img src={Union} />
                        </label>
                    </div>}
                </div>
            }
            {/* // 當沒有照片的時候 */}
            {
                !isUploadBool && netStatus &&
                <div className={!status ? 'disabledObjInfo' : ''}>
                    {
                        store.model.isLoading && <div className='stopClickMask'></div>
                    }
                    <label htmlFor="file" className="uploadPic">
                        {isUpload !== null && <div className="isLoadingPic">
                            <div className="title">上傳中...</div>
                            <div className="text">
                                <div className="name">
                                    {isUpload.name}
                                </div>
                                <div className="num">{picUploadProgress}%</div>
                            </div>
                            <div className="progressBar">
                                <div className="bar"></div>
                            </div>
                        </div>}
                        <div className="word">
                            {t('uploadImagePrompt')}
                        </div>
                        <b className="fileBtn">
                            <span className="icon">
                                <AiOutlineCloudUpload />
                            </span>
                            {t('upload')}
                        </b>
                    </label>
                </div>
            }
            {
                // 當有照片的時候
                isUploadBool && netStatus && <div className={status ? 'modelList' : 'modelList disabledObjInfo'} style={{ opacity: store.model.isLoading ? '0.3' : '1' }}>
                    {
                        !status || store.model.isLoading && <div className='stopClickMask'></div>
                    }
                    {
                        // picHolder &&
                        // <div className="picHolder"></div>
                    }
                    <label htmlFor="file" className="picContainer">
                        <img className="pic" src={imgUrl} draggable='false' />
                    </label>
                    <div className="ctrlBar">
                        <span className="icon" onClick={deletePicEvent}>
                            <img src={trash} draggable='false' />
                        </span>
                        <label htmlFor="file" className="icon">
                            <img src={Union} draggable='false' />
                        </label>
                    </div>
                </div>
            }
            <input onChange={uploadSmailPic} type="file" id="file" />
            {
                uploadIng && <LoadingMask />
            }
        </StyledObjectInfo>
    );
};

export default ObjectInfo;