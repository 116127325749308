// react
import React from 'react';
import { useLocation } from 'react-router-dom';

// redux
import { useSelector } from 'react-redux';

// styled components
import { StyledNav } from './StyledNav';

// components
import NavTop from 'common/Nav/NavTop/NavTop';
import NavBottom from 'common/Nav/NavBottom/NavBottom';

const Nav = (props) => {
    const location = useLocation();
    const store = useSelector(store => store);
    const { refetchModelList, modelListIsLoading, netStatus, modelStatus } = props;
    const [pathName, setPathName] = React.useState(location.pathname);
    const [isPreview, setPreview] = React.useState(false);

    React.useEffect(() => {
        if (location.pathname === '/preview') {
            setPreview(true);
        } else {
            setPreview(false);
        }
        setPathName(location.pathname);
        // console.log(pathName, isPreview);
    }, [location.pathname]);

    React.useEffect(() => {
        // console.log('hhh', isPreview);
    }, [isPreview]);

    return (
        <StyledNav className="navSection">
            <NavTop refetchModelList={refetchModelList} modelListIsLoading={modelListIsLoading} netStatus={netStatus} modelStatus={modelStatus} isPreview={isPreview} />
            {
                !isPreview && <NavBottom />
            }
        </StyledNav>
    );
};

export default Nav;
