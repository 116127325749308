import styled from 'styled-components';

export const StyledArOpen = styled.div`
    padding: 25px;
    position: relative;
    .title{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 15px;
        .name{
            font-size: 14px;
            color: rgba(255,255,255,0.6);
            width: 163px;
            height: 18px;
            overflow:hidden;
            display:flex;
            p{
                overflow:hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                text-align:left;
                max-width:100px;
            }
        }
    }
    .remind{
        text-align:center;
        margin-top: 32px;
        .arIcon{
            opacity:0.7;
            width: 30px;
        }
        .word{
            color:rgba(255, 255, 255, 0.6);
            font-size:12px;
            padding-top:10px;
        }
        .tt{
            font-size:12px;
            color:rgba(255, 255, 255, 0.3);
        }
    }
    .middleTitle{
        padding: 30px 0 0 0;
        img{
            margin-right:10px;
        }
        span{
            color:rgba(255,158,131,0.7);
            font-size:12px;
            width: 54px;
            height:20px;
            line-height:20px;
            text-align:center;
            border-radius: 40px;
            display:inline-block;
            background: rgba(255, 116, 78, 0.16);
        }
    }
    section{
        height: 135px;
    }
    .file{
        position:relative;
        margin-top:10px;
        width: 100%;
        min-height: 135px;
        display: flex;
        justify-content: space-between;
        align-content: center;
        flex-wrap: wrap;
        color:rgba(255, 255, 255, 0.5);
        background: #1B1B1B;
        border: 1px dashed rgba(255, 255, 255, 0.12);
        border-radius: 4px;
        cursor:pointer;
        &:hover{
            background-color: rgba(0,0,0,0.05);
            border: 1px solid rgba(255,255,255,0.12);
            b{
                background: rgba(255,255,255,0.6);
            }
        }
        .status{
            color: #fff;
            img{
                width: 20px;
                margin-left: 5px;
                position: relative;
                top:-2px;
            }
            & + div{
                display: inline-block;
                width: 100%;
                padding:0 10px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }
        >div{
            font-size:12px;
            text-align:center;
            width:100%;
            padding-bottom:10px;
        }
        b{
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.02em;
            color: #FFFFFF;
            padding: 4px 16px;
            border: none;
            border-radius: 40px;
            margin: 0 auto;
            text-align: center;
            background: rgba(255, 255, 255, 0.38);
            display: flex;
            align-items: center;
            svg {
                font-size: 20px;
                margin-right: 8px;
            }
        }
    }
    .finshCon{
        width: 100%;
        padding-top: 5px;
        >div{
            display: inline-block;
            margin-right: 10px;
            cursor: pointer;
            label{
                cursor: pointer;
            }
            svg{
                color: rgba(204, 204, 204, 1);
                &:hover{
                    color: #fff;
                }
            }
        }
    }
    .isLoadingPic{
        width: 100%;
        height: 135px;
        position: absolute;
        background: #1B1B1B;
        border: 1px dashed rgba(255, 255, 255, 0.12);
        border-radius: 4px;
        left:0;
        top: 0;
        z-index: 2;
        .successTitle{
            font-weight: 500;
            font-size: 12px;
            line-height: 150%;
            color: rgba(255, 255, 255, 0.8);
            margin-top:27px;
            text-align:center;
            img{
                width:20px;
                height:20px;
                margin-left:4px;
            }
        }
        .title{
            position: absolute;
            left:50%;
            top:28px;
            transform:translateX(-50%);
            font-weight: 500;
            font-size: 12px;
            line-height: 150%;
            text-align: center;
            letter-spacing: 0.02em;
            color: rgba(255, 255, 255, 0.8);
        }
        .text{
            width: 195px;
            height: 18px;
            top:76px;
            left:50%;
            display:flex;
            justify-content:space-between;
            transform:translateX(-50%);
            position: absolute;
            font-weight: 500;
            font-size: 12px;
            line-height: 150%;
            display: flex;
            align-items: center;
            letter-spacing: 0.02em;
            color: rgba(255, 255, 255, 0.5);
            .name{
                width: 163px;
                height: 18px;
                overflow:hidden;
                display:flex;
                p{
                    overflow:hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    text-align:left;
                    max-width:100px;
                }
            }
        }
        .progressBar{
            position: absolute;
            width: 195px;
            left:50%;
            transform:translateX(-50%);
            height: 4px;
            top: 102px;
            background: rgba(255, 255, 255, 0.12);
            border-radius: 12px;
        }
        .bar{
            height: 4px;
            width:${props => ((props.progress / 100) * 195) + 'px'};
            background: #54C68F;
            border-radius: 12px;
        }
        .progressBar_already{
            position: absolute;
            width: 195px;
            left:50%;
            transform:translateX(-50%);
            height: 4px;
            top: 102px;
            background: #54C68F;
            border-radius: 12px;
        }
    }
`;