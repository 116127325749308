import styled from 'styled-components';

export const StyledSwitch = styled.div`
    width: 24px;
    height: 10px;
    border-radius: 10px;
    background-color: #909090;
    position: relative;
    cursor: pointer;
    label{
        position: absolute;
        left:0;
        top:-1px;
    }
    .color{
        width: 0;
        height: 100%;
        border-radius: 10px;
        background-color: #909090;
        position: relative;
        .shape{
            width: 12px;
            height: 12px;
            border-radius: 50%;
            position: absolute;
            top:-1px;
            left:0;
            background-color: #fff;
            transition: 0.25s left;
        }
        .shapeMove{
            left:13px;
        }
        input[type="checkbox"]{
            display: none;
        }
    }
    .colorMove{
        width: 100%;
        background-color: #FF744E;
    }
`;