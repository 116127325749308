import en from 'i18n/en.json';
import tw from 'i18n/zh-tw.json';
import fr from 'i18n/fr.json';
import ko from 'i18n/ko.json';

import Cookies from 'js-cookie';

const iconDelete = './assets/icons/icon-delete.svg';
const iconAlertFile = './assets/icons/icon-alert-file.svg';
const iconAlertTag = './assets/icons/icon-alert-tag.svg';
const iconExit = './assets/icons/icon-exit.svg';
const iconAlert = './assets/icons/icon-alert-warn.svg';
const iconCould = './assets/icons/icon-could.svg';
const iconPublic = './assets/icons/Public.svg';
const iconUpdate = './assets/icons/Update.svg';
const iconPreview = './assets/icons/Preview.svg';
const iconUpload = './assets/icons/icon-upload.svg';

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
let lang;

if (urlParams.get('lang')){
    if (urlParams.get('lang') === 'en'){
        lang = en;
    } else if (urlParams.get('lang') === 'fr'){
        lang = fr;
    } else if (urlParams.get('lang') === 'ko') {
        lang = ko;
    } else {
        lang = tw;
    }
} else {
    if (Cookies.get('lang') === 'en') {
        lang = en;
    } else if (Cookies.get('lang') === 'fr') {
        lang = fr;
    } else if (Cookies.get('lang') === 'ko') {
        lang = ko;
    } else {
        lang = tw;
    }
}


export const modalConfig = {
    type1: {
        icon: iconDelete,
        type: 'delete',
        desc: [lang.deleteTagPrompt1, '', lang.deleteTagPrompt2],
        notice: lang.tagDeletePrompt,
    },
    type2: {
        icon: iconAlertFile,
        type: 'alert-format',
        desc: lang.fileFormatFail,
        notice: lang.imageSizePrompt,
    },
    type3: {
        icon: iconAlertFile,
        type: 'alert-storage',
        desc: lang.modelSizeError1,
        notice: lang.imageSizePrompt,
    },
    type4: {
        icon: iconAlertTag,
        type: 'alert-tag-name',
        desc: '所有標籤須包含標題。',
        notice: '為標籤添加標題 或 刪除無標題標籤。',
    },
    type5: {
        icon: iconExit,
        type: 'exit-editor',
        desc: '要離開編輯器嗎?',
        notice: '系統不會自動儲存你所做的變更。',
    },
    type6: {
        icon: iconExit,
        type: 'exit-tag',
        desc: lang.leaveObjectTagPrompt1,
        notice: lang.leaveObjectTagPrompt2,
    },
    type7: {
        icon: iconDelete,
        type: 'file-delete',
        desc: lang.deleteObjectThumbnailPrompt,
        notice: lang.deleteProjectRecoverPrompt,
    },
    type8: {
        icon: iconAlertFile,
        type: 'alert-format',
        desc: lang.fileFormatFail,
        notice: '支援: 比例 2:1 (例:2880x1440) 且小於 2MB 的 HDR、JPG、PNG 檔。  ',
    },
    type9: {
        icon: iconDelete,
        type: 'file-delete',
        desc: '要刪除已上傳的HDR?',
        notice: '刪除後將無法復原。',
    },
    type10: {
        icon: iconAlert,
        type: 'file-save',
        desc: lang.saveAndPublicPrompt,
        notice: lang.unSavedProjectPrompt1,
    },
    type11: {
        icon: iconAlert,
        type: 'file-save',
        desc: '您有未儲存的變更，是否儲存並預覽專案?',
        notice: '未儲存的變更將遺失且不會顯示於預覽畫面。',
    },
    type12: {
        icon: iconCould,
        type: 'fail-save',
        desc: lang.loadEditorFail,
        notice: lang.internetError,
    },
    type13: {
        icon: iconPreview,
        type: 'file-preview',
        desc: lang.saveAndPreviewPrompt1,
        notice: lang.saveAndPreviewPrompt2,
    },
    type14: {
        icon: iconCould,
        type: 'fail-save',
        desc: lang.saveProjectFail,
        notice: lang.internetError,
    },
    type15: {
        icon: iconCould,
        type: 'fail-save',
        desc: '取得環境照明失敗。',
        notice: lang.internetError,
    },
    type16: {
        icon: iconCould,
        type: 'fail-save',
        desc: '上傳環境照明失敗。',
        notice: lang.internetError,
    },
    type17: {
        icon: iconCould,
        type: 'fail-save',
        desc: '刪除環境照明失敗。',
        notice: lang.internetError,
    },
    type18: {
        icon: iconCould,
        type: 'fail-save',
        desc: '上傳縮圖失敗。',
        notice: lang.internetError,
    },
    type19: {
        icon: iconCould,
        type: 'fail-save',
        desc: lang.deleteObjectThumbnailFail,
        notice: lang.internetError,
    },
    type20: {
        icon: iconDelete,
        type: 'delete',
        desc: [lang.deleteObjectPrompt1, '', lang.deleteObjectPrompt2],
        notice: lang.objectDeletePrompt,
    },
    type21: {
        icon: iconAlertFile,
        type: 'alert-storage',
        desc: lang.modelSizeError1,
        notice: lang.usdzSupportPrompt,
    },
    type22: {
        icon: iconAlertFile,
        type: 'alert-storage',
        desc: lang.uploadOneFilePrompt,
        notice: lang.usdzSupportPrompt,
    },
    type23: {
        icon: iconAlertFile,
        type: 'alert-storage',
        desc: lang.fileFormatFail,
        notice: lang.usdzSupportPrompt,
    },
    type24: {
        icon: iconPublic,
        type: 'file-save',
        desc: lang.saveAndPublishTitle,
        notice: lang.saveAndPublishDec,
    },
    type25: {
        icon: iconCould,
        type: 'fail-save',
        desc: lang.errorWhilePublishing,
        notice: lang.internetError,
    },
    type26: {
        icon: iconCould,
        type: 'fail-save',
        desc: lang.errorWhileUploading,
        notice: lang.internetError,
    },
    type27: {
        icon: iconDelete,
        desc: [lang.deleteObjectPrompt1, '', lang.thumbnail],
        type: 'delete',
        notice: lang.deleteProjectRecoverPrompt,
    },
    type28: {
        icon: iconCould,
        desc: lang.errorWhileDeleting,
        type: 'deleteErr',
        notice: lang.internetError,
    },
    type29: {
        icon: iconUpload,
        type: 'file-upload2',
        desc: lang.publicProjectMaximumFail,
        notice: lang.upgradePublicProjectPrompt,
    },
    type30: {
        icon: iconUpdate,
        type: 'file-publish-update',
        desc: lang.saveAndUpdateTitle,
        notice: lang.saveAndPublishDec,
    },
    type31: {
        icon: iconUpload,
        type: 'file-upload',
        desc: lang.publicProjectMaximumFail,
        notice: '升級成基本版，以便完成建立並公開物件。',
    },
};

