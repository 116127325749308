import axioApi, { setHeader } from 'api/root.api';

export const postCreateTag = async (body, projectId, modelId) => {
    try {
        const urlEnd = `/editor/project/v1/${projectId}/${modelId}/tag`;
        setHeader('Content-Type', 'application/json');
        const response = await axioApi.post(urlEnd, body);
        const result = response.data;
        return result;
    } catch (error) {
        return {
            result: false,
            status: 'failed',
        };
    }
};


export const deleteDeleteTag = async (projectId, modelId, tagId) => {
    try {
        const urlEnd = `/editor/project/v1/${projectId}/${modelId}/${tagId}`;
        setHeader('Content-Type', 'application/json');
        const response = await axioApi.delete(urlEnd);
        const result = response.data;
        return result;
    } catch (error) {
        return {
            result: false,
            status: 'failed',
        };
    }
};

export const deleteAllTag = async (projectId, modelId) => {
    try {
        const urlEnd = `/editor/project/v1/${projectId}/${modelId}/tags/all`;
        setHeader('Content-Type', 'application/json');
        const response = await axioApi.delete(urlEnd);
        const result = response.data;
        return result;
    } catch (error) {
        return {
            result: false,
            status: 'failed',
        };
    }
};

export const postEditTag = async (body, projectId, modelId, tagId) => {
    try {
        const urlEnd = `/editor/project/v1/${projectId}/${modelId}/${tagId}`;
        setHeader('Content-Type', 'application/json');
        const response = await axioApi.post(urlEnd, body);
        const result = response.data;
        return result;
    } catch (error) {
        return {
            result: false,
            status: 'failed',
        };
    }
};

export const postUploadTagImage = async (formData, projectId, modelId, tagId) => {
    try {
        const urlEnd = `/editor/project/v1/${projectId}/${modelId}/${tagId}/icon`;
        setHeader('Content-Type', 'multipart/form-data');
        const response = await axioApi.post(urlEnd, formData);
        const result = response.data;
        return result;
    } catch (error) {
        return {
            result: false,
            status: 'failed',
        };
    }

};


export const postCreateBatchTags = async (body, projectId, modelId) => {
    try {
        const urlEnd = `/editor/project/v1/${projectId}/${modelId}/tags`;
        setHeader('Content-Type', 'application/json');
        const response = await axioApi.post(urlEnd, body);
        const result = response.data;
        return result;
    } catch (error) {
        return {
            result: false,
            status: 'failed',
        };
    }
};


export const postUpdateBatchTags = async (body, projectId, modelId) => {
    try {
        const urlEnd = `/editor/project/v1/${projectId}/${modelId}/tags/update`;
        setHeader('Content-Type', 'application/json');
        const response = await axioApi.post(urlEnd, body);
        const result = response.data;
        return result;
    } catch (error) {
        return {
            result: false,
            status: 'failed',
        };
    }
};

export const postUpdateBatchImages = async (formData, projectId, modelId, tagIds) => {
    try {
        let urlEnd = `/editor/project/v1/${projectId}/${modelId}/tags/icon?`;
        tagIds.forEach((id, index) => {
            if (index === tagIds.length - 1) {
                urlEnd = `${urlEnd}tagIds=${id}`;
                return;
            }
            urlEnd = `${urlEnd}tagIds=${id}&`;
        });
        setHeader('Content-Type', 'multipart/form-data');
        const response = await axioApi.post(urlEnd, formData);
        const result = response.data;
        return result;
    } catch (error) {
        return {
            result: false,
            status: 'failed',
        };
    }
};
