// react
import React, { useState, useEffect } from 'react';

// redux
import { useSelector, useDispatch } from 'react-redux';

//images
import Union from './images/Union.svg';
import angleMask from './images/angleMask.png';

// redux types
import {
    SET_OBJECT_EXPOSURE,
    SET_OBJECT_SHADOW_INTENSITY,
    SET_OBJECT_SHADOW_SOFTNESS,
} from 'redux/types/objectLight.types';
import { IS_SAVE } from 'redux/types/isSave.types';

//styled components
import { StyledObjectLight } from './StyledObjectLight';

//react icon
import { FiRefreshCcw } from 'react-icons/fi';

//hoo form
import { useForm } from 'react-hook-form';

//redux
import { SHADOW_CARD } from 'redux/types/shadowCard.types';

// //api
// import { modelLight } from 'api/modelLight/modelLight.api';

// // plugin
// import Cookies from 'js-cookie';

//i18n
import { useTranslation } from 'react-i18next';

const reducerConfig = {
    exposure: SET_OBJECT_EXPOSURE,
    shadowIntensity: SET_OBJECT_SHADOW_INTENSITY,
    shadowSoftness: SET_OBJECT_SHADOW_SOFTNESS,
};

const ObjectLight = () => {

    const store = useSelector(store => store);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { register } = useForm();

    const [shadowCards, setShadowCards] = useState({ exposure: store.shadowCard.shadowCard.exposure, shadowIntensity: store.shadowCard.shadowCard.shadowIntensity, shadowSoftness: store.shadowCard.shadowCard.shadowSoftness });

    const handleChangeLight = (e) => {
        setShadowCards({
            ...shadowCards,
            [e.target.name]: e.target.value,
        });
        dispatch({ type: IS_SAVE, payload: true });
        dispatch({ type: reducerConfig[e.target.name], payload: +e.target.value });
    };

    useEffect(() => {
        dispatch({
            type: SHADOW_CARD, payload: {
                exposure: parseFloat(shadowCards.exposure),
                shadowIntensity: parseFloat(shadowCards.shadowIntensity),
                shadowSoftness: parseFloat(shadowCards.shadowSoftness),
            },
        });
    }, [shadowCards]);

    const exposureRestEvent = () => {
        dispatch({
            type: SHADOW_CARD, payload: {
                exposure: 1,
                shadowIntensity: parseFloat(shadowCards.shadowIntensity),
                shadowSoftness: parseFloat(shadowCards.shadowSoftness),
            },
        });

        setShadowCards({
            exposure: 1,
            shadowIntensity: parseFloat(shadowCards.shadowIntensity),
            shadowSoftness: parseFloat(shadowCards.shadowSoftness),
        });
    };

    const intensityRestEvent = () => {
        dispatch({
            type: SHADOW_CARD, payload: {
                exposure: parseFloat(shadowCards.exposure),
                shadowIntensity: 0,
                shadowSoftness: parseFloat(shadowCards.shadowSoftness),
            },
        });

        setShadowCards({
            exposure: parseFloat(shadowCards.exposure),
            shadowIntensity: 0,
            shadowSoftness: parseFloat(shadowCards.shadowSoftness),
        });
    };

    const softnessRestEvent = () => {
        dispatch({
            type: SHADOW_CARD, payload: {
                exposure: parseFloat(shadowCards.exposure),
                shadowIntensity: parseFloat(shadowCards.shadowIntensity),
                shadowSoftness: 0,
            },
        });

        setShadowCards({
            exposure: parseFloat(shadowCards.exposure),
            shadowIntensity: parseFloat(shadowCards.shadowIntensity),
            shadowSoftness: 0,
        });
    };

    return (
        <StyledObjectLight>
            <div className="slideCon">
                <div className="title">
                    <span>
                        {t('exposure')}
                    </span>
                    <div className="icon">
                        <img src={Union} onClick={exposureRestEvent} draggable="false" />
                    </div>
                </div>
                <div className="slideRange">
                    <img src={angleMask} className="angleMask" draggable="false" />
                    <input
                        name="exposure"
                        min="0"
                        max="2"
                        step="0.01"
                        type="range"
                        value={shadowCards.exposure}
                        {...register('exposure')}
                        onChange={handleChangeLight}
                    />
                    <div className="rangeNum">
                        {shadowCards.exposure}
                    </div>
                </div>
            </div>
            <div className="slideCon">
                <div className="title">
                    <span>
                        {t('shadowIntensity')}
                    </span>
                    <div className="icon">
                        <img src={Union} onClick={intensityRestEvent} draggable="false" />
                    </div>
                </div>
                <div className="slideRange">
                    <img src={angleMask} className="angleMask" draggable="false" />
                    <input
                        name="shadowIntensity"
                        min="0"
                        max="10"
                        step="0.1"
                        type="range"
                        value={shadowCards.shadowIntensity}
                        {...register('shadowIntensity')}
                        onChange={handleChangeLight}
                    />
                    <div className="rangeNum">
                        {shadowCards.shadowIntensity}
                    </div>
                </div>
            </div>
            <div className="slideCon">
                <div className="title">
                    <span>
                        {t('shadowSoftness')}
                    </span>
                    <div className="icon">
                        <img src={Union} onClick={softnessRestEvent} draggable="false" />
                    </div>
                </div>
                <div className="slideRange">
                    <img src={angleMask} className="angleMask" draggable="false" />
                    <input
                        name="shadowSoftness"
                        min="0"
                        max="1"
                        step="0.01"
                        type="range"
                        {...register('shadowSoftness')}
                        value={shadowCards.shadowSoftness}
                        onChange={handleChangeLight}
                    />
                    <div className="rangeNum">
                        {shadowCards.shadowSoftness}
                    </div>
                </div>
            </div>
            {/* <button onClick={saveObjLight}>button</button> */}
        </StyledObjectLight>
    );
};

export default ObjectLight;