import axioApi, { setHeader } from 'api/root.api';

import Cookies from 'js-cookie';

export const getModelList = async (projectId) => {
    try {
        const urlEnd = `/editor/project/v1/${projectId}/modelList`;
        const token = Cookies.get('token');
        setHeader('Authorization', `Bearer ${token}`);
        const response = await axioApi.get(urlEnd);
        const result = response.data;
        return result;
    } catch (err) {
        return 'getModelList failed';
    }
};