// react
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// redux
import { useSelector, useDispatch } from 'react-redux';

// redux types
import {
    CLEAR_NEW_TAG_PANEL,
    CLEAR_OLD_TAG_PANEL,
    EMPTY_EDITING_TAGS,
    SIDEBAR_EDIT_TAG,
} from 'redux/types/tag.types';
import { SHADOW_CARD } from 'redux/types/shadowCard.types';
import { VIEW_MODE_NORMAL, VIEW_MODE_PREVIEW } from 'redux/types/viewMode.types';

// styled components
import { StyledNavTop } from './StyledNavTop';

// components
import ModalPopup from 'components/ModalPopup/ModalPopup';
import PublishPop from 'components/PublishPop/PublishPop';
import Loading from 'components/Loading/Loading';
import LoadingCard from 'components/LoadingCard/LoadingCard';
import SuccessProject from 'components/SuccessProject/SuccessProject';

// api
import {
    postCreateBatchTags,
    postUpdateBatchTags,
    postUpdateBatchImages,
} from 'api/objectTag/tag.api';
import { modelLight } from 'api/modelLight/modelLight.api';
import { postGlobalSwitch } from 'api/globalSwitch/globalSwitch.api';
import { postTagSwitch } from 'api/model/model.api';
import { openProject } from 'api/openProject/openProject.api';
import { takedownProject } from 'api/takedownProject/takedownProject.api';
import { getProjectOne } from 'api/getProjectOne/getProjectOne.api';
import { postAnimation } from 'api/postAnimation/postAnimation.api';
import { uplateModel } from 'api/uplateModel/uplateModel.api';
import { applyEnviroment } from 'api/applyEnviroment/applyEnviroment.api';

// react icons
import { BsEyeFill, BsCheckLg } from 'react-icons/bs';
import { IoMdCloudUpload } from 'react-icons/io';

// icons
import { ReactComponent as HomeIcon } from 'assets/icons/home.svg';

// config
import { modalConfig } from 'config/modal';

// utils
import { dataURItoBlob } from 'utils/file';
import { handleRerouteToARMaker } from 'utils/redirectUrl';

// plugin
import { useBeforeunload } from 'react-beforeunload';
import Cookies from 'js-cookie';
import clsx from 'classnames';

// i18n
import { useTranslation } from 'react-i18next';

// constants
import SERVER from 'constants/server';

const NavTop = ({ refetchModelList, netStatus, modelStatus }) => {

    const store = useSelector(store => store);
    const reduxDispatch = useDispatch();
    const location = useLocation();
    const [modalSetting, setModalSetting] = useState({
        show: false,
        type: 'type1',
        title: '',
        desc: '',
        notice: '',
        handleConfirm: () => { },
    });
    const [allSaveIsLoading, setAllSaveIsLoading] = useState(false);
    const [circleLoading, setCircleLoading] = useState(false);
    const [isSave, setSave] = useState(false);
    const [isPreview, setPreview] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [LoadingCardStatus, setLoadingCardStatus] = useState(false);
    const [LoadingCardTitle, setLoadingCardTitle] = useState(false);
    const [LoadingCardTxt, setLoadingCardTxt] = useState(false);
    const [modelViewerLoadingStatus, setModelViewerLoadingStatus] = useState(true);
    const [modelErr, setModelErr] = useState(false);

    const navigate = useNavigate();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const { t } = useTranslation();

    const handleRedirect = () => handleRerouteToARMaker('_self');

    const [version, setVer] = useState('...');

    useEffect(() => {
        // 舊版方案：free / basic / enterprise / armaker_enterprise_*企業名稱*
        // 新版方案：armaker_free_20220808 / armaker_basic_20220808 / <描述1>_Enterprise_<描述2>

        // console.log(store.userInfo.userInfo.ssoData.permission.permits.plan);

        if (store.userInfo?.userInfo?.ssoData?.permission?.permits?.plan?.indexOf('free') > -1) {
            setVer('free');

        } else if (store.userInfo?.userInfo?.ssoData?.permission?.permits?.plan?.indexOf('basic') > -1) {
            setVer('basic');

        } else if (store.userInfo?.userInfo?.ssoData?.permission?.permits?.plan?.indexOf('enterprise') > -1) {
            setVer('enterprise');

        } else if (store.userInfo?.userInfo?.ssoData?.permission?.permits?.plan?.indexOf('Enterprise') > -1) {
            setVer('enterprise');

        }
    }, [store.userInfo]);

    const disabledClickEvent = () => {
        if (store.editPanel.titleLengthError || store.editPanel.descLengthError) {
            return true;
        }
        return false;
    };

    const handleErrorResponse = (modalType) => {
        setHasError(true);
        setModalSetting({
            ...modalSetting,
            show: true,
            title: '',
            type: modalType,
            handleConfirm: () => { },
        });
    };

    useBeforeunload((event) => {
        if (store.isSave[0] === true) {
            let confirmationMessage = true;
            (event || window.event).returnValue = confirmationMessage; //Gecko + IE
            return confirmationMessage; //Webkit, Safari, Chrome
        }
    });

    const handleSaveAll = async (target) => {
        if (disabledClickEvent()) return;
        if (!store.model.isLoading && modelStatus) {
            if (target === 'preview') {
                setAllSaveIsLoading(true);
            } else {
                setCircleLoading(true);
            }

            const projectId = parseInt(Cookies.get('projectId'));
            const modelId = parseInt(Cookies.get('modelId'));
            // const projectId = store.projectInfo.projectInfo.content[0].id;
            // const modelId = store.projectInfo.projectInfo.content[0].editors[0].models[0].id;

            // save object info switch
            const getObjectInfoSwitch = store.switch.objectInfoSwitch;
            const responseObjectInfo = await postGlobalSwitch({ globalSwitch: getObjectInfoSwitch }, Cookies.get('projectId'), Cookies.get('token'));
            // console.log(`this is obj light response ${123}`, responseObjectInfo);

            // save tag switch
            const getObjectTagSwitch = store.switch.objectTagSwitch;
            const resPostTagSwitch = await postTagSwitch(getObjectTagSwitch, projectId, modelId);
            if (!resPostTagSwitch.result && resPostTagSwitch.status === 'failed') {
                handleErrorResponse('type14');
            }

            // save environment light
            const getEnvironmentLight = store.hdrSky.hdrSky;
            applyEnviroment({ environment: getEnvironmentLight }, Cookies.get('token'), projectId, modelId);

            // save ar switch
            const getObjectArSwitch = store.switch.objectArSwitch;
            uplateModel({ viewModeSwitch: getObjectArSwitch }, Cookies.get('token'), projectId, modelId);

            // url concat function
            const tagUrlConcat = (tagUrl) => {
                const httpStr = 'http://';
                const httpsStr = 'https://';
                if (!tagUrl) return tagUrl;

                if (tagUrl.includes(httpStr) || tagUrl.includes(httpsStr)) {
                    return tagUrl;
                }

                if (!tagUrl.includes(httpStr)) {
                    return `http://${tagUrl}`;
                }
            };

            // get all newTags & oldTags 
            const getAllNewTags = store.tagPanel.newTags;
            const getAllOldTags = store.tagPanel.oldTags;

            // save all newTags[]
            // console.log(`this is getAllNewTags ${123}`, getAllNewTags);
            let newTagsWithImage = [];
            const mapAllNewTags = getAllNewTags.map((tag, index) => {
                const formTitle = tag.title.length === 0 ? t('title') : tag.title;
                let body = {
                    content: tag.content,
                    contentTitle: '',
                    dataNormal: tag.dataNormal,
                    dataPosition: tag.dataPosition,
                    cameraOrbit: tag.cameraOrbit,
                    cameraTarget: tag.cameraTarget,
                    title: formTitle,
                    url: tagUrlConcat(tag.url),
                };

                if (tag.imageFile) {
                    newTagsWithImage.push({
                        title: tag.title,
                        imageFile: tag.imageFile,
                        index: index,
                    });
                }

                return body;
            });
            //console.log('mapAllNewTags', mapAllNewTags, projectId, modelId);
            const resCreateBatchTags = await postCreateBatchTags(mapAllNewTags, projectId, modelId);
            if (!resCreateBatchTags.result && resCreateBatchTags.status === 'failed') {
                handleErrorResponse('type14');
            }

            if (newTagsWithImage.length > 0) {
                let formDataForNewTags = new FormData();
                let newIdTagsWithNewImage = [];

                newTagsWithImage.forEach(tag => {
                    const getIndex = tag.index;
                    newIdTagsWithNewImage.push(resCreateBatchTags[getIndex].tag.id);
                    const blob = dataURItoBlob(tag.imageFile.image64);
                    const fileOfBlob = new File([blob], tag.imageFile.filename);
                    formDataForNewTags.append('files', fileOfBlob, fileOfBlob.name);
                });
                const resNewBatchImages = await postUpdateBatchImages(formDataForNewTags, projectId, modelId, newIdTagsWithNewImage);
                if (!resNewBatchImages.result && resNewBatchImages.status === 'failed') {
                    handleErrorResponse('type14');
                }
            }
            // empty new tags in redux
            reduxDispatch({ type: CLEAR_NEW_TAG_PANEL, payload: [] });

            // save all oldTags[]
            // update batch tags
            let idTagsWithNewImage = [];
            let formDataForUpdate = new FormData();

            const mapAllOldTags = getAllOldTags.map(tag => {
                const formTitle = tag.title.length === 0 ? t('title') : tag.title;
                let formIcon;

                // console.log(`this is tag.imageFile ${123}`, tag.imageFile);
                if (tag.imageFile) {
                    idTagsWithNewImage.push(tag.id);
                    const blob = dataURItoBlob(tag.imageFile);
                    const fileOfBlob = new File([blob], tag.filename);
                    formDataForUpdate.append('files', fileOfBlob, fileOfBlob.name);

                }

                formIcon = tag.icon;

                let body = {
                    tagId: tag.id,
                    content: tag.content,
                    contentTitle: '',
                    dataNormal: tag.dataNormal,
                    dataPosition: tag.dataPosition,
                    cameraOrbit: tag.cameraOrbit,
                    cameraTarget: tag.cameraTarget,
                    title: formTitle,
                    url: tagUrlConcat(tag.url),
                    icon: formIcon,
                };

                return body;
            });

            //console.log('mapAllOldTags', mapAllOldTags, projectId, modelId);
            const resBatchTags = await postUpdateBatchTags(mapAllOldTags, projectId, modelId);
            if (!resBatchTags.result && resBatchTags.status === 'failed') {
                handleErrorResponse('type14');
            }

            // console.log(`this is resBatchTags ${123}`, resBatchTags);
            if (idTagsWithNewImage.length > 0) {
                const resBatchImages = await postUpdateBatchImages(formDataForUpdate, projectId, modelId, idTagsWithNewImage);
                if (!resBatchImages.result && resBatchImages.status === 'failed') {
                    handleErrorResponse('type14');
                }
            }
            // clear old tags & empty editing tags in redux
            reduxDispatch({ type: CLEAR_OLD_TAG_PANEL, payload: [] });
            reduxDispatch({ type: EMPTY_EDITING_TAGS, payload: [] });

            // save object lights
            const getObjectLights = store.shadowCard.shadowCard;
            const response = await modelLight(getObjectLights, Cookies.get('token'), projectId, modelId);
            // reduxDispatch({ type: SHADOW_CARD, payload:{
            //     exposure: parseFloat(response.exposure),
            //     shadowIntensity:parseFloat(response.shadowIntensity),
            //     shadowSoftness:parseFloat(response.shadowSoftness),
            // } });

            // save animation
            let sendAnimationJson = store.animation.animateList.slice();
            let sendBody = [];
            sendAnimationJson.forEach((item) => {
                sendBody.push({ ownSwitch: item.ownSwitch, disable: item.disable });
            });

            const animationBody = {
                animationBarColor: 'string',
                animationBarColorSwitch: true,
                animationItemSwitch: JSON.stringify({ ...sendBody }),
                annotationSwitch: store.animation.mainAnimateSwitch,
            };
            const resAnimation = await postAnimation(projectId, modelId, animationBody);
            if (!resAnimation.result && resAnimation.status === 'failed') {
                handleErrorResponse('type14');
            }

            // refetch model list after all save
            await refetchModelList(projectId);
            reduxDispatch({
                type: SIDEBAR_EDIT_TAG,
                payload: { serialNumber: null, tag: {} },
            });
            setAllSaveIsLoading(false);
            setSave(true);
            setTimeout(() => {
                setSave(false);
                setHasError(false);
            }, 3000);
            if (target === 'preview') {
                navigate('/preview');
                setPreview(true);
            } else {
                setCircleLoading(false);
            }
        }

    };


    const goBackPage = () => {
        if (!store.model.isLoading && modelStatus) {
            setPreview(false);
            reduxDispatch({ type: VIEW_MODE_NORMAL, payload: 'normal' });
            navigate('/?lang=' + Cookies.get('lang'));
            window.location.reload();
        }
    };

    const goPreview = () => {
        if (disabledClickEvent()) return;
        if (!store.model.isLoading && modelStatus) {
            setModalSetting({
                ...modalSetting,
                show: true,
                type: 'type13',
                handleConfirm: () => {
                    handleSaveAll('preview');
                    reduxDispatch({ type: VIEW_MODE_PREVIEW, payload: 'preview' });
                },
            });
        }
    };

    useEffect(() => {
        if (location.pathname.indexOf('/preview') === -1) {
            setPreview(false);
        }
    }, [location]);

    const [publish, setPublish] = useState(false);
    const [isSuccess, setSuccess] = useState(false);
    let projectId = Cookies.get('projectId');
    let token = Cookies.get('token');

    const childPublishEvent = (value) => {
        setSuccess(value);
    };

    const [updateStatus, setUpdateStatus] = useState(false);

    const publishEvent = () => {
        if (disabledClickEvent()) return;

        if (!store.model.isLoading && modelStatus) {
            // 可公開物件達上限，跳出 popup
            if (store.userProjectNum?.userProjectNum?.projectPublicCount >= store.permission?.permission?.permission?.permits.publicProject && Cookies.get('isPublish') === 'false') {
                setModalSetting({
                    ...modalSetting,
                    show: true,
                    type: version === 'free' ? 'type31' : 'type29',
                    desc: t('publicProjectMaximumFail', { projectCount: store.userProjectNum?.userProjectNum?.projectPublicCount, projectLimit: store.permission?.permission?.permission?.permits.publicProject }),
                    handleConfirm: () => {
                        if (version === 'free') {
                            handleRerouteToARMaker('_self');
                        } else {
                            window.open(`${SERVER.ISTAGING}/home/contact_us/`, '_blank');
                        }
                    },
                });
                return;
            }
            let type;
            if (Cookies.get('isPublish') === 'true') {
                setLoadingCardTitle(t('UpdatingToPublicPage'));
                type = 'type30';
            } else {
                setLoadingCardTitle(t('publishingProject'));
                type = 'type24';
            }
            if (!isPreview) {
                setModalSetting({
                    ...modalSetting,
                    show: true,
                    type: type,
                    handleConfirm: async () => {
                        await handleSaveAll().catch(() => {
                            handleErrorResponse('type14');
                        });
                        setLoadingCardTxt(t('pleaseWait'));
                        setLoadingCardStatus(true);
                        if (!hasError) {
                            // console.log('打公開API');
                            if (Cookies.get('isPublish') === 'false') {
                                openProject(projectId, token)
                                    .then(res => {
                                        Cookies.set('isPublish', true);
                                        setLoadingCardStatus(false);
                                        setSuccess(true);
                                    })
                                    .catch(() => {
                                        setLoadingCardStatus(false);
                                        setModalSetting({
                                            ...modalSetting,
                                            show: true,
                                            type: 'type25',
                                            handleConfirm: () => {
                                            },
                                        });
                                    });
                            } else {
                                takedownProject(projectId, token).then(res => {
                                    openProject(projectId, token).then(() => {
                                        setLoadingCardStatus(false);
                                        setUpdateStatus(true);
                                        setSuccess(true);
                                    }).catch(() => {
                                        setLoadingCardStatus(false);
                                        setModalSetting({
                                            ...modalSetting,
                                            show: true,
                                            type: 'type25',
                                            handleConfirm: () => {
                                            },
                                        });
                                    });
                                });
                            }
                        }
                    },
                });
            } else {
                handleSaveAll().catch(() => {
                    handleErrorResponse('type14');
                });
                setLoadingCardTxt(t('pleaseWait'));
                setLoadingCardStatus(true);
                if (!hasError) {
                    // console.log('打公開API');
                    if (Cookies.get('isPublish') === 'false') {
                        openProject(projectId, token)
                            .then(res => {
                                Cookies.set('isPublish', true);
                                setLoadingCardStatus(false);
                                setSuccess(true);
                            })
                            .catch(() => {
                                setLoadingCardStatus(false);
                                setModalSetting({
                                    ...modalSetting,
                                    show: true,
                                    type: 'type25',
                                    handleConfirm: () => {
                                    },
                                });
                            });
                    } else {
                        takedownProject(projectId, token).then(res => {
                            openProject(projectId, token).then(() => {
                                setLoadingCardStatus(false);
                                setUpdateStatus(true);
                                setSuccess(true);
                            }).catch(() => {
                                setLoadingCardStatus(false);
                                setModalSetting({
                                    ...modalSetting,
                                    show: true,
                                    type: 'type25',
                                    handleConfirm: () => {
                                    },
                                });
                            });
                        });
                    }
                }
            }
        }
    };

    const [projectName, setProjectName] = useState('');

    useEffect(() => {
        getProjectOne(Cookies.get('projectId'), Cookies.get('token')).then(res => {
            if (res !== 'getProjectOne failed') {
                setProjectName(res.project.name);
            }
        });

        if (decodeURIComponent(urlParams.get('preview')) === 'true' || window.location.pathname.indexOf('preview') > -1) {
            setPreview(true);
        }
    }, []);

    useEffect(() => {
        setModelViewerLoadingStatus(store.model?.isLoading);
    });

    return (
        <StyledNavTop className="flexBetweenCenter" isPreview={isPreview}>
            {isSuccess && <SuccessProject onCallParent={childPublishEvent} updateStatus={updateStatus} />}
            {circleLoading && <div className='stopMask'></div>}
            {allSaveIsLoading && <Loading />}
            {LoadingCardStatus && <LoadingCard mask='true' title={LoadingCardTitle} txt={LoadingCardTxt} />}
            <div className="leftPanel flexCenter">
                <div className="logo" onClick={handleRedirect}>
                    <HomeIcon />
                </div>
                <div className="package flexCenter">
                    {t(`${version}`)}
                </div>
            </div>
            {netStatus && <div className="centerPanel">{projectName}</div>}
            {netStatus && (
                <div className="rightPanel flexCenter">
                    {isPreview && (
                        <div className={clsx('previewBtn', 'flexCenter', { 'loadingDefault': store.model.isLoading })} onClick={goBackPage} >
                            <span>{t('editObject')}</span>
                        </div>
                    )}
                    {
                        netStatus && !isPreview &&
                        <div
                            className={clsx('saveBtn', {
                                'disabledSaveBtn': store.editPanel.titleLengthError || store.editPanel.descLengthError,
                                'loadingDefault': store.model.isLoading,
                            })}
                            onClick={handleSaveAll}
                        >
                            {circleLoading && <div className="lds-dual-ring"></div>}
                            {!hasError && isSave && <BsCheckLg />}
                            {t('save')}
                        </div>
                    }

                    {
                        netStatus && !isPreview &&
                        <div
                            onClick={goPreview}
                            className={clsx('previewBtn', 'flexCenter', {
                                'disabledSaveBtn': store.editPanel.titleLengthError || store.editPanel.descLengthError,
                                'loadingDefault': store.model.isLoading,
                            })}
                        >
                            <BsEyeFill />
                            <span>{t('preview')}</span>
                        </div>
                    }
                    <div
                        className={clsx('publishBtn', {
                            'disabledSaveBtn': store.editPanel.titleLengthError || store.editPanel.descLengthError,
                            'loadingDefault': store.model.isLoading,
                        })}
                    >
                        <div onClick={publishEvent} className={clsx({ 'loadingDefault': store.model.isLoading })}>
                            <IoMdCloudUpload />
                            <span>
                                {Cookies.get('isPublish') === 'true' ? t('updateToPublic') : t('public')}
                            </span>
                        </div>
                    </div>
                </div>
            )}

            {modalSetting.show && (
                <ModalPopup
                    modalConfig={modalConfig[modalSetting.type]}
                    setModalSetting={setModalSetting}
                    modalSetting={modalSetting}
                />
            )}
        </StyledNavTop>
    );
};
export default NavTop;