import React, { useEffect } from 'react';

// styled components
import { StyledSwitch } from './StyledSwitch';

const Switch = (props)=>{

    

    const [shape, setShape] = React.useState(props.turn ? 'shape shapeMove' : 'shape');

    const [color, setColor] = React.useState(props.turn ? 'color colorMove' : 'color');

    

    useEffect(() => {
        setShape(props.turn ? 'shape shapeMove' : 'shape');
        setColor(props.turn ? 'color colorMove' : 'color');
    }, [props.turn]);

    return (
        <StyledSwitch>
            <div className={color}>
                <div className={shape}></div>
                <label htmlFor="checkbox">
                    <input id="checkbox" type="checkbox" />
                </label>
            </div>
        </StyledSwitch>
    );
};

export default Switch;