import styled from 'styled-components';

export const StyledSuccessProject = styled.div`
    position: fixed;
    left:0;
    top:0;
    z-index: 9999999;
    background-color: rgba(0,0,0,0.8);
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .copySuccess{
        width: 230px;
        background: #54C68F;
        border-radius: 4px;
        padding: 8px 24px;
        color: #FFFFFF;
        line-height: 140%;
        position: absolute;
        top: calc((100vh - 588px) / 2);
        left: calc((100vw - 230px) / 2);
        svg{
            margin-right: 4px;
        }
    }
    .whiteBg{
        width: 560px;
        position: relative;
        background-color: #fff;
        border-radius: 8px;
        padding: 48px 82px;
        .closeIcon{
            position: absolute;
            right: 12px;
            top: 12px;
            font-size: 25px;
            color: rgba(0,0,0,0.8);
        }
        .title{
            text-align: left;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 40px;
            color: #333333;
            font-weight: bold;
            .icon{
                margin-right: 8px;
                display: inline-block;
                font-size: 30px;
                color: #54C68F;
                position: relative;
                top: 2px;
            }
        }
        .copyBar{
            text-align:center;
            margin-bottom: 40px;
            input{
                width: 356px;
                height: 34px;
                border-radius: 3px;
                border:1px solid #202020;
                padding-right: 10px;
                padding-left: 10px;
                text-align:left;
                &:focus{
                    outline-color: #ED0973;
                }
            }
            .copy{
                background-color: #ED0973;
                color: #fff;
                display: inline-block;
                width: 34px;
                height: 34px;
                font-size:20px;
                line-height: 32px;
                text-align:center;
                border-radius: 3px;
                margin-left: 5px;
                &:hover{
                    opacity: 0.7;
                }
            }
        }
        .listUl{
            width: 400px;
            margin:0 auto;
            padding:0;
            li{
                display: flex;
                justify-content: space-between;
                align-items: center;
                list-style: none;
                padding-top: 7px;
                >div{
                    font-size: 14px;
                    font-weight: bold;
                    &:first-child{
                        color: #333;
                        .icon{
                            display: inline-block;
                            margin-right: 7px;
                            font-size: 20px;
                        }
                    }
                    &:last-child{
                        color: #54C68F;
                    }
                    .icon{
                        img{
                            width: 18px;
                        }
                    }
                }
                >a{
                    display: block;
                    border-radius: 4px;
                    font-size: 14px;
                    line-height: 24px;
                    text-align:center;
                    text-decoration:none;
                    margin-top: 25px;
                    padding: 8px 24px;
                    &:first-child{
                        background-color: rgba(0,0,0,0.05);
                        color: #666666;
                        &:hover{
                            background-color: #E0E0E0;
                        }
                    }
                    &:last-child{
                        background-color: #ED0973;
                        color: #fff;
                        &:hover{
                            background-color: #F2539D;
                        }
                    }
                }
            }
            .lastLi{
                margin-top: 40px;
                >div{
                    &:first-child{
                        font-size: 12px;
                        color: rgba(0,0,0,0.6);
                        position: relative;
                        top:5px;
                    }
                    &:last-child{
                        font-size: 14px;
                        color: rgba(0,0,0,0.6);
                        .underIcon{
                            display: inline-block;
                            font-size: 25px;
                            margin-left: 10px;
                            position: relative;
                            top:2px;
                        }
                    }
                }
            }
        }
    }
`;