import React from 'react';

// styled components
import { StyledLoadingMask } from './StyledLoadingMask';

const LoadingMask = ()=>{
    return (
        <StyledLoadingMask></StyledLoadingMask>
    );
};

export default LoadingMask;