import styled from 'styled-components';

export const StyledModelViewer = styled.div`
    width: 100%;
    height: calc(100vh - 106px);

    .loadingProgressContainer {
        position: absolute;
        width: calc(100% - 343px);
        height: calc(100% - 106px);
        background: rgba(255, 255, 255, 1);
        z-index: ${props => props.theme.zIndex.LayerEight};
        backdrop-filter: blur(40px);

        .progressBarPanel {
            margin-bottom: 24px;
            width: 300px;
            height: 8px;
        }

        .progress {
            height: 8px;
            background: #E0E0E0 !important;
        }

        .progress-bar {
            background: linear-gradient(79.78deg, #ED0973 0%, #FF744E 100%) !important;
        }

        .progressText {
            color: #333333;
            font-size: 20px;
            font-weight: 500;
            line-height: 28px;
            letter-spacing: 0.02em;
            text-align: left;
            margin-bottom:20px;
        }
    }

    #myEditor {
        width: calc(100vw - 343px);
        height: 100%;
        background-color: #EEEEEE;

        .section1 {
            right: 30px;
            bottom: 0px;
        }

        .section2 {
            left: 30px;
            bottom: 0px;
        }

        .section3 {
            right: 30px;
            top: 0px;
        }

        .section4 {
            left: 30px;
            top: 0px;
        }

        .xSection1 {
            right: 30px;
            bottom: 0px;
        }

        .xSection2 {
            left: 30px;
            bottom: 0px;
        }

        .show {
            display: block;
        }

        .hotspotTop {
            position: relative;
        }

        .hotspot {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            border: 2px solid #fff;
            background-color: rgba(0, 0, 0, 0.7);
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: ${props => props.theme.zIndex.LayerNine};
        }

        .oldHover {
            &:hover {
                cursor: pointer;
                box-shadow: 0px 0px 0px 6px rgba(230,3,99,0.4);
            }
        }

        .newHover {
            &:hover {
                cursor: pointer;
                box-shadow: 0px 0px 0px 6px rgba(0, 0, 0, 0.4);
            }
        }
        .oldHover.noHover{
            box-shadow: 0px 0px 0px 6px rgba(230,3,99,0.4);
        }
        .newHover.noHover{
            box-shadow: 0px 0px 0px 6px rgba(0, 0, 0, 0.4);
        }
        .annotation {
            width: auto;
            background-color: white;
            position: absolute;
            transform: translate(15px, 5px);
            border-radius: 10px;
            padding: 10px !important;
            background-color: #888888;
            text-align: left;
            white-space: nowrap;
            color: #fff;
        }

        .selected {
            background: #E60363D9;
        }
        
        .hotspot.selected {
            background: #E60363D9;
        }

    }

    .unsaveTooltip {
        display: none;
        position: absolute;
        top: 0;
        right: 30px;
        width: auto;
        white-space: nowrap;
        padding: 2px 4px;
        background: #121212;
        color: #fff;
        border-radius: 4px;
    }

    .showToolTip {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
    }

    .showEditingToolTip {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
    }

    .arrowRight {
        position: absolute;
        right: -7px;
        width: 0; 
        height: 0; 
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 10px solid #121212;
    }

    .editingText {
        margin-right: auto;
        font-size: 15px;
        color: #FFC55E;

        svg {
            margin-right: 5px;
            font-size: 20px;
        }
    }
`;