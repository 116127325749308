import axioApi, { setHeader } from 'api/root.api';

export const uplateModel = async (body, token, projectId, modelId)=>{
    const urlEnd = `/editor/project/v1/${projectId}/${modelId}/updateModel`;
    // setHeader('Access-Control-Allow-Origin', '*');
    setHeader('Authorization', `Bearer ${token}`);
    setHeader('Content-Type', 'application/json');
    const response = await axioApi.post(urlEnd, body);
    const result = response.data;
    return result;
};