import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

//redux
import { HDR_SKY } from 'redux/types/hdrSky.types';
import { GET_MODEL_LIST } from 'redux/types/model.types';

//styled components
import { StyledEnvLight } from './StyledEnvLight';
import ModalPopup from 'components/ModalPopup/ModalPopup';
import LoadingMask from 'components/LoadingMask/LoadingMask';

//images
import HDRI from './images/hdri.svg';
import check from './images/check.svg';

// hooks
import useModal from 'hooks/useModal';

// config
import { modalConfig } from 'config/modal';


//plugin
import clsx from 'classnames';
import Cookies from 'js-cookie';

//api
import { applyEnviroment } from 'api/applyEnviroment/applyEnviroment.api';
import { getModelList } from 'api/getModelList/getModelList';

//i18n
import { useTranslation } from 'react-i18next';

import * as dayjs from 'dayjs';

function sortFn(modelList) {
    let sortAry = modelList.sort((a, b) => {
        return dayjs(b.lastUpdated).unix() - dayjs(a.lastUpdated).unix();
    });

    for (let i = 0; i < sortAry.length; i++) {
        if (sortAry[i].mainObject === true) {
            let mainTemp = sortAry[i];
            sortAry[i] = sortAry[0];
            sortAry[0] = mainTemp;
        }
    }

    return sortAry;
}

const EnvLight = () => {

    const projectId = parseInt(Cookies.get('projectId'));
    const store = useSelector(store => store);
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const tabs = [
        {
            id: 1,
            text: t('hdrLibrary'),
        },
    ];

    const defaultHDR = ['neutral', 'default', 'spruit sunrise', 'aircraft workshop', 'music hall', 'pillars', 'whipple creek'];

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const modelId = Cookies.get('modelId');
    const token = Cookies.get('token');

    const { modalSetting, setModalSetting } = useModal();
    const [activeName, setActive] = useState('neutral');


    const handleErrorResponse = (modalType) => {
        setModalSetting({
            ...modalSetting,
            show: true,
            title: '',
            type: modalType,
            handleConfirm: () => { },
        });
    };

    useEffect(() => {
        let hdrSky = store.hdrSky.hdrSky;
        setActive(hdrSky === null ? 'neutral' : hdrSky);
        // let hdrSky = store.model?.modelList.filter((item) => {
        //     return item.id === parseInt(Cookies.get('modelId'));
        // });
        // setActive(hdrSky[0]?.environment === null ? 'neutral' : hdrSky[0]?.environment);
    }, [store.hdrSky.hdrSky]);





    const activeEvent = (item) => () => {
        // applyEnviroment({ environment: item }, token, projectId, modelId).then(res => {
        //     // console.log(res);
        //     dispatch({ type: HDR_SKY, payload: item });
        //     getModelList(projectId).then((modelList) => {
        //         dispatch({ type: GET_MODEL_LIST, payload: sortFn(modelList) });
        //     });
        // }).catch((err) => {
        //     // handleErrorResponse('type17');
        // });
        setActive(item);
        dispatch({ type: HDR_SKY, payload: item });
    };


    return (
        <StyledEnvLight>
            {modalSetting.show && (
                <ModalPopup
                    modalConfig={modalConfig[modalSetting.type]}
                    setModalSetting={setModalSetting}
                    modalSetting={modalSetting}
                />
            )}
            <div className="tabPanel">
                {
                    tabs.map(tab => {
                        return (
                            <div key={tab.id} className={`tab ${tab.id === 1 && 'active'}`}>
                                {tab.text}
                            </div>
                        );
                    })
                }
            </div>
            <div className="grid">
                {
                    defaultHDR.map(item => {
                        return (
                            <div className={clsx('box', {
                                'active': activeName === item,
                            })} onClick={activeEvent(item)} key={item}>
                                <div className="img">
                                    <img src={check} className="check" draggable="false" />
                                    <img src={HDRI} className="hdri" draggable="false" />
                                </div>
                                <div className="name">{item}</div>
                            </div>
                        );
                    })
                }
            </div>
        </StyledEnvLight>
    );
};

export default EnvLight;