import styled from 'styled-components';

export const StyledQRcode = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999999999;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    text-align: center;

    .closeIcon{
        position: absolute;
        top:20px;
        right:20px;
        cursor: pointer;
        svg{
            color: #fff;
            font-size:30px;
        }
    }

    .qrcode_container {
        width: 600px;
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        align-items: center;
        justify-content: center;
        align-content: center;

        h1 {
            width: 100%;
            font-weight: 500;
            font-size: 20px;
            line-height: 29px;
            letter-spacing: 0.01em;
            color: #FFFFFF;
            margin-bottom: 16px;
        }
        span {
            width:100%;
            font-weight: 500;
            font-size: 16px;
            line-height: 23px;
            letter-spacing: 0.01em;
            color: rgba(255, 255, 255, 0.6);
        }
        .qrcode {
            border: 20px solid #fff;
            margin: 48px auto;
        }
    }
`;