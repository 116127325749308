export const hideAllEditPanel = () => {
    document.querySelectorAll('.editPanel').forEach(e => e.style.display = 'none');
};

export const clickOnViewer = (e) => {
    if (e.target.id === 'myEditor') {
        // if (document.querySelectorAll('.editPanel').length > 0) {
        //     document.querySelectorAll('.editPanel').forEach(node => {
        //         node.style.display = 'none';
        //     });
        // }
    }
};

export const clickOnHotspot = (hotspot) => {
    if (hotspot.children[0]) {
        let editPanelId = hotspot.children[0].children[0].id;
        document.querySelectorAll('.editPanel').forEach(node => {
            node.style.display = 'none';
        });
        document.querySelector(`#${editPanelId}`).style.display = 'block';
    }
};