import { HDR_SKY_SWITCH } from 'redux/types/hdrSkySwitch.types';

let HDR_SKY_SWITCH_RES = {
    hdrSkySwitch: false,
};

const hdrSkySwitchReducer = (state = HDR_SKY_SWITCH_RES, action) => {
    switch (action.type) {
        case HDR_SKY_SWITCH:
            return {
                ...state, hdrSkySwitch: action.payload,
            };
        default: return state;
    }
};

export default hdrSkySwitchReducer;