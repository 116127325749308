import { ANIMATE_LIST, MAIN_ANIMATE_SWITCH, CURRENT_ANIMATE, ANIMATE_PLAY_STATUS } from 'redux/types/animation.types';



let INIT_ANIMATE = {
    animateList: [],
    mainAnimateSwitch: true,
    currentAnimate: null,
    animatePlayStatus:true,
};


const animationReducer = (state = INIT_ANIMATE, action) => {
    switch (action.type) {
        case ANIMATE_LIST:
            return {
                ...state, animateList: [...action.payload],
            };

        case MAIN_ANIMATE_SWITCH:
            return {
                ...state,  mainAnimateSwitch: action.payload,
            };

        case CURRENT_ANIMATE:
            return {
                ...state, currentAnimate: action.payload,
            };

        case ANIMATE_PLAY_STATUS:
            return {
                ...state, animatePlayStatus: action.payload,
            };

        default:
            return state;
    }


};

export default animationReducer;