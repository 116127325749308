// react
import React, { useState, useEffect } from 'react';

// redux
import { useSelector, useDispatch } from 'react-redux';

// redux types
import {
    OLD_TAG_PANEL,
    CLEAR_OLD_TAG_PANEL,
} from 'redux/types/tag.types';
import { GET_TAG_LIST } from 'redux/types/model.types';

// styled components
import { StyledSidebarContainer } from './StyledSidebarContainer';

// components
import EditContainer from 'components/EditContainer/EditContainer';
// import SuccessProject from 'components/SuccessProject/SuccessProject';

// pages
import NavigationBar from 'components/NavigationBar/NavigationBar';

// plugin
import Cookies from 'js-cookie';


const SidebarContainer = (props) => {
    // const projectId = 16;
    // const modelId = 5;
    const projectId = parseInt(Cookies.get('projectId'));
    const modelId = parseInt(Cookies.get('modelId'));
    const store = useSelector(store => store);
    const reduxDispatch = useDispatch();
    const { modelList, refetchModelList, netStatus, modelListIsLoading, modelStatus } = props;
    const [] = useState(props.preview);
    const [hotspots, setHotspots] = useState([]);


    useEffect(() => {
        if (modelList?.length > 0) {
            let checkModelId = false;
            for (let item of modelList) {
                if (item.id === parseFloat(Cookies.get('modelId'))) {
                    checkModelId = true;
                }
            }
            if (!checkModelId) {
                return;
            }
            reduxDispatch({ type: CLEAR_OLD_TAG_PANEL, payload: [] });
            reduxDispatch({ type: OLD_TAG_PANEL, payload: modelList.find(model => model.id === parseFloat(Cookies.get('modelId')))?.tags });
            reduxDispatch({ type: GET_TAG_LIST, payload: modelList.find(model => model.id === parseFloat(Cookies.get('modelId')))?.tags });
        }
    }, [modelList]);

    useEffect(() => {
        setHotspots(store.model.tagList);
    }, [store.model.tagList]);


    return (
        <StyledSidebarContainer className="flexStartCenter sidebarSection">
            {store.model.isLoading && <div className="masker" />}
            {/*成功發佈在這 <SuccessProject></SuccessProject> */}
            <NavigationBar netStatus={netStatus} modelListIsLoading={modelListIsLoading} modelStatus={modelStatus} />
            <EditContainer
                projectId={projectId}
                modelId={modelId}
                tags={hotspots}
                refetchModelList={refetchModelList}
                netStatus={netStatus}
                modelListIsLoading={modelListIsLoading}
                modelStatus={modelStatus}
            />
            {props.children}
        </StyledSidebarContainer>
    );
};

export default SidebarContainer;