import styled from 'styled-components';

export const StyledSidebarContainer = styled.div`
    position: relative;
    width: 343px;
    height: calc(100vh - 106px);
    z-index:10000;
    .masker {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: ${props => props.theme.zIndex.LayerTen};
        background-color: rgba(0,0,0,0);
    }

    .rightRemind{
        position: absolute;
        right: 30px;
        bottom: 30px;
        z-index: 10;
        li{
            width: 40px;
            height: 40px;
            border-radius: 3px;
            background-color: rgba(0,0,0,0.8);
            margin-bottom: 20px;
            cursor: pointer;
            list-style: none;
            line-height: 40px;
            text-align:center;
            position: relative;
            opacity: 0.2;
            &:hover{
                opacity: 1;
            }
            &:last-child{
                margin-bottom: 0;
            }
            img{
                width: 35px;
            }
            .pop{
                position: absolute;
                width: 260px;
                height: 252px;
                background-color: rgba(0,0,0,0.87);
                padding:12px 24px;
                border-radius: 8px;
                bottom: 0;
                left: -270px;
                .top{
                    color: rgba(255,255,255,0.87);
                    font-size: 14px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    img{
                        width: 16px;
                        cursor: pointer;
                    }
                }
                table{
                    margin:0 auto;
                    width: 90%;
                    tr{
                        td{
                            font-size:14px;
                            color: #fff;
                            width: 50%;
                            text-align:left;
                            padding: 10px 0;
                            border-bottom: 1px solid rgba(255,255,255,0.12);
                            &:nth-child(2){
                                width: 25%;
                                text-align:right;
                            }
                            &:nth-child(3){
                                width: 25%;
                                text-align:right;
                            }
                        }
                        &:last-child{
                            td{
                                border:none;
                            }
                        }
                    }
                }
            }
        }
        .opa{
            opacity: 1;
        }
    }
    .smallBarPic{
        z-index: 10;
        width: 98px;
        height: 480px;
        position: absolute;
        left:0;
        top:100px;
        background-color: rgba(0,0,0,0.87);
        border-radius: 0 20px 20px 0;
        ul{
            margin-left: 30px;
            margin-top: 20px;
            margin-right: 0;
            margin-bottom: 0;
            padding: 0;
            li{
                width: 48px;
                height: 48px;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center center;
                    border-radius: 4px;
                }
            }
        }
    }
`;