// react
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// redux
import { useSelector, useDispatch } from 'react-redux';

//hdr file
import HDR_FILE from 'constants/hrd';

// redux types
import {
    NEW_TAG_PANEL,
    OLD_TAG_PANEL,
    CLEAR_OLD_TAG_PANEL,
    REMOVE_NEW_TAG,
    EDITING_TAGS,
    CLEAR_NEW_TAG_PANEL,
    CHANGE_TAG,
} from 'redux/types/tag.types';
import { GET_TAG_LIST, MODEL_IS_LOADING } from 'redux/types/model.types';
import { IS_SAVE } from 'redux/types/isSave.types';
import { ANIMATE_LIST, MAIN_ANIMATE_SWITCH, CURRENT_ANIMATE, ANIMATE_PLAY_STATUS } from 'redux/types/animation.types';


// styled components
import { StyledModelViewer } from './StyledModelViewer';

// react bootstrap
import ProgressBar from 'react-bootstrap/ProgressBar';

// components
import EditPanel from 'components/EditPanel/EditPanel';
import AnimateController from 'components/AnimateController/AnimateController';
import ModalPopup from 'components/ModalPopup/ModalPopup';
import LoadingIcon from 'common/LoadingIcon/LoadingIcon';


// api
import {
    deleteDeleteTag,
} from 'api/objectTag/tag.api';
import { postTagSwitch } from 'api/model/model.api';

// hooks
import useModal from 'hooks/useModal';

// utils
import {
    hideAllEditPanel,
    // clickOnViewer,
} from 'utils/ObjectTag';

import { handleCameraMovement } from 'utils/customFunctions';

// config
import { modalConfig } from 'config/modal';

// plugins
import clsx from 'classnames';
import '@google/model-viewer/dist/model-viewer';
import Cookies from 'js-cookie';

//i18n
import { useTranslation } from 'react-i18next';

//images
import { boolean } from 'yup/lib/locale';


const ModelViewer = (props) => {
    const projectId = parseInt(Cookies.get('projectId'));
    const modelId = parseInt(Cookies.get('modelId'));
    const store = useSelector(store => store);
    const { modelList, refetchModelList, isAnimation, isObjectTag } = props;
    const reduxDispatch = useDispatch();
    const location = useLocation();
    const [initModel, setInitModel] = useState([]);
    const [hotspots, setHotspots] = useState([]);
    const [currentHotspot, setCurrentHotspot] = useState(null);
    const [currentHotspotId, setCurrentHotspotId] = useState('');
    const [sectionType, setSectionType] = useState('');
    const [currentHoverNewTag, setCurrentHoverNewTag] = useState('');
    const [currentHoverOldTag, setCurrentHoverOldTag] = useState('');
    const [noHover, setNoHover] = useState('');
    const { modalSetting, setModalSetting } = useModal();

    const { t, i18n } = useTranslation();

    // object lights
    const [exposure, setExposure] = useState(store.shadowCard?.shadowCard?.exposure);
    const [shadowIntensity, setShadowIntensity] = useState(store.shadowCard?.shadowCard?.shadow_intensity);
    const [shadowSoftness, setShadowSoftness] = useState(store.shadowCard?.shadowCard?.shadow_softness);

    // env light
    const [hdrSky, setHdrSky] = useState('');

    // model loading progress bar
    const [modelLoadingProgress, setModelLoadingProgress] = useState(0);

    // remaining tags after deleting new tag
    const [remainingTags, setRemainingTags] = useState([]);

    const [tagDragged, setTagDragged] = useState(false);
    const [modelDragged, setModelDragged] = useState(false);

    const [draggingTag, setDraggingTag] = useState(null);
    const [draggingHotspotId, setDraggingHotspotId] = useState('');

    useEffect(() => {
        handleCameraMovement();
        const getModelViewer = document.querySelector('#myEditor');
        getModelViewer.addEventListener('progress', (e) => {
            if (window.location.href.indexOf('/envLight') !== -1) {
                return;
            }
            const getProgress = e.detail.totalProgress;
            reduxDispatch({ type: MODEL_IS_LOADING, payload: true });
            // if (getProgress === 1) {
            //     reduxDispatch({ type: MODEL_IS_LOADING, payload: getProgress === 1 ? false : true });
            // }
            setModelLoadingProgress(+getProgress.toFixed(2));
            if (e.detail.type === 'loadfailure') {
                reduxDispatch({ type: MODEL_IS_LOADING, payload: true });
                setModalSetting({
                    ...modalSetting,
                    show: true,
                    title: '',
                    type: 'type12',
                    coverSetting: true,
                    handleOtherAction: true,
                    otherBtnText: t('PleaseTryAgain'),
                    handleConfirm: () => {
                        window.location.reload();
                    },
                });
            }
        });
        // getModelViewer.addEventListener('load', () => {
        // });
    }, []);

    useEffect(() => {
        if (modelLoadingProgress === 1) {
            var mytimer = setTimeout(() => {
                reduxDispatch({ type: MODEL_IS_LOADING, payload: false });
            }, 1000);
        }
        return () => {
            clearTimeout(mytimer);
        };
    }, [modelLoadingProgress]);

    const getSpotXYandHalfWH = (x, y) => {
        const getModelViewer = document.querySelector('#myEditor');
        const offsetX = window.innerWidth - getModelViewer.offsetWidth;
        const offsetY = window.innerHeight - getModelViewer.offsetHeight;
        const halfW = getModelViewer.offsetWidth / 2;
        const halfH = getModelViewer.offsetHeight / 2;
        const spotX = x - offsetX;
        const spotY = y - offsetY;

        // console.log(`this is spotX ${111}`, spotX);
        // console.log(`this is halfW ${111}`, halfW);

        // console.log(`this is spotY ${222}`, spotY);
        // console.log(`this is halfH ${222}`, halfH);

        const getSectionType = () => {
            if (spotX > halfW && spotY < halfH) return 'section4';
            if (spotX > halfW && spotY >= halfH) return 'section2';
            if (spotX <= halfW && spotY > halfH) return 'section1';
            return 'section3';
        };

        const resultSection = getSectionType();

        return resultSection;
    };

    useEffect(() => {
        if (hotspots.length === 0) return;
        if (hotspots.length !== store.model.tagList.length) {
            let hotspotTotal = document.querySelectorAll('.hotspotTop').length;
            if (isObjectTag) {
                document.querySelectorAll('.hotspotTop')[hotspotTotal - 1].click();
            }
        }
    }, [hotspots]);

    useEffect(() => {
        if (remainingTags.length > 0) {
            setHotspots([...remainingTags]);
        }
    }, [remainingTags]);


    useEffect(() => {
        setExposure(store.shadowCard?.shadowCard?.exposure);
    }, [store.shadowCard?.shadowCard?.exposure]);

    useEffect(() => {
        setShadowIntensity(store.shadowCard?.shadowCard?.shadowIntensity);
    }, [store.shadowCard?.shadowCard?.shadowIntensity]);

    useEffect(() => {
        setShadowSoftness(store.shadowCard.shadowCard?.shadowSoftness);
    }, [store.shadowCard?.shadowCard?.shadowSoftness]);

    useEffect(() => {
        if (store.hdrSky?.hdrSky === 'neutral') {
            setHdrSky('neutral');
        } else if (store.hdrSky?.hdrSky === 'default') {
            setHdrSky('');
        } else if (store.hdrSky?.hdrSky === 'spruit sunrise') {
            setHdrSky(HDR_FILE.SPRUIR_SUNRISE);
        } else if (store.hdrSky?.hdrSky === 'aircraft workshop') {
            setHdrSky(HDR_FILE.AIRCRAFT_WORKSHOP);
        } else if (store.hdrSky?.hdrSky === 'music hall') {
            setHdrSky(HDR_FILE.MUSIC_HALL);
        } else if (store.hdrSky?.hdrSky === 'pillars') {
            setHdrSky(HDR_FILE.PILLARS);
        } else if (store.hdrSky?.hdrSky === 'whipple creek') {
            setHdrSky(HDR_FILE.WHIPPLE_CREEK);
        }
    }, [store.hdrSky?.hdrSky]);


    useEffect(() => {
        if (modelList?.length > 0) {
            let checkModelId = false;
            for (let item of modelList) {
                if (item.id === parseFloat(Cookies.get('modelId'))) {
                    checkModelId = true;
                }
            }
            if (!checkModelId) {
                return;
            }
            setInitModel(modelList);
            reduxDispatch({ type: CLEAR_OLD_TAG_PANEL, payload: [] });
            reduxDispatch({ type: OLD_TAG_PANEL, payload: modelList.find(model => model.id === parseFloat(Cookies.get('modelId')))?.tags });
            reduxDispatch({ type: GET_TAG_LIST, payload: modelList.find(model => model.id === parseFloat(Cookies.get('modelId')))?.tags });

            setHotspots([]);
            const getModelViewer = document.querySelector('#myEditor');
            getModelViewer.addEventListener('error', (e) => {
                setModelStatus(false);
                setModalSetting({
                    ...modalSetting,
                    show: true,
                    title: '',
                    type: 'type12',
                    coverSetting: true,
                    handleOtherAction: true,
                    otherBtnText: t('PleaseTryAgain'),
                    handleConfirm: () => {
                        window.location.reload();
                    },
                });
            });
        }
    }, [modelList]);

    useEffect(() => {
        const reduxNewTags = store.tagPanel.newTags;
        const currentTags = store.model.tagList;
        const updateNewTags = reduxNewTags.map((tag, index) => {
            tag.orderNumber = currentTags.length + index + 1;
            return tag;
        });
        setHotspots([...store.model.tagList, ...updateNewTags]);
    }, [store.model.tagList]);

    const handleTagMouseDown = (tag, spotId, MouseEvent) => {
        if (MouseEvent.buttons % 2 == 0) {
            return;
        }
        /*
        const targetPanelDisplay = document.querySelector(`#${spotId} > .editPanel`).style.display;
        if (targetPanelDisplay === 'none') {
            return;
        }
        */
        //console.log('handleTagMouseDown', tag);
        setDraggingTag(tag);
        setDraggingHotspotId(spotId);
    };

    const handleMouseUp = (MouseEvent) => {
        //console.log('handleMouseUp', draggingHotspotId, currentHotspot.id);
        if (currentHotspotId !== '') {
            document.querySelector(`#${currentHotspotId} > .editPanel`).style = '';
            const getSpot = document.querySelector(`#${currentHotspotId}`).getBoundingClientRect();
            const spotX = getSpot.x;
            const spotY = getSpot.y;

            setSectionType(getSpotXYandHalfWH(spotX, spotY));
        }

        if (draggingHotspotId === '') {
            //console.log('aaa', store.tagPanel, currentHotspot.id);
            return;
        }

        //console.log('handleMouseUp2', draggingHotspotId, currentHotspot.id);
        const viewer = document.querySelector('model-viewer#myEditor');

        const x = MouseEvent.clientX;
        const y = MouseEvent.clientY;
        const positionAndNormal = viewer.positionAndNormalFromPoint(x, y);

        if (positionAndNormal === null) {
            viewer.updateHotspot({
                name: draggingHotspotId,
                position: draggingTag.dataPosition,
                normal: draggingTag.dataNormal,
            });
            setDraggingTag(null);
            setDraggingHotspotId('');
            return;
        }

        const { position, normal } = positionAndNormal;
        const cameraOrbit = viewer.getCameraOrbit();
        const cameraTarget = viewer.getCameraTarget();

        //console.log('handleMouseUp', draggingTag);
        reduxDispatch({
            type: CHANGE_TAG, payload: {
                orderNumber: draggingTag.orderNumber,
                id: draggingTag.id,
                dataPosition: position.toString(),
                dataNormal: normal.toString(),
                cameraOrbit: cameraOrbit.toString(),
                cameraTarget: cameraTarget.toString(),
            },
        });

        setDraggingTag(null);
        setDraggingHotspotId('');
    };

    const handleMouseMove = (MouseEvent) => {
        //console.log('handleMouseMove', modelDragged, draggingHotspotId);
        if (MouseEvent.buttons % 2 == 1 && draggingHotspotId === '') {
            //console.log('handleMouseMove setModelDragged', modelDragged);
            if (modelDragged === false) {
                setModelDragged(true);
            }
        }

        if (MouseEvent.buttons % 2 === 1) {
            hideAllEditPanel();
        }

        if (MouseEvent.buttons % 2 === 0 || draggingHotspotId === '') {
            return;
        }

        //console.log('handleMouseMove', MouseEvent.buttons, draggingHotspotId);
        setTagDragged(true);
        const viewer = document.querySelector('model-viewer#myEditor');

        const x = MouseEvent.clientX;
        const y = MouseEvent.clientY;
        const positionAndNormal = viewer.positionAndNormalFromPoint(x, y);

        if (positionAndNormal === null) {
            return;
        }

        const { position, normal } = positionAndNormal;

        //console.log(draggingHotspotId, position, normal);
        hideAllEditPanel();

        viewer.updateHotspot({
            name: draggingHotspotId,
            position: position.toString(),
            normal: normal.toString(),
        });

    };

    const handleDoubleClick = (MouseEvent) => {
        const tagSwitch = store.switch.objectTagSwitch;

        if (!tagSwitch) return;
        if (location.pathname !== '/objectTag') return;

        const viewer = document.querySelector('model-viewer#myEditor');
        // const hotspotCount = document.querySelectorAll('.hotspot').length;
        const hotspotCount = hotspots.length;
        const x = MouseEvent.clientX;
        const y = MouseEvent.clientY;
        const positionAndNormal = viewer.positionAndNormalFromPoint(x, y);

        // if not click on the model, then return
        if (positionAndNormal === null) {
            return;
        }

        const { position, normal } = positionAndNormal;
        const cameraOrbit = viewer.getCameraOrbit();
        const cameraTarget = viewer.getCameraTarget();
        // create the hotspot

        // const hotspot = document.createElement('button');
        // hotspot.innerHTML = hotspotCount + 1;

        // create new hotspot

        const createNewHotspot = {
            content: '',
            contentTitle: '',
            dataNormal: normal.toString(),
            dataPosition: position.toString(),
            cameraOrbit: cameraOrbit.toString(),
            cameraTarget: cameraTarget.toString(),
            orderNumber: `${hotspotCount + 1}`,
            id: null,
            title: '',
            url: '',
            imageFile: '',
        };

        // NEW_TAG_PANEL
        reduxDispatch({ type: NEW_TAG_PANEL, payload: createNewHotspot });
        setHotspots([...hotspots, createNewHotspot]);
        reduxDispatch({ type: IS_SAVE, payload: true });
    };

    const handleHideInfoCard = (e) => {
        //console.log('handleHideInfoCard0', modelDragged, currentHotspot.id);
        if (modelDragged === true) {
            setModelDragged(false);
            return;
        }

        //console.log('handleHideInfoCard', modelDragged, currentHotspot.id);
        e.stopPropagation();
        setCurrentHotspot(null);
        setCurrentHotspotId('');
        hideAllEditPanel();
        // setNoHover('');
        // for (let i = 0; i < document.querySelectorAll('.editPanel').length;i++){
        //     document.querySelectorAll('.editPanel')[i].style.display = 'none';
        // }
        // clickOnViewer(e);
    };

    const handleEditingHotspot = (tagId) => {
        if (tagId == null) return;

        const editingTags = store.tagPanel.editingTags;

        if (editingTags.indexOf(tagId) === -1) {
            //這裡做做看資料比對
            reduxDispatch({ type: EDITING_TAGS, payload: tagId });
        }
        reduxDispatch({ type: IS_SAVE, payload: true });
        // if (editingHotspotId.indexOf(tagId) === -1) {
        //     setEditingHotspotId([...editingHotspotId, tagId]);

        // }
    };

    const handleClickHotspot = (tag, spotId) => (e) => {
        //console.log('handleClickHotspot', currentHotspot.id);
        // if (location.pathname !== '/objectTag') return;

        if (e) {
            e.stopPropagation();
        }
        const targetPanelDisplay = document.querySelector(`#${spotId} > .editPanel`).style.display;

        hideAllEditPanel();
        document.querySelector(`#${spotId} > .editPanel`).style = '';
        setNoHover(tag);
        if (!targetPanelDisplay && !tagDragged) {
            setNoHover('');
            setCurrentHotspotId('');
            return document.querySelector(`#${spotId} > .editPanel`).style.display = 'none';
        }
        document.querySelector(`#${spotId} .titleInput`).focus();

        setTagDragged(false);

        const getSpot = document.querySelector(`#${spotId}`).getBoundingClientRect();
        const spotX = getSpot.x;
        const spotY = getSpot.y;

        /*
        const modelViewer = document.querySelector('#myEditor');
        modelViewer.cameraTarget = tag.cameraTarget;
        modelViewer.cameraOrbit = tag.cameraOrbit;
        */

        setCurrentHotspot(tag);
        setCurrentHotspotId(spotId);
        setSectionType(getSpotXYandHalfWH(spotX, spotY));

        return;

    };

    useEffect(() => {
        if (store.tagPanel.sidebarEditingTag.serialNumber) {
            const serialNumber = store.tagPanel.sidebarEditingTag.serialNumber;
            const tagId = `hotspot-${serialNumber}`;
            const tag = store.tagPanel.sidebarEditingTag.tag;
            handleClickHotspot(tag, tagId)(false);
        }
    }, [store.tagPanel.sidebarEditingTag.serialNumber]);

    useEffect(() => {
        const handleHideTagCard = () => {
            //console.log('handleHideTagCard', modelDragged);
            //hideAllEditPanel();
            //setNoHover('');
        };
        document.addEventListener('click', handleHideTagCard);
        return () => document.removeEventListener('click', handleHideTagCard);
    }, []);

    const handleNewTagMouseEnter = (e, spot) => {
        if (e.target.id.includes('editPanel')) {
            return;
        }

        if (!spot.id) {
            setCurrentHoverNewTag(spot.orderNumber);
        } else {
            setCurrentHoverOldTag(spot.id);
        }
    };

    const handleNewTagMouseLeave = () => () => {
        setCurrentHoverNewTag('');
        setCurrentHoverOldTag('');
    };

    const handleDeleteTagApiCall = async (projectId, modelId, tagId) => {
        const response = await deleteDeleteTag(projectId, modelId, tagId);
        const resPostTagSwitch = await postTagSwitch(store.switch.objectTagSwitch, projectId, modelId);
        if (response.status === 'success') {
            setHotspots([]);
            await refetchModelList(projectId);
        }

        if (response.status === 'failed' && !response.result) {
            setModalSetting({
                ...modalSetting,
                show: true,
                title: '',
                type: 'type14',
                handleConfirm: () => { },
            });
        }
    };

    const handleDeleteHotspot = (orderNumber, tag) => () => {
        setModalSetting({
            ...modalSetting,
            show: true,
            title: tag?.title,
            type: 'type1',
            handleConfirm: () => {

                // delete tag already exits, api calls will be implemented
                if (tag.id) {
                    setHotspots([]);
                    handleDeleteTagApiCall(projectId, modelId, tag.id);
                    const updateHotspots = hotspots
                        .filter(spot => spot.id !== tag.id)
                        .map((spot, index) => {
                            if (!spot.id) {
                                spot.orderNumber = index + 1;
                            }
                            return spot;
                        });
                    setRemainingTags(updateHotspots);
                }

                // delete tag who is not yet created via api, only remove from dom
                if (!tag.id) {
                    // document.querySelector(`#hotspot-${orderNumber}`).remove();
                    setHotspots([]);
                    reduxDispatch({ type: CLEAR_NEW_TAG_PANEL, payload: [] });

                    const updateHotspots = hotspots
                        .filter(spot => spot.orderNumber !== orderNumber)
                        .map((spot, index) => {
                            if (!spot.id) {
                                spot.orderNumber = index + 1;
                            }
                            return spot;
                        });

                    setRemainingTags(updateHotspots);
                }
                reduxDispatch({ type: REMOVE_NEW_TAG, payload: orderNumber });
                return;
            },
        });
    };

    // ======================= animation ========================
    const [showAnimateHidden, setShowAnimateHidden] = useState(false);
    const [currentPlayTime, setCurrentPlayTime] = useState('0.00');
    const [currentPercent, setcurrentPercent] = useState(0);
    const [showTootltip, setShowTootltip] = useState(null);
    const [tootltipItemName, setTootltipItemName] = useState(null);
    const [tootltipItemTop, setTootltipItemTop] = useState(0);
    const [tootltipTime, setTootltipTime] = useState(null);
    const [showTootltipTime, setShowTootltipTime] = useState(false);
    const [tootltipTimePositionX, setTootltipTimePositionX] = useState(0);
    const [isAfterScrub, setIsAfterScrub] = useState(false);

    useEffect(() => {
        let animationList = [];
        const getModelViewer = document.querySelector('#myEditor');
        getModelViewer.addEventListener('load', async (e) => {
            for (let item of getModelViewer.availableAnimations) {
                await getModelViewer.setAttribute('animation-name', item);
                animationList.push({ name: item, duration: getModelViewer.duration.toFixed(2), ownSwitch: true, disable: false });
            }
            if (animationList.length === 1) {
                animationList[0].disable = true;
            }
            if (animationList.length === 0) {
                return;
            }
            if (modelList?.length > 0) {
                let nowModel = modelList.find((item) => {
                    if (item.id === parseFloat(Cookies.get('modelId'))) {
                        return item;
                    }
                });
                // 判斷有無設定
                if (JSON.parse(nowModel.animationItemSwitch)) {
                    for (let i = 0; i < Object.keys(JSON.parse(nowModel.animationItemSwitch)).length; i++) {
                        animationList[i].ownSwitch = JSON.parse(nowModel.animationItemSwitch)[i].ownSwitch;
                        animationList[i].disable = JSON.parse(nowModel.animationItemSwitch)[i].disable;
                    }
                }

                let defaultCurrent = animationList.find((item) => item.ownSwitch === true);
                if (isAnimation && nowModel.annotationSwitch) {
                    getModelViewer.setAttribute('animation-name', defaultCurrent.name);
                }
                reduxDispatch({ type: CURRENT_ANIMATE, payload: defaultCurrent });
                reduxDispatch({ type: ANIMATE_LIST, payload: animationList });
                reduxDispatch({ type: MAIN_ANIMATE_SWITCH, payload: nowModel.annotationSwitch });
            }
        });
    }, [modelList]);

    useEffect(() => {
        const getModelViewer = document.querySelector('#myEditor');
        if (!isAnimation && store.animation.mainAnimateSwitch) {
            getModelViewer.setAttribute('animation-name', '');
            getModelViewer.removeAttribute('animation-name');
            getModelViewer.pause();
            reduxDispatch({ type: ANIMATE_PLAY_STATUS, payload: false });
        } else {
            if (store.animation.mainAnimateSwitch) {
                if (store.animation?.currentAnimate) {
                    getModelViewer.setAttribute('animation-name', store.animation.currentAnimate.name);
                }
                getModelViewer.play();
                reduxDispatch({ type: ANIMATE_PLAY_STATUS, payload: true });
            }
        }
        return () => {
            setcurrentPercent(0);
            setCurrentPlayTime('0.00');
        };
    }, [isAnimation]);

    useEffect(() => {
        const getModelViewer = document.querySelector('#myEditor');
        if (store.animation.animatePlayStatus) {
            var interval = setInterval(() => {
                setCurrentPlayTime(getModelViewer.currentTime.toFixed(2));
                let percent = (getModelViewer.currentTime / getModelViewer.duration) * 100;
                setcurrentPercent(percent);
            }, 1);
        }
        return () => {
            clearInterval(interval);
        };
    }, [store.animation.animatePlayStatus]);

    useEffect(() => {
        setCurrentPlayTime('0.00');
        setcurrentPercent(0);
    }, [store.animation?.currentAnimate]);

    useEffect(() => {
        if (!store.animation.mainAnimateSwitch) {
            setCurrentPlayTime('0.00');
            setcurrentPercent(0);
        }
    }, [store.animation.mainAnimateSwitch]);

    useEffect(() => {
        if (isAfterScrub && store.animation.animatePlayStatus) {
            var mytimmer = setTimeout(() => {
                const getModelViewer = document.querySelector('#myEditor');
                getModelViewer.play();
                reduxDispatch({ type: ANIMATE_PLAY_STATUS, payload: true });
            }, 100);
        }
        return () => {
            clearTimeout(mytimmer);
        };
    }, [isAfterScrub]);

    const handleChangeAnimate = async (item) => {
        const getModelViewer = document.querySelector('#myEditor');
        getModelViewer.pause();
        await getModelViewer.setAttribute('animation-name', item.name);
        reduxDispatch({ type: CURRENT_ANIMATE, payload: item });
        if (store.animation.animatePlayStatus) {
            getModelViewer.play();
        }
    };

    const handlePlayStatus = () => {
        const getModelViewer = document.querySelector('#myEditor');
        if (store.animation.animatePlayStatus) {
            getModelViewer.pause();
        } else {
            getModelViewer.play();
        }
        reduxDispatch({ type: ANIMATE_PLAY_STATUS, payload: !store.animation.animatePlayStatus });
    };

    const onScrub = () => {
        setIsAfterScrub(true);
        const getModelViewer = document.querySelector('#myEditor');
        getModelViewer.pause();
        let value = (tootltipTime * 100) / getModelViewer.duration;
        getModelViewer.currentTime = tootltipTime;
        setcurrentPercent(value);
        setCurrentPlayTime(tootltipTime);
    };

    const handleAfterScrub = () => {
        setIsAfterScrub(false);
        if (store.animation.animatePlayStatus && tootltipTime == 0.00) {
            const getModelViewer = document.querySelector('#myEditor');
            getModelViewer.play();
        }
    };

    const handleHoverTooltips = (name, e) => {
        // 比對的是英文或數字
        var regExp = /[a-zA-Z0-9]/;
        if (name.length < 15 && regExp.test(name)) {
            return;
        } else if (name.length < 7 && !regExp.test(name)) {
            return;
        }
        setTootltipItemName(name);
        let Y = e.target.getBoundingClientRect().top;
        setTootltipItemTop(Y + 10);
        setShowTootltip(true);
    };

    const handleshowTooltipTime = (e) => {
        const getModelViewer = document.querySelector('#myEditor');
        let event = window.event;
        let inputidth = e.target.clientWidth;
        let X = e.target.getBoundingClientRect().left;
        let mouseX = event.pageX;
        let nowMouseTootipTime = (((mouseX - X) / inputidth) * getModelViewer.duration).toFixed(2);
        if (nowMouseTootipTime < 0 || nowMouseTootipTime > getModelViewer.duration.toFixed(2)) {
            setShowTootltipTime(false);
            return;
        }
        if (mouseX - X < 0) {
            nowMouseTootipTime = 0.001;
            nowMouseTootipTime = nowMouseTootipTime.toFixed(2);
        }
        setTootltipTime(nowMouseTootipTime);
        setShowTootltipTime(true);
        setTootltipTimePositionX(mouseX - X + 10);
    };


    return (
        <StyledModelViewer className="modelSection" animatePlayStatus={store.animation.animatePlayStatus}>
            {
                modelLoadingProgress < 1 && (
                    <div className="loadingProgressContainer flexCenter flexY">
                        <div className="progressText">
                            iStaging
                        </div>
                        <div className="progressBarPanel">
                            <ProgressBar now={modelLoadingProgress} min={0} max={1} />
                        </div>
                    </div>
                )
            }
            <model-viewer
                ar
                camera-controls
                id="myEditor"
                // src="./assets/ball/scene.gltf"
                src={`${initModel.filter((model) => model.id === parseFloat(Cookies.get('modelId')))[0]?.lastUploadFileType === 'gltf' ? initModel.filter((model) => model.id === parseFloat(Cookies.get('modelId')))[0]?.gltfSrc : initModel.filter((model) => model.id === parseFloat(Cookies.get('modelId')))[0]?.glbSrc}`}
                // ios-src="./assets/ball/Small_One_Handed_Axe.usdz"
                ios-src=""
                poster={<LoadingIcon />}
                ar-modes="webxr scene-viewer quick-look"
                seamless-poster=""
                interaction-prompt-style="basic"
                loading="auto"
                onDoubleClick={handleDoubleClick}
                onClick={(e) => handleHideInfoCard(e)}
                onMouseMove={(e) => handleMouseMove(e)}
                onMouseUp={(e) => handleMouseUp(e)}
                environment-image={hdrSky === 'default' ? '' : hdrSky}
                shadow-intensity={shadowIntensity}
                shadow-softness={shadowSoftness}
                exposure={exposure}
                interaction-prompt-style={isAnimation ? 'basic' : 'wiggle'}
            >
                {
                    isAnimation && <div slot="interaction-prompt"></div>
                }
                {
                    isObjectTag && store.switch.objectTagSwitch && hotspots.map((spot, index) => {
                        const isEditingTag = store.tagPanel.editingTags.indexOf(spot.id);
                        const serialNumber = index + 1;
                        return (
                            <div
                                key={spot.id || spot.orderNumber}
                                className='hotspotTop'
                                id={`hotspot-${serialNumber}`}
                                name={`hotspot-${serialNumber}`}
                                slot={`hotspot-${serialNumber}`}
                                data-tag-id={spot.id}
                                data-position={spot.dataPosition}
                                data-normal={spot.dataNormal}
                                data-orbit={spot.cameraOrbit}
                                data-target={spot.cameraTarget}
                                onClick={handleClickHotspot(spot, `hotspot-${serialNumber}`)}
                                onMouseEnter={(e) => handleNewTagMouseEnter(e, spot)}
                                onMouseLeave={handleNewTagMouseLeave(spot)}
                                onMouseDown={(e) => handleTagMouseDown(spot, `hotspot-${serialNumber}`, e)}
                            >
                                <div
                                    className={clsx('hotspot', {
                                        'selected': Boolean(spot.id) && isEditingTag === -1,
                                        'oldHover': Boolean(spot.id) && isEditingTag === -1,
                                        'newHover': !Boolean(spot.id) || isEditingTag !== -1,
                                        'noHover': noHover === spot,
                                    })}
                                >
                                    {serialNumber}
                                </div>

                                <EditPanel
                                    className={clsx({
                                        'show': spot.id === currentHotspot?.id,
                                    })}
                                    orderNumber={serialNumber}
                                    tag={spot}
                                    projectId={projectId}
                                    modelId={modelId}
                                    refetchModelList={refetchModelList}
                                    handleDeleteHotspot={() => handleDeleteHotspot(spot.orderNumber ? spot.orderNumber : serialNumber, spot)}
                                    sectionType={sectionType}
                                    setModalSetting={setModalSetting}
                                    modalSetting={modalSetting}
                                    handleEdited={() => handleEditingHotspot(spot.id)}
                                />
                                <div className={clsx('unsaveTooltip', {
                                    'showToolTip': !Boolean(spot.id) && spot.orderNumber === currentHoverNewTag,
                                    'showEditingToolTip': Boolean(spot.id) && spot.id === currentHoverOldTag && isEditingTag !== -1,
                                })}>

                                    {
                                        Boolean(spot.id) && spot.id === currentHoverOldTag && isEditingTag !== -1
                                            ? t('unsavedPrompt')
                                            : t('notSaveTagsPrompt')
                                    }
                                    <div className="arrowRight" />
                                </div>
                            </div>
                        );
                    })
                }
            </model-viewer>
            {
                isAnimation && store.animation.mainAnimateSwitch && store.animation.animateList.length > 0 &&
                <AnimateController
                    animatePlayStatus={store.animation.animatePlayStatus}
                    currentPercent={currentPercent}
                    onScrub={onScrub}
                    handlePlayStatus={handlePlayStatus}
                    setShowAnimateHidden={setShowAnimateHidden}
                    currentPlayTime={currentPlayTime}
                    showAnimateHidden={showAnimateHidden}
                    handleHoverTooltips={handleHoverTooltips}
                    setShowTootltip={setShowTootltip}
                    showTootltip={showTootltip}
                    handleChangeAnimate={handleChangeAnimate}
                    tootltipItemName={tootltipItemName}
                    tootltipItemTop={tootltipItemTop}
                    handleshowTooltipTime={handleshowTooltipTime}
                    setShowTootltipTime={setShowTootltipTime}
                    showTootltipTime={showTootltipTime}
                    tootltipTime={tootltipTime}
                    tootltipTimePositionX={tootltipTimePositionX}
                    setCurrentPlayTime={setCurrentPlayTime}
                    handleAfterScrub={handleAfterScrub}

                />
            }
            {
                modalSetting.show && (
                    <ModalPopup
                        modalConfig={modalConfig[modalSetting.type]}
                        setModalSetting={setModalSetting}
                        modalSetting={modalSetting}
                        coverSetting={true}
                    />
                )
            }
        </StyledModelViewer >
    );
};

export default ModelViewer;
