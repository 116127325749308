import styled from 'styled-components';

export const StyledNavigationBar = styled.div`
    width: 64px;
    height: 100%;
    background: #121212;
    padding-top: 50px;
    position: relative;
    z-index: 10;
    .nav {
        position: relative;
        width: 64px;
        height: 64px;
        flex-direction: column;
        color: #fff;
        opacity: ${props=>props.isLoading ? '0.2' : '1'};
        &:hover{
            background-color: rgba(255, 255, 255, 0.12);
            img{
                opacity: 1;
            }
            .navName{
                display: flex;
            }
        }
        >a{
            color: #fff;
            text-decoration:none;
        }
        .navIcon {
            width: 100%;
            font-size: 24px;
        }
        .navName{
            position: absolute;
            color: #fff;
            height: 32px;
            top:50%;
            margin-top: -16px;
            left:65px;
            z-index: 2;
            justify-content: flex-start;
            align-items: center;
            display: none;
            .nn{
                padding-left: 10px;
                padding-right: 10px;
                background-color: #121212;
                white-space: nowrap;
                height: 32px;
                line-height: 32px;
                font-size: 14px;
            }
            .angle{
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 6px 14px 6px 0;
                border-color: transparent #121212 transparent transparent;
            }
        }
        img{
            width: 37px;
            opacity: 0.6;
        }
    }
    .noLink{
        background: none;
        &:hover{
            background: none;
            img{
                opacity: 0.2;
            }
        }
        img{
            opacity: ${props=>props.isLoading ? '1' : '0.2'};
        }
    }
    .currentNav{
        background-color: #FF744E;
        opacity: 1;
        img{
            opacity: 1;
        }
        &:hover{
            background-color: #FF744E;
        }
    }
`;