import { LAST_HDR_SKY } from 'redux/types/lastHdrSky.types';

let LAST_HDR_SKY_RES = {
    lastHdrSky: '',
};

const lastHdrSkyReducer = (state = LAST_HDR_SKY_RES, action) => {
    switch (action.type) {
        case LAST_HDR_SKY:
            return {
                ...state, lastHdrSky: action.payload,
            };
        default: return state;
    }
};

export default lastHdrSkyReducer;