import { SECRET } from 'redux/types/secret.types';

let SECRET_RES = {
    secret: '',
};

const secretReducer = (state = SECRET_RES, action) => {
    switch (action.type) {
        case SECRET:
            return {
                ...state, secret: action.payload,
            };
        default: return state;
    }
};

export default secretReducer;