import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

//redux
import { GET_MODEL_LIST } from 'redux/types/model.types';
import { AR_SWITCH } from 'redux/types/switch.types';

//styled components
import { StyledArOpen } from './StyledArOpen';

//components
import Switch from '../Switch/Switch';
import ModalPopup from 'components/ModalPopup/ModalPopup';
import SmallLoading from 'components/SmallLoading/SmallLoading';

//images
import arIcon from './images/ar.svg';
import ar1 from './images/ar1.svg';
import ar2 from './images/ar2.svg';

// hooks
import useModal from 'hooks/useModal';

// config
import { modalConfig } from 'config/modal';


//react icons
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { BsTrashFill, BsArrowRepeat } from 'react-icons/bs';

//plugin
import Dropzone from 'react-dropzone';
import Cookies from 'js-cookie';
import * as dayjs from 'dayjs';

//api
import { usdzUpload } from 'api/usdzUpload/usdzUpload.api';
import { getModelList } from 'api/getModelList/getModelList';
import { deleteUsdz } from 'api/deleteUsdz/deleteUsdz.api';
import { uplateModel } from 'api/uplateModel/uplateModel.api';

//i18n
import { useTranslation } from 'react-i18next';

function sortFn(modelList) {
    let sortAry = modelList.sort((a, b) => {
        return dayjs(b.lastUpdated).unix() - dayjs(a.lastUpdated).unix();
    });

    for (let i = 0; i < sortAry.length; i++) {
        if (sortAry[i].mainObject === true) {
            let mainTemp = sortAry[i];
            sortAry[i] = sortAry[0];
            sortAry[0] = mainTemp;
        }
    }

    return sortAry;
}


const ArOpen = () => {

    const projectId = parseInt(Cookies.get('projectId'));
    const modelId = parseInt(Cookies.get('modelId'));
    const token = Cookies.get('token');
    const store = useSelector(store => store);
    const dispatch = useDispatch();
    const { modalSetting, setModalSetting } = useModal();
    const [isLoad, setLoad] = useState(false);
    const [finshLoad, setFinshLoad] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [usdzName, setUsdzName] = useState('');
    const [switchAr, setArSwitch] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {

        let usdzInfo = store.model?.modelList?.filter(item => item.id === parseInt(Cookies.get('modelId')))[0];
        let arSwitch = store.switch.objectArSwitch;

        if (usdzInfo?.usdzName !== '') {
            setFinshLoad(true);
            setLoad(true);
            setUsdzName(usdzInfo?.usdzName.slice(0, usdzInfo?.usdzName.indexOf('.usdz')));
        } else {
            setFinshLoad(false);
            setLoad(false);
        }

        // AR switch 畫面上的開關
        setArSwitch(arSwitch === null ? true : arSwitch);
        // if (arSwitch === null) {
        //     setArSwitch(true);
        //     // uplateModel({ viewModeSwitch: true }, token, projectId, modelId).then(res => {
        //     //     getModelList(projectId).then((modelList) => {
        //     //         dispatch({ type: GET_MODEL_LIST, payload: sortFn(modelList) });
        //     //     });
        //     // });
        // } else {
        //     setArSwitch(arSwitch);
        // }

        // console.log(store.model?.modelList?.filter(item => item.id === parseInt(Cookies.get('modelId')))[0]?.usdzName);

    }, [store.model?.modelList, store.switch.objectArSwitch]);

    const setStatusEvent = () => {
        setArSwitch(!switchAr);
        dispatch({ type: AR_SWITCH, payload: !switchAr });
    };

    const acceptedFiles = (e) => {
        let fileWork = null;
        if (Object.prototype.toString.call(e) === '[object Array]') {
            fileWork = e;
        } else if (Object.prototype.toString.call(e) === '[object Object]') {
            fileWork = e.target.files;
        }
        let fileData = new FormData();
        fileData.append('file', fileWork[0]);
        if (fileWork[0].size / (1024 * 1024) > 100) {
            setModalSetting({
                ...modalSetting,
                show: true,
                type: 'type21',
            });
            return;
        } else if (fileWork.length > 1) {
            setModalSetting({
                ...modalSetting,
                show: true,
                type: 'type22',
            });
            return;
        } else if (fileWork[0].name.indexOf('usdz') === -1) {
            setModalSetting({
                ...modalSetting,
                show: true,
                type: 'type23',
            });
            return;
        }
        setUsdzName(fileWork[0].name.slice(0, fileWork[0].name.indexOf('.usdz')));
        setFinshLoad(false);
        const handleUploadBar = (progress) => {
            setUploadProgress(progress);
        };
        usdzUpload(fileData, token, projectId, modelId, handleUploadBar).then(res => {
            setFinshLoad(true);

            getModelList(projectId).then((modelList) => {
                dispatch({ type: GET_MODEL_LIST, payload: sortFn(modelList) });
            });

        }).catch(err => {
            setUsdzName('');
            setLoad(false);
            setModalSetting({
                ...modalSetting,
                show: true,
                type: 'type26',
            });
        });
        setLoad(true);
    };

    const deleteUsdzEvent = () => {
        setModalSetting({
            ...modalSetting,
            show: true,
            title: usdzName,
            type: 'type20',
            handleConfirm: () => {
                deleteUsdz({ usdzName: '', usdzSrc: '' }, token, projectId, modelId).then(() => {
                    setFinshLoad(false);
                    setLoad(false);
                    setUsdzName('');
                    getModelList(projectId).then((modelList) => {
                        dispatch({ type: GET_MODEL_LIST, payload: sortFn(modelList) });
                    });
                }).catch(err => {
                    setModalSetting({
                        ...modalSetting,
                        show: true,
                        type: 'type28',
                    });
                });
            },
        });
    };



    return (
        <StyledArOpen progress={uploadProgress}>
            {modalSetting.show && (
                <ModalPopup
                    modalConfig={modalConfig[modalSetting.type]}
                    setModalSetting={setModalSetting}
                    modalSetting={modalSetting}
                />
            )}
            <div className="title">
                <div className="name">
                    {t('arProjection')}
                </div>
                <div className="switch" onClick={setStatusEvent}>
                    <Switch turn={switchAr} />
                </div>
            </div>
            <div className="middleTitle">
                <img src={ar1} className="arIcon" alt="" draggable="false" />
                <span>USDZ</span>
            </div>
            {
                !isLoad && <Dropzone onDrop={acceptedFiles}>
                    {({ getRootProps, getInputProps }) => (
                        <section>
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <div className="file" htmlFor='usdzInput'>
                                    <div>
                                        {t('uploadUsdzPrompt1')}<br />
                                        {t('uploadUsdzPrompt2')}
                                    </div>
                                    <b>
                                        <AiOutlineCloudUpload /> {t('upload')}
                                    </b>
                                </div>
                            </div>
                        </section>
                    )}
                </Dropzone>
            }
            {
                isLoad && <div>
                    <div className="file">
                        {!finshLoad && <div className="isLoadingPic">
                            <p className='title'>{t('uploading')}</p>
                            <div className="text">
                                <div className="name">
                                    <p>{usdzName}</p>
                                    .usdz
                                </div>
                                <div className="num">{uploadProgress}%</div>
                            </div>
                            <div className="progressBar">
                                <div className="bar"></div>
                            </div>
                        </div>}
                        {/* ===== */}
                        {finshLoad && <Dropzone onDrop={acceptedFiles}>
                            {({ getRootProps, getInputProps }) => (
                                <section>
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <div htmlFor='usdzInput'>
                                            <div className="isLoadingPic">

                                                <p className='successTitle'>
                                                    {t('enableARSuccessPrompt')}
                                                    <img src={arIcon} className="arIcon" alt="" />
                                                </p>

                                                <div className="text">
                                                    <div className="name">
                                                        <p>{usdzName}</p>
                                                        .usdz
                                                    </div>
                                                    <div className="num_already">100%</div>
                                                </div>
                                                <div className="progressBar_already">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            )}
                        </Dropzone>}

                    </div>
                    {
                        finshLoad && <div className='finshCon'>
                            <div className="infoFinsh" onClick={deleteUsdzEvent}>
                                <BsTrashFill />
                            </div>
                            <div className='finshCtrl'>
                                <label htmlFor="fileInput">
                                    <BsArrowRepeat />
                                </label>
                            </div>
                        </div>
                    }

                </div >
            }
            <input onChange={acceptedFiles} type="file" id="fileInput" style={{ display: 'none' }} />
            <div className="remind">
                <img src={arIcon} className="arIcon" alt="" draggable="false" />
                <div className="word">
                    {t('arProjectionPrompt')}
                </div>
            </div>
        </StyledArOpen >
    );
};

export default ArOpen;