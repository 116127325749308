// react
import React, { Suspense, useEffect, useState } from 'react';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';

// redux
import { useDispatch, useSelector } from 'react-redux';

// redux types
import { GET_MODEL_LIST } from 'redux/types/model.types';
import { TAG_SWITCH, SWITCH, AR_SWITCH } from 'redux/types/switch.types';
import { USER_INFO } from 'redux/types/userInfo.types';
import { PROJECT_INFO } from 'redux/types/projectInfo.types';
import { SHADOW_CARD } from 'redux/types/shadowCard.types';
import { HDR_SKY } from 'redux/types/hdrSky.types';
import { LAST_HDR_SKY } from 'redux/types/lastHdrSky.types';
import { ICON } from 'redux/types/icon.types';
import { PERMISSION } from 'redux/types/permission.types';
import { USER_PROJECT_NUM } from 'redux/types/userProjectNum.types';
import { HDR_SKY_SWITCH } from 'redux/types/hdrSkySwitch.types';
import { SECRET } from 'redux/types/secret.types';
import { IS_SAVE } from 'redux/types/isSave.types';
import { MODEL_IS_LOADING } from 'redux/types/model.types';



// styled components
import { ThemeProvider } from 'styled-components';
import { StyledApp } from './StyeldApp';

// components
import Nav from 'common/Nav/Nav';
import Home from 'pages/Home/Home';
import ObjectInfo from 'pages/ObjectInfo/ObjectInfo';
import ObjectTag from 'pages/ObjectTag/ObjectTag';
import ObjectAnimation from 'pages/ObjectAnimation/ObjectAnimation';
// import ObjectLight from 'pages/ObjectLight/ObjectLight';
import ObjectLight from 'pages/ObjectLight/ObjectLight';
// import EnvLight from 'pages/EnvLight/EnvLight';
import EnvLight from 'pages/EnvLight/EnvLight';
import ArOpen from 'pages/ArOpen/ArOpen';
import Preview from 'pages/Preview/Preview';
import ModelAr from 'pages/ModelAr/ModelAr';
import ModelViewer from 'components/ModelViewer/ModelViewer';
import ModalPopup from 'components/ModalPopup/ModalPopup';
import CookieBanner from 'components/CookieBanner/CookieBanner';

// hooks
import useModal from 'hooks/useModal';

// constants
import THEME from 'constants/theme';

// react query
import { useQuery } from 'react-query';

// api
import { getModelList } from 'api/model/model.api';
import { loginGet } from 'api/loginInfo/loginInfo.api';
import { getProject } from 'api/getProject/getProject.api';
import { getGlobalSwitch } from 'api/globalSwitch/globalSwitch.api';
import { loginInfoNum } from 'api/loginInfoNum/loginInfoNum.api';
import { getHeartBeat } from 'api/getHeartBeat/getHeartBeat.api';

// utils
import { handleRerouteToARMaker } from 'utils/redirectUrl';

// config
import { modalConfig } from 'config/modal';

// plugin
import Cookies from 'js-cookie';
import * as dayjs from 'dayjs';

//i18n
import { useTranslation } from 'react-i18next';

//ga
import ReactGA from 'react-ga4';

ReactGA.initialize('G-B17J6K0VSN');
ReactGA.send('pageview');

document.oncontextmenu = () => false;


const hasToken = (urlParams) => {
    if (!urlParams.get('token') && (!Cookies.get('token') || Cookies.get('token') === 'null')) {
        return false;
    }

    return true;
};

const hasProjectId = (urlParams) => {
    if (!urlParams.get('projectId') && (!Cookies.get('projectId') || Cookies.get('projectId') === 'null')) {
        return false;
    }

    return true;
};

const hasModelId = (urlParams) => {
    if (!urlParams.get('modelsId') && (!Cookies.get('modelId') || Cookies.get('modelId') === 'null')) {
        return false;
    }

    return true;
};

function App() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const { t, i18n } = useTranslation();

    const [isPreview, setPreview] = useState(false);
    const [isModelAr, setModelAr] = useState(false);
    const [isAnimation, setIsAnimation] = useState(false);
    const [isObjectTag, setIsObjectTag] = useState(false);



    // 首次載入時網路傳案資料錯誤
    const [netStatus, setNetStatus] = useState(false);
    // 模型建立成功與否
    const [modelStatus, setModelStatus] = useState(true);


    useEffect(() => {
        if (urlParams.get('lang')) {
            Cookies.set('lang', urlParams.get('lang'));
        }

        i18n.changeLanguage(Cookies.get('lang'));
        if (decodeURIComponent(urlParams.get('preview')) === 'true' || window.location.pathname.indexOf('preview') > -1) {
            setPreview(true);
        }
        if (window.location.pathname.indexOf('modelAr') > -1) {
            setModelAr(true);
        }
        if (window.location.pathname.indexOf('objectAnimation') > -1) {
            setIsAnimation(true);
        }
        if (window.location.pathname.indexOf('objectTag') > -1) {
            setIsObjectTag(true);
        }

        if (!urlParams.get('secret')) {
            if (!hasToken(urlParams) || !hasProjectId(urlParams) || !hasModelId(urlParams)) {
                return handleRerouteToARMaker('_self');
            }
        }

        // window.addEventListener('beforeunload', function (e) {
        //     let confirmationMessage = '確定要離開這個網頁嗎？';

        //     (e || window.event).returnValue = confirmationMessage; //Gecko + IE
        //     return confirmationMessage; //Webkit, Safari, Chrome
        // });

        // window.addEventListener('onunload', function (e) {
        //     let confirmationMessage = true;
        //     (e || window.event).returnValue = confirmationMessage; //Gecko + IE
        //     return confirmationMessage; //Webkit, Safari, Chrome
        // });

    }, []);

    if (urlParams.get('projectId')) {
        Cookies.set('projectId', decodeURIComponent(urlParams.get('projectId')));
    }

    if (urlParams.get('modelsId')) {
        Cookies.set('modelId', decodeURIComponent(urlParams.get('modelsId')));
    }

    if (urlParams.get('token')) {
        Cookies.set('token', decodeURIComponent(urlParams.get('token')));
    }

    if (urlParams.get('isPublish')) {
        Cookies.set('isPublish', decodeURIComponent(urlParams.get('isPublish')));
    }

    const projectId = parseFloat(Cookies.get('projectId'));
    const modelId = parseFloat(Cookies.get('modelId'));

    const dispatch = useDispatch();
    const store = useSelector(store => store);
    const {
        data: modelList,
        isLoading: modelListIsLoading,
        refetch: refetchModelList,
    } = useQuery('getModelList', getModelList(projectId));
    const { modalSetting, setModalSetting } = useModal();

    const handleErrorResponse = (modalType) => {
        setModalSetting({
            ...modalSetting,
            show: true,
            title: '',
            type: modalType,
            handleConfirm: () => { },
        });
    };

    useEffect(() => {
        setNetStatus(true);
        setModalSetting({
            show: false,
        });
        if (!urlParams.get('secret')) {
            setModalSetting({
                show: false,
            });
            if (!modelList?.result && modelList?.status === 'failed') {
                // 當專案載入資料失敗錯誤
                setNetStatus(false);
                dispatch({ type: MODEL_IS_LOADING, payload: true });
                setModalSetting({
                    ...modalSetting,
                    show: true,
                    title: '',
                    type: 'type12',
                    coverSetting: true,
                    mode: 'editor',
                    handleOtherAction: true,
                    otherBtnText: t('PleaseTryAgain'),
                    handleConfirm: () => {
                        window.location.reload();
                    },
                });
                return;
            }

            if (modelList?.length > 0) {
                let checkModelId = false;
                for (let item of modelList) {
                    if (item.id === parseFloat(Cookies.get('modelId'))) {
                        checkModelId = true;
                    }
                }
                if (!checkModelId) {
                    dispatch({ type: MODEL_IS_LOADING, payload: true });
                    dispatch({ type: PERMISSION, payload: JSON.parse(atob(Cookies.get('token').split('.')[1])) });
                    if (isPreview) {
                        setModalSetting({
                            ...modalSetting,
                            show: true,
                            title: '',
                            type: 'type12',
                            coverSetting: true,
                            mode: 'preview',
                            handleOtherAction: true,
                            otherBtnText: t('PleaseTryAgain'),
                            handleConfirm: () => {
                                window.location.reload();
                            },
                        });
                    } else {
                        setModalSetting({
                            ...modalSetting,
                            show: true,
                            title: '',
                            type: 'type12',
                            coverSetting: true,
                            mode: 'editor',
                            handleOtherAction: true,
                            otherBtnText: t('PleaseTryAgain'),
                            handleConfirm: () => {
                                window.location.reload();
                            },
                        });
                    }
                    return;
                }
                // return;
                let sortAry = modelList.sort((a, b) => {
                    return dayjs(b.lastUpdated).unix() - dayjs(a.lastUpdated).unix();
                });

                for (let i = 0; i < sortAry.length; i++) {
                    if (sortAry[i].mainObject === true) {
                        let mainTemp = sortAry[i];
                        sortAry[i] = sortAry[0];
                        sortAry[0] = mainTemp;
                    }
                }

                // Cookies.set('isLoading', 'true');
                dispatch({ type: GET_MODEL_LIST, payload: sortAry });
                dispatch({ type: PERMISSION, payload: JSON.parse(atob(Cookies.get('token').split('.')[1])) });
                const getCurrentModel = modelList.find(model => model.id === modelId);
                loginGet(Cookies.get('token')).then((res) => {
                    dispatch({ type: SECRET, payload: JSON.parse(atob(Cookies.get('token').split('.')[1])).secret });
                    dispatch({ type: USER_INFO, payload: res });
                    getProject(Cookies.get('token')).then((res) => {
                        dispatch({ type: PROJECT_INFO, payload: res });
                        dispatch({
                            type: SHADOW_CARD, payload: {
                                exposure: getCurrentModel.exposure,
                                shadowIntensity: getCurrentModel.shadowIntensity,
                                shadowSoftness: getCurrentModel.shadowSoftness,
                            },
                        });
                        // console.log('看一下', getCurrentModel.lastEnvironment);
                        dispatch({ type: HDR_SKY, payload: getCurrentModel.environment === null ? 'neutral' : getCurrentModel.environment });
                        dispatch({ type: LAST_HDR_SKY, payload: getCurrentModel.lastEnvironment });
                        dispatch({ type: HDR_SKY_SWITCH, payload: getCurrentModel.checkEnvironment });
                        dispatch({ type: ICON, payload: getCurrentModel.icon });
                        dispatch({ type: PERMISSION, payload: JSON.parse(atob(Cookies.get('token').split('.')[1])) });
                        dispatch({ type: AR_SWITCH, payload: getCurrentModel.viewModeSwitch === null ? true : getCurrentModel.viewModeSwitch });
                        // console.log('id在這', getCurrentModel.id);
                        loginInfoNum(Cookies.get('token')).then((resProjectInfo) => {
                            dispatch({ type: USER_PROJECT_NUM, payload: resProjectInfo });
                        });
                        getGlobalSwitch(Cookies.get('projectId'), Cookies.get('token')).then((res) => {
                            // console.log(res.globalSwitch);
                            dispatch({ type: SWITCH, payload: Boolean(res.globalSwitch) });
                            dispatch({ type: IS_SAVE, payload: false });
                            if (window.location.href.indexOf('preview') > -1) {
                                window.history.pushState({}, 0, window.location.origin + '/preview');
                            } else {
                                window.history.pushState({}, 0, window.location.origin);
                            }
                        });
                    });
                }).catch((err) => {
                    // handleErrorResponse('type12');
                });
                dispatch({ type: TAG_SWITCH, payload: Boolean(getCurrentModel.tagSwitch) });

                if (!modelStatus) {
                    setModalSetting({
                        ...modalSetting,
                        show: true,
                        title: '',
                        type: 'type12',
                        coverSetting: true,
                        handleOtherAction: true,
                        otherBtnText: t('PleaseTryAgain'),
                        handleConfirm: () => {
                            window.location.reload();
                        },
                    });
                }
                return;
            }
        }

    }, [modelList]);


    // const pictureEvent = ()=>{
    //     // console.log('照相嚕');
    //     const modelViewer = document.querySelector('#myEditor');
    //     // const blob = modelViewer.toBlob({ idealAspect: false });
    //     // const url = URL.createObjectURL(blob);
    //     console.log(modelViewer.toDataURL());
    // };

    return (
        <ThemeProvider theme={THEME}>
            <StyledApp pathName={window.location.pathname}>
                <Router>
                    <Nav refetchModelList={refetchModelList} modelListIsLoading={modelListIsLoading} netStatus={netStatus} modelStatus={modelStatus} />
                    {/* <button style={{ position:'absolute', left:0, top:0, zIndex:555 }} onClick={pictureEvent}>照相嚕</button> */}
                    <Suspense fallback={<div />}>
                        <Routes>
                            <Route exact path="/" element={
                                <ObjectInfo
                                    modelList={modelList}
                                    refetchModelList={refetchModelList}
                                    modelListIsLoading={modelListIsLoading}
                                    netStatus={netStatus}
                                    modelStatus={modelStatus}
                                    setIsAnimation={setIsAnimation}
                                    setIsObjectTag={setIsObjectTag}
                                />}
                            />
                            {/* <Route exact path="/objectInfo" element={
                                <ObjectInfo
                                    modelList={modelList}
                                    refetchModelList={refetchModelList}
                                    modelListIsLoading={modelListIsLoading}
                                />}
                            /> */}
                            <Route exact path="/objectTag" element={
                                <ObjectTag
                                    modelList={modelList}
                                    refetchModelList={refetchModelList}
                                    modelListIsLoading={modelListIsLoading}
                                    netStatus={netStatus}
                                    modelStatus={modelStatus}
                                    setIsAnimation={setIsAnimation}
                                    setIsObjectTag={setIsObjectTag}
                                />}
                            />
                            <Route exact path="/objectAnimation" element={
                                <ObjectAnimation
                                    modelList={modelList}
                                    refetchModelList={refetchModelList}
                                    modelListIsLoading={modelListIsLoading}
                                    netStatus={netStatus}
                                    modelStatus={modelStatus}
                                    setIsAnimation={setIsAnimation}
                                    setIsObjectTag={setIsObjectTag}
                                />}
                            />
                            <Route exact path="/objectLight" element={
                                <ObjectLight
                                    modelList={modelList}
                                    refetchModelList={refetchModelList}
                                    modelListIsLoading={modelListIsLoading}
                                    netStatus={netStatus}
                                    modelStatus={modelStatus}
                                    setIsAnimation={setIsAnimation}
                                    setIsObjectTag={setIsObjectTag}
                                />}

                            />
                            <Route exact path="/envLight" element={
                                <EnvLight
                                    modelList={modelList}
                                    refetchModelList={refetchModelList}
                                    modelListIsLoading={modelListIsLoading}
                                    netStatus={netStatus}
                                    modelStatus={modelStatus}
                                    setIsAnimation={setIsAnimation}
                                    setIsObjectTag={setIsObjectTag}
                                />}
                            />
                            <Route exact path="/arOpen" element={
                                <ArOpen
                                    modelList={modelList}
                                    refetchModelList={refetchModelList}
                                    modelListIsLoading={modelListIsLoading}
                                    netStatus={netStatus}
                                    modelStatus={modelStatus}
                                    setIsAnimation={setIsAnimation}
                                    setIsObjectTag={setIsObjectTag}
                                />}
                            />
                            <Route exact path="/preview" element={<Preview
                                modelList={modelList} refetchModelList={refetchModelList} modelListIsLoading={modelListIsLoading} netStatus={netStatus} />} />
                            <Route exact path="/modelAr" element={<ModelAr />} />
                        </Routes>
                    </Suspense>
                    {
                        !isModelAr && !isPreview && store.viewMode.mode === 'normal' && (
                            <ModelViewer
                                modelList={modelList}
                                refetchModelList={refetchModelList}
                                modelListIsLoading={modelListIsLoading}
                                setModelStatus={setModelStatus}
                                isAnimation={isAnimation}
                                isObjectTag={isObjectTag}
                            />
                        )
                    }
                </Router>
                {modalSetting.show && (
                    <ModalPopup
                        modalConfig={modalConfig[modalSetting.type]}
                        setModalSetting={setModalSetting}
                        modalSetting={modalSetting}
                    />
                )}
                <CookieBanner />
            </StyledApp>
        </ThemeProvider>
    );
}

export default App;
