import styled from 'styled-components';
//images
import namIcon from './images/namIcon.svg';
import pauseIcon from './images/pauseIcon.svg';
import playIcon from './images/playIcon.svg';

export const StyledPreview = styled.div`
    width: 100vw;
    position: absolute;
    top:56px;
    /* background-color: mistyrose; */
    height: calc(100% - 56px);
    .isLoadingPanel {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: ${props => props.theme.zIndex.LayerTen};
        background: mistyrose
    }
    #myViewer {
        width: 100%;
        height: 100%;
        position: absolute;
        left:0;
        bottom: 0;
        .hotspot {
            z-index: ${props => props.theme.zIndex.LayerNine};
            position: relative;
            width: 28px;
            height: 28px;
            border-radius: 50%;
            border: 2px solid #fff;
            background: #E60363D9;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            &:hover {
                cursor: pointer;
                box-shadow: 0px 0px 0px 6px rgba(230,3,99,0.4);
            }
        }

        .infoCard {
            padding: 10px;
            z-index: ${props => props.theme.zIndex.LayerTen};
            position: absolute;
            display: none;
            width: 200px;
            height: auto;
            max-height: 312px;
            background: #000;
            border-radius: 8px;
            overflow-y: scroll;
            &::-webkit-scrollbar-track
            {
                -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
                border-radius: 8px;
                background-color: rgba(0,0,0,0);
                margin:10px;
            }
            &::-webkit-scrollbar
            {
                width: 8px;
                background-color: rgba(0,0,0,0);
            }
            
            &::-webkit-scrollbar-thumb
            {
                border-radius: 8px;
                -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
                background-color: #C4C4C4;
                ;
            }
            @media screen and (max-width: 768px) {
                height:170px;
                overflow: hidden;
                border-radius:10px;
                padding:20px;
            }
            @media screen and (max-width: 414px) {
                height:${props => props.decOver36 ? '170px' : 'auto'};
            }
            .more{
                display:none;
                @media screen and (max-width: 768px) {
                    display:block;
                    width: 100%;
                    color:#fff;
                    font-size:12px;
                    text-align:center;
                    position: absolute;
                    padding-top:5px;
                    padding-bottom:10px;
                    bottom:0;
                    left:0;
                    background: #000;
                }
                @media screen and (max-width: 414px) {
                    padding:10px;
                    text-overflow : ellipsis;
                    display: ${props => props.fullCard || props.decOver36 ? 'block' : 'none'};
                }
                svg{
                    position: relative;
                    top:-2px;
                    font-size:20px;
                }
            }
        }
        .infoCard.full{
            @media screen and (max-width: 768px) {
                width: 100%;
                height: 100%;
                position: fixed;
                left:auto;
                top:auto;
                right:auto;
                bottom:auto;
                max-height:none;
                padding:40px;
                border-radius:0;
                .closeIcon{
                    display:block;
                }
            }
            .more{
                display:none;
            }
        }

        .section1 {
            right: 30px;
            bottom: 0px;
        }

        .section2 {
            left: 30px;
            top: 0px;
        }

        .section3 {
            right: 30px;
            top: 0px;
        }

        .section4 {
            left: 30px;
            bottom: 0px;
        }
        .xSection1 {
            right: 30px;
            bottom: 0px;
        }

        .xSection2 {
            left: 30px;
            bottom: 0px;
        }

        .show {
            display: block;
        }

        .title {
            text-align: left;
            margin-bottom: ${props => props.onlyTitle ? '0px' : '8px'};
            width: 100%;
            min-height: 20px;
            height: auto;
            font-size: 14px;
            word-break: break-all;
        }

        .content {
            text-align: left;
            margin-bottom: ${props => props.onlyTitleDesc ? '0px' : '8px'};
            width: 100%;
            height: auto;
            max-height: 80px;
            font-size: 14px;
            white-space:normal;
            word-break:break-word !important;
            @media screen and (min-width:414px){
                overflow: auto;
            }
            @media screen and (max-width:414px){
                margin-bottom: ${props => props.onlyTitleDesc ? '0px' : '20px'};
                height: auto;
                max-height: 60px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
            }
            &::-webkit-scrollbar-track
            {
                -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
                border-radius: 8px;
                background-color: rgba(0,0,0,0);
            }
            &::-webkit-scrollbar
            {
                width: 8px;
                background-color: rgba(0,0,0,0);
            }
            
            &::-webkit-scrollbar-thumb
            {
                border-radius: 8px;
                -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
                background-color: #C4C4C4;
                ;
            }
        }

        .image {
            margin-bottom: 14px;
            width: 100%;
            height:100px;
            border-radius: 4px;
            background: #1B1B1B;
            @media screen and (max-width:414px){
                display:none;
            }
            img{
                width:100%;
                height:100%;
                object-fit:contain;
            }
        }

        .urlPanel {
            text-align: left;
            width: 100%;
            height: 22px;
            font-size: 12px;
            color:#fff;
            text-decoration:none;
            &:hover{
                text-decoration:underline;
            }

            .urlIcon {
                width: 20px;
                height: 20px;

                path {
                    fill: white;
                }
            }

            .url {
                top: 50%;
                flex: 1;
                width: calc(100% - 20px);
                height: 22px;

                & > span {
                    vertical-align: middle;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }
    .loadingProgressContainer {
        position: fixed;
        width: 100%;
        height: 100%;
        top:56px;
        background: rgba(255, 255, 255, 1);
        z-index: 10;
        // backdrop-filter: blur(40px);
        .progressBarPanel {
            margin-bottom: 24px;
            width: 300px;
            height: 8px;
        }

        .progress {
            height: 8px;
            background: #E0E0E0 !important;
        }

        .progress-bar {
            background: linear-gradient(79.78deg, #ED0973 0%, #FF744E 100%) !important;
            border-top-right-radius:4px;
            border-bottom-right-radius:4px;
        }

        .progressText {
            color: #333333;
            font-size: 20px;
            font-weight: 500;
            line-height: 28px;
            letter-spacing: 0.02em;
            text-align: left;
            margin-bottom:20px;
        }
    }
    .rightRemind{
        position: absolute;
        right: 30px;
        top: 30px;
        z-index: 10;
        li{
            width: 40px;
            height: 40px;
            margin-bottom: 20px;
            cursor: pointer;
            list-style: none;
            line-height: 40px;
            text-align:center;
            position: relative;
            box-shadow: 0px 0px 5px rgb(0 0 0 / 20%);
            border-radius: 8px;
            &.loadingDefault{
                opacity:0.3;
                cursor:default !important;
            }
            .blackRemind{
                display:none;
                align-items:center;
                position: absolute;
                right:45px;
                top:3px;
                @media all and (max-width:1024px) {
                    display:none;
                }
                .tt{
                    background: #000;
                    height:32px;
                    line-height:32px;
                    border-radius:4px;
                    padding:0 7px;
                    color:#fff;
                    font-size:14px;
                    white-space:nowrap;
                }
                .angle{
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 6px 0 6px 14px;
                    border-color: transparent transparent transparent #000000;
                }
            }
            svg{
                    fill:rgba(0,0,0,0.8);
                }
            &:hover{
                background-color: rgba(0,0,0,0.8);
                svg{
                    fill:#fff;
                }
                .blackRemind{
                    display:flex;
                    @media all and (max-width:1024px) {
                        display:none;
                    }
                }
            }
            &:last-child{
                margin-bottom: 0;
            }
            img{
                width: 35px;
            }
            .pop{
                position: absolute;
                width: 260px;
                height: 252px;
                background-color: rgba(0,0,0,0.87);
                padding:12px 24px;
                border-radius: 8px;
                top: 0;
                left: -270px;
                .top{
                    color: rgba(255,255,255,0.87);
                    font-size: 14px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    img{
                        width: 16px;
                        cursor: pointer;
                    }
                }
                table{
                    margin:0 auto;
                    width: 90%;
                    tr{
                        td{
                            font-size:14px;
                            color: #fff;
                            width: 50%;
                            text-align:left;
                            padding: 10px 0;
                            border-bottom: 1px solid rgba(255,255,255,0.12);
                            &:nth-child(2){
                                width: 25%;
                                text-align:right;
                            }
                            &:nth-child(3){
                                width: 25%;
                                text-align:right;
                            }
                        }
                        &:last-child{
                            td{
                                border:none;
                            }
                        }
                    }
                }
            }
        }
        .opa{
            opacity: 1;
        }
    }
    .arIcon{
        width: 40px;
        height: 40px;
        right: 30px;
        top: 140px;
        cursor: pointer;
        list-style: none;
        line-height: 40px;
        text-align:center;
        position: fixed;
        z-index: 2;
        box-shadow: 0px 0px 5px rgb(0 0 0 / 20%);
        border-radius: 8px;
        background-color: #fff;
        &:hover{
            background-color: rgba(0,0,0,0.8);
            svg{
                fill: #fff;
            }
            .blackRemind{
                display:flex;
                @media all and (max-width:1024px) {
                    display:none;
                }
            }
        }
        .blackRemind{
                display:none;
                align-items:center;
                position: absolute;
                right:45px;
                top:3px;
                @media all and (max-width:1024px) {
                    display:none;
                }
                .tt{
                    background: #000;
                    height:32px;
                    line-height:32px;
                    border-radius:4px;
                    padding:0 7px;
                    color:#fff;
                    font-size:14px;
                    white-space:nowrap;
                }
                .angle{
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 6px 0 6px 14px;
                    border-color: transparent transparent transparent #000000;
                }
            }
        svg{
            fill:rgba(0,0,0,0.8);
            width: 42px;
            position: relative;
            top:-2px;
        }
    }
    .smallBarPic{
        z-index: 10;
        width: 98px;
        height: 480px;
        position: absolute;
        left:0;
        top:100px;
        background-color: rgba(0,0,0,0.87);
        border-radius: 0 20px 20px 0;
        overflow-x:hidden;
        overflow-y:scroll;
        &::-webkit-scrollbar-track
        {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
            border-radius: 4px;
            background-color: rgba(0,0,0,0);
            margin:40px 0px;
        }
        &::-webkit-scrollbar
        {
            width: 4px;
            background-color: rgba(0,0,0,0);
        }
        
        &::-webkit-scrollbar-thumb
        {
            border-radius: 4px;
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
            background-color: rgba(255,255,255,0.3);
            ;
        }
        .smallPicHolder{
            width:48px;
            height:48px;
            background: #2f2f2f;
            border-radius: 8px;
        }
        .loadingDefault{
            cursor:default !important;
        }
        ul{
            margin-left: 30px;
            margin-top: 20px;
            margin-right: 0;
            margin-bottom: 0;
            padding: 0;
            li{
                width: 48px;
                height: 48px;
                margin-bottom: 15px;
                cursor: pointer;
                list-style:none;
                img{
                    border:1px solid rgba(255,255,255,0.4);
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center center;
                    border-radius: 4px;
                }
            }
        }
    }
    .animation{
        &_conainer{
            width:100%;
            padding:0 48px;
            position:absolute;
            bottom:0px;
            left:50%;
            transform:translateX(-50%);
            margin-bottom:20px;
            z-index:100;
        }
        &_bar{
            width: 100%;
            height: 32px;
            &_tooltip{
                word-break: break-all;
                word-wrap:break-word;
                min-width: 80px;
                text-align:center;
                left: 360px;
                top:-50px;
                border-radius: 4px;
                position: absolute;
                background: rgba(0, 0, 0, 0.87);
                font-weight: 500;
                font-size: 14px;
                line-height: 140%;
                letter-spacing: 0.02em;
                color: #FFFFFF;
                padding:10px;
                &:before{
                    content:'';
                    position: absolute;
                    width: 0;
                    height: 0;
                    left: 50%;
                    top:30px ;
                    transform:translateX(-50%) rotate(-90deg);
                    border-style: solid;
                    border-width: 15px 12px 15px 0;
                    border-color: transparent #000000DE transparent transparent;
                }
            }
            input[type="range"] {
                cursor:pointer;
                -webkit-appearance: none;
                width: 100%;
                height: 4px;
                background: rgba(0, 0, 0, 0.12);
                border-radius: 4px;
                background-image:linear-gradient(79.78deg, #ED0973 0%, #FF744E 100%);
                // background-size: 70% 100%;
                background-repeat: no-repeat;
            }
              /* Input Thumb */
            input[type="range"]::-webkit-slider-thumb {
                -webkit-appearance: none;
                height: 20px;
                width: 20px;
                border-radius: 50%;
                background: #fff;
                box-shadow: 0 0 2px 0 #555;
                cursor:pointer;
                transition: background .3s ease-in-out;
            }
            
            input[type="range"]::-moz-range-thumb {
                -webkit-appearance: none;
                height: 20px;
                width: 20px;
                border-radius: 50%;
                background: #fff;
                box-shadow: 0 0 2px 0 #555;
                transition: background .3s ease-in-out;
                }
            input[type="range"]::-ms-thumb {
                -webkit-appearance: none;
                height: 20px;
                width: 20px;
                border-radius: 50%;
                background: #fff;
                box-shadow: 0 0 2px 0 #555;
                transition: background .3s ease-in-out;
            }
        }
        &_control{
            display:flex;
            align-items:center;
            &_icon_box{
                margin-right:42px;
                cursor:pointer;
                &:hover{
                    background: rgba(0, 0, 0, 0.87);
                    border-radius: 8px;
                    .icon{
                        background-color: #fff;
                    }
                }
                .icon{
                    width:48px;
                    height:48px;
                    display:flex;
                    justify-content:center;
                    align-items:center;
                    background-color: #000;
                    -webkit-mask:${props => props.animatePlayStatus ? `url(${pauseIcon}) no-repeat center` : `url(${playIcon}) no-repeat center`};
                    mask: ${props => props.animatePlayStatus ? `url(${pauseIcon}) no-repeat center` : `url(${playIcon}) no-repeat center`};
                }
            }
            &_chose{
                display:flex;
                justify-content:center;
                align-items:center;
                padding:10px 24px;
                border-radius: 4px;
                margin-right:24px;
                border: 1px solid rgba(0, 0, 0, 0);
                background:${props=>props.showAnimateHidden ? 'rgba(0, 0, 0, 0.87)' : ''} ;
                &:hover{
                    background: rgba(0, 0, 0, 0.87);
                    border: 1px solid rgba(0, 0, 0, 0.87);
                    cursor:pointer;
                    .animation_control_chose_name{
                        color:#fff;
                    }
                    .animation_control_chose_icon{
                        background-color: #fff;
                    }
                }
                &_icon{
                    width:24px;
                    height:24px;
                    margin-right:8px;
                    background-color:${props=>props.showAnimateHidden ? '#fff' : '#000'} ;
                    -webkit-mask: url(${namIcon}) no-repeat center;
                    mask: url(${namIcon}) no-repeat center;
                }
                &_name{
                    font-weight: 900;
                    font-size: 16px;
                    line-height: 24px;
                    color:${props=>props.showAnimateHidden ? '#fff' : '#333333'} ;
                    width: 120px;
                    overflow:hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
            &_duration{
                font-weight: 700;
                font-size: 16px;
                line-height: 140%;
                color: #666666;
            }
        }
        &_hidden_list{
            position: absolute;
            left: 137px;
            bottom: 55px;
            background: rgba(0, 0, 0, 0.87);
            border: 1px solid rgba(255, 255, 255, 0.12);
            border-radius: 4px;
            padding:15px;
            width:204px;
            max-height:215px;
            overflow-y: scroll;
            overflow-x: hidden;
            &::-webkit-scrollbar-track
            {
                -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
                border-radius: 4px;
                background-color: rgba(0,0,0,0);
                margin: 0px;
            }
            &::-webkit-scrollbar
            {
                width: 4px;
                background-color: rgba(0,0,0,0);
            }
    
            &::-webkit-scrollbar-thumb
            {
                border-radius: 4px;
                -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
                background-color: rgba(255,255,255,0.3);
                ;
            }
            &_item{
                display: flex;
                align-items:center;
                width: 175px;
                height: 36px;
                cursor:pointer;
                &.currentPlay{
                    .name{
                        color: rgba(255, 255, 255, 1);
                    }
                }
                .check{
                    width: 24px;
                    height: 24px;
                    margin-right:5px;
                }
                .name{
                    flex-grow: 1;
                    max-width:145px;
                    color: rgba(255, 255, 255, 0.5);
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 140%;
                    overflow:hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    &:hover{
                        color:#fff;
                    }
                }
            }
            &_tooltip{
                word-break: break-all;
                word-wrap:break-word;
                width: 130px;
                left: 360px;
                border-radius: 4px;
                position: fixed;
                background: rgba(0, 0, 0, 0.87);
                font-weight: 500;
                font-size: 14px;
                line-height: 140%;
                letter-spacing: 0.02em;
                color: #FFFFFF;
                padding:10px;
                visibility:${props=>props.showTootltip ? 'visible' : 'hidden'};
                transform:translateY(-50%);
                z-index:999999999;
                &:before{
                    content:'';
                    position: absolute;
                    width: 0;
                    height: 0;
                    left: -10px;
                    top: 50%;
                    transform:translateY(-50%);
                    border-style: solid;
                    border-width: 10px 14px 10px 0;
                    border-color: transparent #000000DE transparent transparent;
                }
            }
        }
    }
    .choseOpenMode{
        position:absolute;
        bottom:${props=>props.isOpenTag ? '56px' : '106px'};
        right:48px;
        background:rgba(0,0,0,0.87);
        color:#fff;
        padding:8px 24px;
        border-radius:4px;
        cursor:pointer;
        .icon{
            display:flex;
            justify-content:center;
            align-items:center;
            img{
                width:35px;
                height:35px;
            }
        }
    }
`;