// react
import React, { useState, useEffect, useRef } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';

// redux types
import { GET_MODEL_LIST } from 'redux/types/model.types';

// styled components
import { StyledNavBottom } from './StyledNavBottom';

//components
import ModalPopup from 'components/ModalPopup/ModalPopup';

// hooks
import useModal from 'hooks/useModal';

// config
import { modalConfig } from 'config/modal';

// react icons
import { RiArrowDownSFill, RiFileCopy2Line, RiDeleteBin6Fill } from 'react-icons/ri';
import { IoMdSettings } from 'react-icons/io';
import { BsCheck, BsArrowClockwise, BsThreeDots, BsPencilFill, BsPlus } from 'react-icons/bs';
import boxes from './images/boxes.svg';
import AiFillFolder from './images/interface.svg';
import brand from './images/brand.svg';
import view from './images/view.svg';

//api
import { otherModel } from 'api/otherModel/otherModel.api';
import { deleteModel } from 'api/deleteModel/deleteModel.api';
import { mainObject } from 'api/mainObject/mainObject.api';
import { projectPicture } from 'api/projectPicture/projectPicture.api';

//plugin
import Cookies from 'js-cookie';
import clsx from 'classnames';

//i18n
import { useTranslation } from 'react-i18next';
import { glbModelUpload } from 'api/model/model.api';
import { deleteAllTag } from 'api/objectTag/tag.api';
import Loading from '../../../components/Loading/Loading';


const NavBottom = () => {

    const store = useSelector(store => store);
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const [modelList, setModelList] = useState([]);
    const { modalSetting, setModalSetting } = useModal();
    const [modelListOverLimit, setModelListOverLimit] = useState(false);
    const [modelViewerLoadingStatus, setModelViewerLoadingStatus] = useState(true);
    const fileInputRef = useRef(null);
    const [reUploadModelId, setReUploadModelId] = useState(null);
    const [isUploading, setIsUploading] = useState(false);

    useEffect(() => {
        setModelList(store.model?.modelList);
        // 檢查物件數是否達上限（10個）
        if (store.model?.modelList.length >= 10) {
            setModelListOverLimit(true);
        } else {
            setModelListOverLimit(false);
        }
    }, [store.model?.modelList]);

    useEffect(() => {
        setModelViewerLoadingStatus(store.model?.isLoading);
    });

    const [modelName, setModelName] = useState('');

    useEffect(() => {
        let checkModelId = false;
        for (let item of modelList) {
            if (item.id === parseFloat(Cookies.get('modelId'))) {
                checkModelId = true;
            }
        }
        if (!checkModelId) {
            return;
        }
        if (modelList.find(model => model.id === parseFloat(Cookies.get('modelId'))) !== '') {
            if (modelList.find(model => model.id === parseFloat(Cookies.get('modelId')))?.lastUploadFileType === 'gltf') {
                setModelName(modelList.find(model => model.id === parseFloat(Cookies.get('modelId')))?.gltfName);
            } else {
                setModelName(modelList.find(model => model.id === parseFloat(Cookies.get('modelId')))?.glbName);
            }

        }
    }, [modelList]);

    const uploadModelEvent = (e) => {
        e.stopPropagation();
        otherModel({}, Cookies.get('projectId')).then(res => {
            const hostname = window.location.hostname;
            const encodeToken = encodeURIComponent(Cookies.get('token'));
            const encodeProjectId = encodeURIComponent(Cookies.get('projectId'));
            const encodeModelsId = encodeURIComponent(res.modelId);
            const encodePublish = encodeURIComponent(Cookies.get('isPublish'));
            const lang = Cookies.get('lang');

            if (hostname === 'localhost') {
                window.open(`http://localhost:3022/upload?token=${encodeToken}&projectId=${encodeProjectId}&modelId=${encodeModelsId}&isPublish=${encodePublish}&lang=${lang}&useMaterialLab=true`, '_self', 'noopener, noreferrer');
            }

            if (hostname === 'areditor-dev.istaging.com') {
                window.open(`https://armaker-dev.istaging.com/upload?token=${encodeToken}&projectId=${encodeProjectId}&modelId=${encodeModelsId}&isPublish=${encodePublish}&lang=${lang}&useMaterialLab=true`, '_self', 'noopener, noreferrer');
            }

            if (hostname === 'areditor.istaging.com') {
                window.open(`https://armaker.istaging.com/upload?token=${encodeToken}&projectId=${encodeProjectId}&modelId=${encodeModelsId}&isPublish=${encodePublish}&lang=${lang}&useMaterialLab=true`, '_self', 'noopener, noreferrer');
            }
        });
    };

    const deleteProjectEvent = (modelId, name) => (e) => {
        e.stopPropagation();
        setModalSetting({
            ...modalSetting,
            show: true,
            type: 'type20',
            title: name,
            handleConfirm: () => {
                deleteModel(Cookies.get('token'), Cookies.get('projectId'), modelId).then(res => {
                    let afterDeleteList = store.model.modelList.slice(0);
                    for (let i = 0; i < afterDeleteList.length; i++) {
                        if (afterDeleteList[i].id == modelId) {
                            afterDeleteList.splice(i, 1);
                        }
                    }
                    dispatch({ type: GET_MODEL_LIST, payload: afterDeleteList });
                    // const hostname = window.location.hostname;
                    // const encodeToken = Cookies.get('token');
                    // const encodeProjectId = Cookies.get('projectId');
                    // const encodeModelsId = modelList.find(model => model.mainObject === true).id;
                    // const encodePublish = Cookies.get('isPublish');

                    // if (modelId === parseFloat(Cookies.get('modelId'))) {

                    //     if (window.location.hostname.indexOf('localhost') > -1) {
                    //         return window.open(`http://${window.location.hostname}:3020/?projectId=${encodeProjectId}&modelsId=${encodeModelsId}&token=${encodeToken}&isPublish=${encodePublish}`, '_self');
                    //     }

                    //     if (window.location.hostname.indexOf('-dev') > -1) {
                    //         return window.open(`https://areditor-dev.istaging.com/?projectId=${encodeProjectId}&modelsId=${encodeModelsId}&token=${encodeToken}&isPublish=${encodePublish}`, '_self');
                    //     }

                    //     if (window.location.hostname.indexOf('armaker.istaging.com') > -1) {
                    //         return window.open(`https://areditor.istaging.com/?projectId=${encodeProjectId}&modelsId=${encodeModelsId}&token=${encodeToken}&isPublish=${encodePublish}`, '_self');
                    //     }

                    // } else {
                    //     window.location.reload();
                    // }
                });

            },
        });
    };

    const triggerFileInputClick = (modelId) => {
        setReUploadModelId(modelId);
        fileInputRef?.current.click();
    };

    const mainObjectEvent = (modelId) => (e) => {
        e.stopPropagation();
        mainObject(Cookies.get('token'), Cookies.get('projectId'), modelId).then(res => {
            let afterSetMainList = store.model.modelList.slice(0);
            for (let i = 0; i < afterSetMainList.length; i++) {
                afterSetMainList[i].mainObject = false;
                if (afterSetMainList[i].id == modelId) {
                    afterSetMainList[i].mainObject = true;
                }
            }
            dispatch({ type: GET_MODEL_LIST, payload: afterSetMainList });
            // window.location.reload();
            // const modlePic = store.model.modelList.filter(item => item.id === modelId)[0];
            // const myEditor = document.querySelector('#myEditor');
            // let modelSrc = '';
            // let modelOldSrc = myEditor.getAttribute('src');
            // if (modlePic.lastUploadFileType === 'gltf') {
            //     modelSrc = modlePic.gltfSrc;
            // } else {
            //     modelSrc = modlePic.glbSrc;
            // }
            // myEditor.setAttribute('src', modelSrc);
            // myEditor.removeAttribute('environment-image');
            // myEditor.removeAttribute('shadow-intensity');
            // myEditor.removeAttribute('shadow-softness');
            // myEditor.removeAttribute('exposure');
            // if (modelOldSrc === modelSrc) {
            //     (async function () {
            //         let formData = new FormData();
            //         const myFile = await myEditor.toBlob();
            //         formData.append('file', myFile);
            //         formData.fileType = 'image/png';
            //         projectPicture(formData, Cookies.get('projectId'), Cookies.get('token')).then(res => {
            //             window.location.reload();
            //         });
            //     })();
            // }
            // myEditor.addEventListener('progress', async (e) => {
            //     const getProgress = e.detail.totalProgress;
            //     if (getProgress === 1) {
            //         let formData = new FormData();
            //         const myFile = await myEditor.toBlob();
            //         formData.append('file', myFile);
            //         formData.fileType = 'image/png';
            //         projectPicture(formData, Cookies.get('projectId'), Cookies.get('token')).then(res => {
            //             window.location.reload();
            //         });
            //     }
            // });

            // const modlePic = projectPic.filter(item.id === modelId)[0];
            // console.log(modlePic);
            // setPicModelUrl('');
            // window.location.reload();
        });
    };

    const redirectModel = (modelId) => {
        const encodeToken = encodeURIComponent(Cookies.get('token'));
        const encodeProjectId = Cookies.get('projectId');
        const encodePublish = Cookies.get('isPublish');

        const params = {
            projectId: encodeProjectId,
            modelsId: modelId,
            token: encodeToken,
            isPublish: encodePublish,
        };
        const url = new URL('/', window.location.origin);
        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
        return window.open(url.toString(), '_self');
    };

    const changeModelEvent = (modelId) => () => {
        if (modelId !== parseFloat(Cookies.get('modelId'))) {
            redirectModel(modelId);
        }
    };

    const handleFileChange = async (event, modelId) => {
        const file = event.target.files[0];
        if (file) {
            setIsUploading(true);
            const projectId = Cookies.get('projectId');
            let formData = new FormData();
            formData.append('file', file);
            try {
                await glbModelUpload(formData, projectId, modelId);
                await deleteAllTag(projectId, modelId);
                redirectModel(modelId);
            } catch (error) {
                console.log('The file update failed because ', error);
                alert('The file update failed!');
            } finally {
                setIsUploading(false);
            }
        }
    };

    const [dropdown, setDropdown] = useState(null);
    const [ctrlBtn, setCtrlBtn] = useState(null);
    const [isShowList, setShow] = useState(false);

    const handleDropdown = (e, id) => {
        e.stopPropagation();

        if (dropdown === id) {
            setDropdown(null);
        }
        if (dropdown !== id) {
            setDropdown(id);
        }
    };

    const showAllModelList = (e) => {
        e.stopPropagation();
        if (store.model.isLoading) return;
        if (dropdown !== null) {
            handleDropdown(e, null);
            setCtrlBtn(null);
        }
        setShow(!isShowList);
    };

    const showCtrlBtn = (id) => {
        if (dropdown !== null) return;

        if (ctrlBtn === id) {
            setCtrlBtn(null);
        }
        if (ctrlBtn !== id) {
            setCtrlBtn(id);
        }
    };

    const hideCtrlBtn = () => {
        if (dropdown !== null) return;
        setCtrlBtn(null);
    };

    const handleCloseDropdown = (e) => {
        e.stopPropagation();

        if (dropdown !== null) {
            handleDropdown(e, null);
            setCtrlBtn(null);
        }
    };

    useEffect(() => {
        const handleClick = e => {
            if (dropdown !== null) {
                handleDropdown(e, null);
                setCtrlBtn(null);
            } else {
                setShow(false);
            }
        };
        document.addEventListener('click', handleClick);
        return () => document.removeEventListener('click', handleClick);
    }, [handleDropdown]);

    const [scrollTop, setScrollTop] = useState(210);
    const modelListMain = useRef();
    let documentTop = 210;

    useEffect(() => {
        modelListMain.current.addEventListener('scroll', () => {
            setScrollTop(documentTop - modelListMain.current.scrollTop);
        });
    }, []);

    return (
        <StyledNavBottom className="flexCenter">
            { isUploading && <Loading /> }
            {modalSetting.show && (
                <ModalPopup
                    modalConfig={modalConfig[modalSetting.type]}
                    setModalSetting={setModalSetting}
                    modalSetting={modalSetting}
                />
            )}
            <div onClick={showAllModelList}
                className={clsx('objectSelectorPanel', 'flexStartCenter', { 'loadingDefault': store.model.isLoading })}>
                <img src={boxes} alt="" draggable="false"/>
                <span>
                    {
                        modelName
                    }
                </span>
                <div
                    className={modelList.find(model => model.id === parseFloat(Cookies.get('modelId')))?.mainObject === true ? 'objectTag' : 'objectTag objectTagNone'}>
                    {t('mainObject')}
                </div>
                <RiArrowDownSFill/>
                <input
                    type="file"
                    ref={ fileInputRef }
                    style={{ display: 'none' }}
                    accept=".glb,.gltf,.obj,.babylon"
                    onChange={(event) => handleFileChange(event, reUploadModelId)}
                />
                <div className="popModelList" onClick={handleCloseDropdown}
                    style={{ display: isShowList ? 'block' : 'none' }}>
                    <div className="angle"></div>
                    <div className="title">
                        {t('objects')}
                    </div>
                    <ul className="block" ref={modelListMain}>
                        {
                            modelList.map((item, index) => {
                                return (
                                    <li key={item.id} onClick={changeModelEvent(item.id)}
                                        onMouseEnter={() => showCtrlBtn(item.id)} onMouseLeave={hideCtrlBtn}>
                                        <div>
                                            <div className="icon">
                                                {
                                                    parseInt(Cookies.get('modelId')) === item.id ? <BsCheck/> : null
                                                }
                                            </div>
                                            <div className="name">
                                                {
                                                    item.lastUploadFileType === 'gltf' ? item.gltfName : item.glbName
                                                }
                                            </div>
                                        </div>
                                        <div onClick={(e) => e.stopPropagation()}>
                                            <div className={item.mainObject ? 'isMaster' : 'isMaster isMasterNone'}>
                                                {
                                                    item.mainObject ? t('main') : ''
                                                }
                                            </div>
                                            <div className="point">
                                                <BsThreeDots className='pointIcon'
                                                    onClick={(e) => handleDropdown(e, index)}
                                                    style={{ opacity: ctrlBtn === item.id ? 100 : 0 }}/>
                                                {dropdown === index && (
                                                    <ul className="ctrlModel" style={{ top: scrollTop + index * 48 }}>
                                                        <li onClick={mainObjectEvent(item.id)}
                                                            className={item.mainObject ? 'notClick' : ''}>
                                                            <div className="ii">
                                                                <IoMdSettings/>
                                                            </div>
                                                            {t('setAsMainObject')}
                                                        </li>
                                                        <li onClick={(e) => {
                                                            e.stopPropagation();
                                                            triggerFileInputClick(item.id);
                                                        }}>
                                                            <div className="ii">
                                                                <BsArrowClockwise/>
                                                            </div>
                                                            {t('reUpload')}
                                                        </li>

                                                        {/*
                                                        <li>
                                                            <div className='ii'>
                                                                <RiFileCopy2Line />
                                                            </div>
                                                            複製物件
                                                        </li>
                                                        <li>
                                                            <div className="ii"><BsPencilFill /></div>
                                                            重新命名</li> */}
                                                        <li className={item.mainObject || Cookies.get('modelId') == item.id ? 'notClick' : ''}
                                                            onClick={deleteProjectEvent(item.id, item.lastUploadFileType === 'gltf' ? item.gltfName : item.lastUploadFileType === 'glb' ? item.glbName : '')}>
                                                            <div className="ii"><RiDeleteBin6Fill/></div>
                                                            {t('delete')}
                                                        </li>
                                                    </ul>
                                                )}
                                            </div>
                                        </div>
                                    </li>
                                );
                            })
                        }
                        {
                            modelListOverLimit &&
                            <div className='limitation'>
                                <div className='highlightWord'>{t('navBottom.objectOverLimit')}</div>
                                {t('navBottom.maximumNumber')}
                            </div>
                        }
                    </ul>
                    <div className='btnCon'>
                        <button onClick={uploadModelEvent}
                            className={`${modelListOverLimit ? 'disableBtn' : 'uploadModel'}`}
                            disabled={modelListOverLimit}>
                            <BsPlus/>
                            {t('uploadSubobject')}
                        </button>
                    </div>
                </div>
            </div>

            <div className="modelViewerActionPanel flexBetweenCenter">
                <div className="leftPanel">
                    <img src={AiFillFolder} style={{ display: 'none' }}/>
                    <img src={brand} style={{ display: 'none' }}/>
                </div>
                <div className="rightPanel">
                    <img src={view} style={{ display: 'none' }} />
                </div>
            </div>
        </StyledNavBottom>
    );
};

export default NavBottom;
