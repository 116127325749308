// import React from 'react';

// styled components
import { StyledLoading } from './StyledLoading';

//i18n
import { useTranslation } from 'react-i18next';


const Loading = ()=>{

    const { t } = useTranslation();

    return (
        <StyledLoading>
            <div className="whiteBg">
                <div className="loading">
                    <div id="loading-wrapper">
                        <div id="loading-content"></div>
                    </div>
                </div>
                <div className="title">
                    {t('savingModelProject')}
                </div>
                <div className="txt">
                    {t('pleaseWait')}
                </div>
            </div>
        </StyledLoading>
    );
};

export default Loading;