import styled from 'styled-components';

export const StyledLoadingIcon = styled.div`
    .loadingIcon {
        font-size: 6rem;
        animation: loadingIcon 0.45s infinite linear;
    }

    @keyframes loadingIcon {
        0% {
            transform: translateY(0px) scale(1);
        }
        100% {
            transform: translateY(80px) scale(1.15);
        }
    }
`;