import styled from 'styled-components';

export const StyledNav = styled.div`
    position: relative;
    width: 100%;
    background-color: #fff;
    z-index: 100000;
    grid-columns: 1 / 3;
    grid-rows: 1 / 2;
    .masker {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: ${props => props.theme.zIndex.LayerTen};
        background-color: rgba(0,0,0,0);
    }
`;