// react
import React, { useState, useEffect } from 'react';

// redux
import { useSelector, useDispatch } from 'react-redux';

//images
import check from './images/check.svg';


// redux types
import { ANIMATE_LIST, MAIN_ANIMATE_SWITCH, CURRENT_ANIMATE, ANIMATE_PLAY_STATUS } from 'redux/types/animation.types';
import { IS_SAVE } from 'redux/types/isSave.types';

//styled components
import { StyledObjectAnimation } from './StyledObjectAnimation';

// components
import Switch from '../Switch/Switch';

//react icon
// import { FiRefreshCcw } from 'react-icons/fi';


//redux
// import { SHADOW_CARD } from 'redux/types/shadowCard.types';

// //api
// import { modelLight } from 'api/modelLight/modelLight.api';

// // plugin
import Cookies from 'js-cookie';
import clsx from 'classnames';

//i18n
import { useTranslation } from 'react-i18next';
const ObjectAnimation = () => {
    const modelId = parseInt(Cookies.get('modelId'));
    const store = useSelector(store => store);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [showTootltip, setShowTootltip] = useState(null);
    const [tootltipItemName, setTootltipItemName] = useState(null);
    const [tootltipItemTop, setTootltipItemTop] = useState(0);

    const handleMainAnimateSwitch = () => {
        const getModelViewer = document.querySelector('#myEditor');
        if (store.animation.mainAnimateSwitch) {
            getModelViewer.setAttribute('animation-name', '');
            getModelViewer.pause();
            dispatch({ type: ANIMATE_PLAY_STATUS, payload: false });
        } else {
            getModelViewer.setAttribute('animation-name', store.animation.currentAnimate.name);
            getModelViewer.play();
            dispatch({ type: ANIMATE_PLAY_STATUS, payload: true });
        }
        getModelViewer.currentTime = 0;
        dispatch({ type: MAIN_ANIMATE_SWITCH, payload: !store.animation.mainAnimateSwitch });
        dispatch({ type: IS_SAVE, payload: true });
    };

    const handleChangeAnimate = async (item) => {
        const getModelViewer = document.querySelector('#myEditor');
        if (!item.ownSwitch) {
            return;
        } else {
            getModelViewer.pause();
            await getModelViewer.setAttribute('animation-name', item.name);
            dispatch({ type: CURRENT_ANIMATE, payload: item });
            if (store.animation.animatePlayStatus) {
                getModelViewer.play();
            }
        }
    };

    const handleOwnAnimateSwitch = (idx) => {
        let newAnimateList = store.animation.animateList.slice();
        let countClose = 0;
        newAnimateList.forEach((item, index) => {
            if (idx == index) {
                if (item.disable) {
                    return;
                }
                if (item.name === store.animation.currentAnimate.name) {
                    let itemSwitchIsOpen = newAnimateList.filter((item) => {
                        return item.ownSwitch;
                    });
                    if (itemSwitchIsOpen[itemSwitchIsOpen.indexOf(item) + 1]) {
                        handleChangeAnimate(itemSwitchIsOpen[itemSwitchIsOpen.indexOf(item) + 1]);
                        dispatch({ type: CURRENT_ANIMATE, payload: itemSwitchIsOpen[itemSwitchIsOpen.indexOf(item) + 1] });
                    } else {
                        handleChangeAnimate(itemSwitchIsOpen[0]);
                        dispatch({ type: CURRENT_ANIMATE, payload: itemSwitchIsOpen[0] });
                    }
                }
                item.ownSwitch = !item.ownSwitch;
            }
            if (!item.ownSwitch) {
                countClose++;
            }
        });
        newAnimateList.forEach((item) => {
            // 當打開的數量不唯一的時候
            if (countClose > 0) {
                item.disable = false;
            }
            // 當剩餘一個時，最後一個不可關閉
            if (countClose === newAnimateList.length - 1) {
                if (item.ownSwitch) {
                    item.disable = true;
                }
            }
        });
        dispatch({ type: ANIMATE_LIST, payload: newAnimateList });
        dispatch({ type: IS_SAVE, payload: true });
    };

    const handleHoverTooltips = (name, e) => {
        var regExp = /^[\d|a-zA-Z]+$/;
        if (name.length > 19 && regExp.test(name)) {
            setShowTootltip(true);
            setTootltipItemName(name);
            let Y = e.target.getBoundingClientRect().top - 127;
            setTootltipItemTop(Y);
        } else if (name.length > 10 && !regExp.test(name)) {
            setShowTootltip(true);
            setTootltipItemName(name);
            let Y = e.target.getBoundingClientRect().top - 127;
            setTootltipItemTop(Y);
        }
    };

    return (
        <StyledObjectAnimation>
            <div className="switch_setting">
                <div className="name">{t('objectAnimation')}</div>
                {
                    store.animation.animateList.length > 0 && <div className="switch" onClick={handleMainAnimateSwitch}>
                        <Switch turn={store.animation.mainAnimateSwitch} />
                    </div>
                }
                {
                    store.animation.animateList.length == 0 &&
                    <div className="switch disable">
                        <Switch turn={true} />
                    </div>
                }
            </div>
            {
                store.animation.animateList.length == 0 &&
                <div className="noAnime">
                    此物件尚未有任何動畫
                </div>
            }
            {
                store.animation.animateList.length > 0 && <div className={clsx('animate_list', { 'disable': !store.animation.mainAnimateSwitch })}>
                    <div className="mask"></div>
                    {
                        store.animation.animateList.length > 0 &&
                        store.animation.animateList.map((item, idx) => {
                            return (<div
                                className={
                                    clsx('animate_item', {
                                        'currentPlay': item.name === store.animation.currentAnimate.name,
                                        'closeOwnSwitch': !item.ownSwitch,
                                    })}
                                key={item.name} >
                                <div className="checkIcon">
                                    {
                                        store.animation.currentAnimate.name === item.name && <div className="icon"></div>
                                    }
                                </div>
                                <div className="animate_item_name" onClick={() => { handleChangeAnimate(item); }} onMouseEnter={(e) => { handleHoverTooltips(item.name, e); }} onMouseLeave={() => setShowTootltip(false)}>{item.name}</div>
                                <div className="animate_item_duration">{item.duration}</div>
                                <div
                                    className={clsx('animate_item_switch', { 'disable': item.disable })} onClick={() => handleOwnAnimateSwitch(idx)}>
                                    <Switch turn={item.ownSwitch} />
                                </div>
                            </div>);
                        })
                    }
                </div>}
            {showTootltip && <div className="animate_tooltip" style={{ top: `${tootltipItemTop}px` }}>
                {tootltipItemName}
            </div>}
        </StyledObjectAnimation>
    );
};

export default ObjectAnimation;