// react
import React, { useReducer, useEffect, useState } from 'react';

// redux
import { useSelector, useDispatch } from 'react-redux';

// redux types
import {
    NEW_TAG_INPUT,
    NEW_TAG_CONTENT,
    NEW_TAG_URL,
    NEW_TAG_IMAGE_FILE,
    OLD_TAG_TITLE,
    OLD_TAG_CONTENT,
    OLD_TAG_URL,
    OLD_TAG_IMAGE_FILE,
    OLD_TAG_DELETE_IMAGE_FILE,
    CLEAR_NEW_TAG_PANEL,
    CLEAR_OLD_TAG_PANEL,
    OLD_TAG_PANEL,
    REMOVE_EDITING_TAG,
} from 'redux/types/tag.types';

import {
    TITLE_LENGTH_ERROR,
    DESC_LENGTH_ERROR,
} from 'redux/types/editPanel.types';

// styled components
import { StyledEditPanel } from './StyledEditPanel';

// api
import {
    postCreateTag,
    postEditTag,
    postUploadTagImage,
} from 'api/objectTag/tag.api';

// hook form
import { useForm, useController } from 'react-hook-form';

// react icons
import { AiOutlineCloudUpload } from 'react-icons/ai';

// icons
import { ReactComponent as DeleteIcon } from 'assets/icons/delete-icon.svg';
import { ReactComponent as UpdateIcon } from 'assets/icons/update-icon.svg';
import { ReactComponent as ExclamationIcon } from 'assets/icons/exclamation.svg';

// form validation
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// utils
import { isImage, checkFileSize } from 'utils/file';

// plugins
import clsx from 'classnames';
import Cookies from 'js-cookie';

//i18n
import { useTranslation } from 'react-i18next';

const schema = yup.object({
    titleInput: yup.string().max(30),
});

const initState = {
    titleCharNumber: 0,
    textareaCharNumber: 0,
    titleInput: '',
    textareaInput: '',
    urlInput: '',
    fileImage64: '',
    tagId: '',
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'TAG_ID':
            return {
                ...state,
                tagId: action.payload,
            };
        case 'TITLE_INPUT':
            return {
                ...state,
                titleInput: action.payload,
            };
        case 'TITLE_CHAR_NUMBER':
            return {
                ...state,
                titleCharNumber: action.payload,
            };
        case 'TEXTAREA_INPUT':
            return {
                ...state,
                textareaInput: action.payload,
            };
        case 'TEXTAREA_CHAR_NUMBER':
            return {
                ...state,
                textareaCharNumber: action.payload,
            };
        case 'FILE_IMAGE_64':
            return {
                ...state,
                fileImage64: action.payload,
            };
        case 'URL_INPUT':
            return {
                ...state,
                urlInput: action.payload,
            };

        default:
            return { ...state };
    }
};

const EditPanel = ({
    orderNumber,
    tag,
    projectId,
    modelId,
    refetchModelList,
    handleDeleteHotspot,
    sectionType,
    setModalSetting,
    modalSetting,
    handleEdited,
}) => {
    const store = useSelector(store => store);
    const reduxDispatch = useDispatch();
    const [variables, dispatch] = useReducer(reducer, initState);
    const inputImageName = `tagImage-${orderNumber}`;
    const targetHotspotId = `hotspot-${orderNumber - 1}`;
    const { register, handleSubmit, formState: { errors }, control } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            titleInput: tag?.title || '',
            textareaInput: tag?.content || '',
            urlInput: tag?.url || '',
            [inputImageName]: tag?.icon || '',
        },
    });
    const { field } = useController({ control, name: inputImageName });
    const [isLoading, setIsLoading] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [isReaderLoading, setIsReaderLoading] = useState(null);
    const [imageName, setImageName] = useState(null);
    const { t } = useTranslation();
    const [modelName, setModelName] = useState('');

    useEffect(() => {
        if (store.model?.modelList.find(model => model.id === parseFloat(Cookies.get('modelId'))) !== '') {
            if (store.model?.modelList.find(model => model.id === parseFloat(Cookies.get('modelId')))?.lastUploadFileType === 'gltf') {
                setModelName(store.model?.modelList.find(model => model.id === parseFloat(Cookies.get('modelId')))?.gltfName);
            } else {
                setModelName(store.model?.modelList.find(model => model.id === parseFloat(Cookies.get('modelId')))?.glbName);
            }

        }
    }, [store.model?.modelList]);

    useEffect(() => {
        // didMountHotspot to apply hotspots getting from api
        if (tag) {
            dispatch({ type: 'TAG_ID', payload: tag.id });
            dispatch({ type: 'TITLE_INPUT', payload: tag.title });
            dispatch({ type: 'TITLE_CHAR_NUMBER', payload: tag.title.length });
            dispatch({ type: 'TEXTAREA_INPUT', payload: tag.content });
            dispatch({ type: 'TEXTAREA_CHAR_NUMBER', payload: tag.content.length });
            dispatch({ type: 'URL_INPUT', payload: tag.url });
            dispatch({ type: 'FILE_IMAGE_64', payload: tag.icon });
            setIsReaderLoading(100);
        }

    }, []);

    const handleErrorResponse = (modalType) => {
        setHasError(true);
        setModalSetting({
            ...modalSetting,
            show: true,
            title: '',
            type: modalType,
            handleConfirm: () => { },
        });
    };

    const handleInputChange = (e) => {
        const getInputName = e.target.name;
        const getInputValue = e.target.value;
        const getInputLength = e.target.value.length;
        const findNewTag = store.tagPanel.newTags.find(tag => tag.orderNumber === `${orderNumber}`);
        handleEdited();
        if (getInputName.includes('tagImage')) {
            // NEW_TAG_IMAGE_FILE
            const curFile = e.target.files?.[0]; // 透過 input 取得的 file object
            // image file type validation
            if (!isImage(curFile.name)) {
                setModalSetting({
                    ...modalSetting,
                    show: true,
                    title: tag?.title,
                    type: 'type2',
                    handleConfirm: () => { },
                });
                return;
            }

            // image file size validation
            if (!checkFileSize(curFile.size)) {
                setModalSetting({
                    ...modalSetting,
                    show: true,
                    title: tag?.title,
                    type: 'type3',
                    handleConfirm: () => { },
                });
                return;
            }
            setIsReaderLoading(0);
            const reader = new FileReader();
            reader.onload = function (e) {
                dispatch({ type: 'FILE_IMAGE_64', payload: e.target.result });
                // 問題好像在這
                // dispatch({ type: 'FILE_IMAGE_64', payload: e.target.result });
                // into redux
                // const findNewTag = store.tagPanel.newTags.find(tag => tag.orderNumber === `${orderNumber}`);
                if (findNewTag) {
                    setImageName(curFile.name);
                    findNewTag.imageFile = {
                        filename: curFile.name,
                        image64: e.target.result,
                    };
                    reduxDispatch({ type: NEW_TAG_IMAGE_FILE, payload: findNewTag });
                }

                if (!findNewTag) {
                    setImageName(curFile.name);
                    const oldPayload = {
                        tagId: tag.id,
                        inputValue: e.target.result,
                        filename: curFile.name,
                    };
                    reduxDispatch({ type: OLD_TAG_IMAGE_FILE, payload: oldPayload });
                }

            };
            reader.onprogress = function (e) {
                setTimeout(() => {
                    setIsReaderLoading(99);
                }, 1000);
                setTimeout(() => {
                    setIsReaderLoading(100);
                }, 1200);
            };

            // 使用 readAsDataURL 將圖片轉成 Base64
            reader.readAsDataURL(curFile);
        }

        if (getInputName === 'titleInput') {
            reduxDispatch({ type: TITLE_LENGTH_ERROR, payload: getInputLength > 30 });
            dispatch({ type: 'TITLE_INPUT', payload: getInputValue });
            dispatch({ type: 'TITLE_CHAR_NUMBER', payload: getInputLength });
            if (findNewTag) {
                findNewTag.title = getInputValue;
                reduxDispatch({ type: NEW_TAG_INPUT, payload: findNewTag });
            }

            if (!findNewTag) {
                const oldPayload = {
                    tagId: tag.id,
                    inputValue: getInputValue,
                };
                reduxDispatch({ type: OLD_TAG_TITLE, payload: oldPayload });
            }
        }

        if (getInputName === 'textareaInput') {
            reduxDispatch({ type: DESC_LENGTH_ERROR, payload: getInputLength > 300 });
            dispatch({ type: 'TEXTAREA_INPUT', payload: getInputValue });
            dispatch({ type: 'TEXTAREA_CHAR_NUMBER', payload: getInputLength });
            if (findNewTag) {
                findNewTag.content = getInputValue;
                reduxDispatch({ type: NEW_TAG_CONTENT, payload: findNewTag });
            }

            if (!findNewTag) {
                const oldPayload = {
                    tagId: tag.id,
                    inputValue: getInputValue,
                };
                reduxDispatch({ type: OLD_TAG_CONTENT, payload: oldPayload });
            }

        }

        if (getInputName === 'urlInput') {
            dispatch({ type: 'URL_INPUT', payload: getInputValue });
            if (findNewTag) {
                findNewTag.url = getInputValue;
                reduxDispatch({ type: NEW_TAG_URL, payload: findNewTag });
            }

            if (!findNewTag) {
                const oldPayload = {
                    tagId: tag.id,
                    inputValue: getInputValue,
                };
                reduxDispatch({ type: OLD_TAG_URL, payload: oldPayload });
            }
        }

    };

    const handleEditPanelSubmit = async (data) => {
        setIsLoading(true);
        const getTargetHotspot = document.querySelector(`#${targetHotspotId}`);
        const getHotspotPosition = getTargetHotspot.getAttribute('data-position');
        const getHotspotNormal = getTargetHotspot.getAttribute('data-normal');
        const formTitle = data.titleInput.length === 0 ? '我是標題' : data.titleInput;
        const checkImageStatus = data[`tagImage-${orderNumber}`] === '';

        const body = {
            content: data.textareaInput,
            contentTitle: '',
            dataNormal: getHotspotNormal,
            dataPosition: getHotspotPosition,
            title: formTitle,
            url: data.urlInput,
            icon: checkImageStatus ? '' : tag.icon,
        };

        // 判斷是否有圖片再決定是否要上傳
        // 需要判斷圖片是否有更新
        const uploadImage = async (tagId) => {

            if (typeof data[`tagImage-${orderNumber}`] === 'object') {
                let formData = new FormData();
                formData.append('file', data[`tagImage-${orderNumber}`], data[`tagImage-${orderNumber}`].name);
                const response = await postUploadTagImage(formData, projectId, modelId, tagId);
                if (!response.result && response.status === 'failed') {
                    handleErrorResponse('type14');
                }
            }
            return;
        };

        if (variables.tagId) {

            const removeEditingTag = store.tagPanel.editingTags.filter(tag => tag !== variables.tagId);
            const response = await postEditTag(body, projectId, modelId, variables.tagId);
            if (!response.result && response.status === 'failed') {
                handleErrorResponse('type14');
            }

            reduxDispatch({ type: REMOVE_EDITING_TAG, payload: removeEditingTag });
            reduxDispatch({ type: CLEAR_OLD_TAG_PANEL, payload: [] });
            if (response.status === 'success') {
                if (tag.imageFile) {
                    await uploadImage(variables.tagId);
                }
                await refetchModelList(projectId);
            }
        }

        if (!variables.tagId) {
            const response = await postCreateTag(body, projectId, modelId);
            if (!response.result && response.status === 'failed') {
                handleErrorResponse('type14');
            }

            reduxDispatch({ type: CLEAR_NEW_TAG_PANEL, payload: [] });
            if (response.status === 'success') {
                await uploadImage(response.tag.id);
                await refetchModelList(projectId);
            }
        }

        setIsLoading(false);
        setIsSaved(true);
        setTimeout(() => {
            setIsSaved(false);
            setHasError(false);
        }, 3000);
    };

    const handleDeleteImage = () => {
        setIsReaderLoading(null);
        setImageName(null);
        setModalSetting({
            ...modalSetting,
            show: true,
            title: modelName,
            type: 'type27',
            handleConfirm: () => {
                let num = orderNumber - 1;
                // console.log(store.tagPanel.oldTags);
                field.onChange('');
                dispatch({ type: 'FILE_IMAGE_64', payload: '' });
                const oldPayload = {
                    tagId: store.tagPanel.oldTags[num].id,
                    inputValue: '',
                    filename: '',
                };
                reduxDispatch({ type: OLD_TAG_DELETE_IMAGE_FILE, payload: oldPayload });
            },
        });
    };

    const isEditinOldTag = store.tagPanel.editingTags.indexOf(tag.id) !== -1;
    return (
        <StyledEditPanel
            id={`editPanel-${orderNumber}`}
            className={`editPanel ${sectionType}`}
            onClick={(e) => e.stopPropagation()}
            onMouseEnter={(e) => e.stopPropagation()}
            onMouseLeave={(e) => e.stopPropagation()}
            onMouseMove={(e) => e.stopPropagation()}
            onMouseDown={(e) => e.stopPropagation()}
            onMouseUp={(e) => e.stopPropagation()}
            style={{ display: 'none' }}
            progress={isReaderLoading}
        >
            <form
                autoComplete="off"
                onSubmit={handleSubmit(handleEditPanelSubmit)}
            >
                <div className="actionPanel flexEndCenter">
                    {
                        isEditinOldTag && (
                            <div className="editingText flexStartCenter">
                                <ExclamationIcon />
                                {t('unsavedPrompt')}
                            </div>
                        )
                    }

                    <DeleteIcon className="deleteIconTag" onClick={handleDeleteHotspot(orderNumber, tag)} />
                    {/* <button 
                        type="submit" 
                        className="saveBtn" 
                        data-target-id={`hotspot-${orderNumber}`}
                        disabled={isSaved}
                    >
                        {isLoading && <div className="lds-dual-ring"></div>}
                        {!hasError && isSaved && <BsCheckLg />}
                        儲存
                    </button> */}
                </div>
                <input
                    autoFocus
                    name="titleInput"
                    type="text"
                    className="titleInput"
                    placeholder={t('tagTitle')}
                    value={variables.titleInput}
                    {...register('titleInput')}
                    onChange={handleInputChange}
                    autoComplete="off"
                />

                <div className={clsx(
                    'titleCharNumber',
                    {
                        ['redText']: variables.titleCharNumber > 30,
                        ['flexBetweenCenter']: variables.titleCharNumber > 30,
                    },
                )}>
                    {errors?.titleInput?.type === 'required' && <span>{t('enterTitlePrompt')}</span>}
                    {variables.titleCharNumber > 30 && <span>{t('wordLimitPrompt')}</span>}
                    <span>{variables.titleCharNumber}/30</span>
                </div>

                <textarea
                    name="textareaInput"
                    className="textArea"
                    placeholder={t('tagDescription')}
                    value={variables.textareaInput}
                    {...register('textareaInput')}
                    onChange={handleInputChange}
                />

                <div className={clsx('textAreaCharNumber', {
                    ['redText']: variables.textareaCharNumber > 300,
                    ['flexBetweenCenter']: variables.textareaCharNumber > 300,
                })}>
                    {variables.textareaCharNumber > 300 && <span>{t('wordLimitPrompt')}</span>}
                    <span>{variables.textareaCharNumber}/300</span>
                </div>
                {
                    variables && isReaderLoading !== null && isReaderLoading < 100 && <div className="isLoadingPic">
                        <div className="title">上傳中...</div>
                        <div className="text">
                            <div className="name">
                                {imageName}
                            </div>
                            <div className="num">{isReaderLoading}%</div>
                        </div>
                        <div className="progressBar">
                            <div className="bar"></div>
                        </div>
                    </div>
                }
                <label htmlFor={`tagImage-${orderNumber}`} className="uploadLabel flexCenter flex-column">

                    <div className="">
                        <p>{t('uploadImagePrompt')}</p>
                        <div className="uploadBtn flexCenter">
                            <AiOutlineCloudUpload />
                            <span>{t('upload')}</span>
                        </div>
                    </div>

                    {
                        variables.fileImage64 && isReaderLoading == 100 && (
                            <img
                                className="previewImage"
                                src={variables.fileImage64 || ''}
                                alt="file image 64"
                                draggable='false'
                            />
                        )
                    }


                    <input
                        type="file"
                        id={`tagImage-${orderNumber}`}
                        name={`tagImage-${orderNumber}`}
                        control={control}
                        {...register(`tagImage-${orderNumber}`)}
                        onChange={(e) => {
                            handleInputChange(e);
                            field.onChange(e.target.files[0]);
                        }}
                    />
                </label>

                {
                    variables.fileImage64 && (
                        <div className="imageActionPanel flexStartCenter">
                            <div className="deleteIconPanel flexCenter">
                                <DeleteIcon className="deleteIconImage" onClick={handleDeleteImage} />
                            </div>

                            <div className="updateIconPanel flexCenter">
                                <label htmlFor={`tagImage-64-${orderNumber}`}>
                                    <UpdateIcon className="updateIconImage" />
                                    <input
                                        type="file"
                                        id={`tagImage-64-${orderNumber}`}
                                        name={`tagImage-64-${orderNumber}`}
                                        control={control}
                                        {...register(`tagImage-64-${orderNumber}`)}
                                        onChange={(e) => {
                                            handleInputChange(e);
                                            field.onChange(e.target.files[0]);
                                        }}
                                        style={{
                                            display: 'none',
                                        }}
                                    />
                                </label>
                            </div>

                        </div>
                    )
                }

                <input
                    type="text"
                    name="urlInput"
                    id={`urlInput-${orderNumber}`}
                    className="urlInput"
                    placeholder={t('tagUrl')}
                    value={variables.urlInput}
                    {...register('urlInput')}
                    onChange={handleInputChange}
                />
            </form>
        </StyledEditPanel>
    );
};

export default EditPanel;
