import axioApi, { setHeader } from 'api/root.api';

import Cookies from 'js-cookie';

export const postAnimation = async (projectId, modelId, body) => {
    try {
        const urlEnd = `/editor/project/v1/${projectId}/${modelId}/animationCard`;
        const token = Cookies.get('token');
        setHeader('Authorization', `Bearer ${token}`);
        const response = await axioApi.post(urlEnd, body);
        const result = response.data;
        return result;
    } catch (err) {
        return {
            result: false,
            status: 'failed',
        };
    }
};