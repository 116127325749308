import axioApi, { setHeader } from 'api/root.api';

export const getProjectOne = async (projectId, token)=>{
    try {
        const urlEnd = `/editor/project/v1/${projectId}`;
        // setHeader('Access-Control-Allow-Origin', '*');
        setHeader('Authorization', `Bearer ${token}`);
        const response = await axioApi.get(urlEnd);
        const result = response.data;
        return result;
    } catch (err) {
        return 'getProjectOne failed';
    }
};