import styled from 'styled-components';

export const StyledObjectTagEditPanel = styled.div`
    padding: 25px;
    width: 100%;
    height: 100%;

    .transparent {
        color: transparent !important;
    }


    .tabPanel {
        margin-bottom: 20px;
        width: 100%;
        height: 40px;
    }

    .tab {
        padding: 10px;
        flex: 1;
        color: rgba(255, 255, 255, 0.2);
        border-bottom: 2px solid rgba(255, 255, 255, 0.2);
    }

    .tab.active {
        color:  rgba(255, 255, 255, 0.87);
        border-bottom: 2px solid rgba(255, 255, 255, 0.87);
    }

    .defaultAddTag {
        margin-top: 110px;
        width: 100%;
        height: auto;
        color: rgba(255, 255, 255, 0.5);

        svg {
            margin-bottom: 18px;
            font-size: 30px;
        }
    }
    .closeTag{
        opacity: 0.2;
    }

    .switchPanel {
        width: 100%;
        height: 20px;

        .name{
            font-size: 14px;
            color: rgba(255,255,255,0.6);
        }
    }

    .tagContainer {
        position: relative;
        margin-top: 14px;
        width: 100%;
        height: calc(100% - 140px);
        overflow: auto;

        .hoverMyTags {
            &:hover {
                background: rgba(0,0,0, 0.05);
                cursor: pointer;
            }
        }

        .myTags {
            position: relative;
            margin-top: 10px;
            padding: 6px 12px;
            width: 100%;
            height: 36px;
            color: #fff;
            background: #1B1B1B;
            border: 1px solid rgba(255, 255, 255, 0.12);
            border-radius: 2px;

            

            .tagNumber {
                margin-right: 8px;
                width: 24px;
                height: 24px;
                background: rgba(255, 255, 255, 0.3);
                border-radius: 50%;
            }

            .disabledTagNumber {
                background: rgba(255, 255, 255, 0.2) !important;
                color: rgba(255, 255, 255, 0.38) !important;
            }

            .tagTitle {
                width: 155px;
                vertical-align: middle;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: ${props => props.disableTag ? 'rgba(255, 255, 255, 0.2)' : '#fff'};
            }

            .deleteIcon {
                display: none;
            }

            .showDeleteIcon {
                padding:  0 0 5px 0;
                position: absolute;
                display: block;
                right: 20px;
                width: 24px;
                height: 24px;

                &:hover {
                    background: rgba(255, 255, 255, 0.12);
                    border-radius: 4px;
                    cursor: pointer;
                }

                svg {
                    position: absolute;
                    bottom: 1px;

                    path {
                            fill: rgba(255, 255, 255, 0.6);
                    }
                }

            }
        }

        .exclamationIcon {
            position: absolute;
            top: -10px;
            right: -2px;
            font-size: 20px;
        }

        .unsaveObjectTooltip {
            display: none;
            position: absolute;
            right: -130px;
            width: 100px;
            height: 30px;
            background: #121212;
            border-radius: 4px;
            z-index: 500;

            .arrowLeft {
                position: absolute;
                left: -7px;
                width: 0; 
                height: 0; 
                border-top: 10px solid transparent;
                border-bottom: 10px solid transparent; 
                border-right:10px solid #121212; 
            }
        }

        .showEditingToolTip {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
        }
    }
`;