// api
import axioApi, { setHeader } from 'api/root.api';

export const usdzUpload = async (body, token, projectId, modelId, handleUploadBar)=>{
    const urlEnd = `/editor/project/v1/${projectId}/${modelId}/uploadUsdz`;
    setHeader('Authorization', `Bearer ${token}`);
    setHeader('Content-Type', 'multipart/form-data');
    const response = await axioApi.post(urlEnd, body, {
        onUploadProgress: progressEvent => {
            handleUploadBar(Math.round((progressEvent.loaded / progressEvent.total) * 100));
        },
    });
    const result = response.data;
    return result;
};