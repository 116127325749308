// styles
import { StyledMobilePopup } from './StyledMobilePopup';

// images
import { ReactComponent as Loading } from './images/Loading.svg';
import { ReactComponent as Oops } from './images/Oops.svg';
import { ReactComponent as Success } from './images/Success.svg';

// i18n
import { useTranslation } from 'react-i18next';

const MobilePopup = ({ type }) => {

    const { t } = useTranslation();

    let popupType;
    const isLoading = type === '偵測裝置中';

    switch (type) {
        case '偵測裝置成功':
            popupType = {
                icon: <Success />,
                title: t('mobilePopup.detectDeviceSuccessfully'),
                description: t('mobilePopup.onlyForArProjection'),
            };
            break;
        case 'QRCode已失效':
            popupType = {
                icon: <Oops />,
                title: t('mobilePopup.qrcodeIsInvalid'),
                description: '',
            };
            break;
        case '偵測裝置中':
            popupType = {
                icon: <Loading />,
                title: t('mobilePopup.detectingDevice'),
                description: t('mobilePopup.onlyForArProjection'),
            };
            break;
        case '無法使用':
            popupType = {
                icon: <Oops />,
                title: t('mobilePopup.canNotUse'),
                description: t('mobilePopup.activeArOnIos'),
            };
            break;
        default:
            popupType = {
                icon: '',
                title: '',
                description: '',
            };
    }

    return (
        <StyledMobilePopup>
            <div className='mobile_popup'>
                <div className={`mobile_popup_icon ${isLoading && 'mobile_popup_loading'}`}>
                    {popupType.icon}
                </div>
                <div className='mobile_popup_title'>
                    {popupType.title}
                </div>
                <div className='mobile_popup_description'>
                    {popupType.description}
                </div>
            </div>
        </StyledMobilePopup>
    );
};

export default MobilePopup;