import styled from 'styled-components';

export const StyledNavTop = styled.div`
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 24px;
    padding-right: 24px;
    width: 100%;
    height: 56px;
    z-index: ${props => props.theme.zIndex.LayerTen};
    background: linear-gradient(79.78deg, #ED0973 0%, #FF744E 100%);
    .stopMask{
        position: fixed;
        left:0;
        top:0;
        z-index: ${props => props.theme.zIndex.LayerTen};
        width: 100vw;
        height: 100vh;
    }
    .logo {
        margin-right: 24px;
        font-size: 24px;
        color: #fff;
        &:hover{
            opacity: 0.7;
        }
        svg {
            path {
                fill: #fff;
            }
        }

        &:hover {
            cursor: pointer;
        }
    }

    .package {
        padding: 4px 16px;
        background: rgba(255, 255, 255, 0.3);
        border-radius: 40px;
        color: #fff;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.02em;
        white-space: nowrap;
        min-width:75px;
        min-height:32px;
    }

    .centerPanel {
        font-weight: 500;
        font-size: 16px;
        color: #FFFFFF;
        margin-left:${props => props.isPreview ? '185px' : '0px'};
    }
    .rightPanel{
        > .previewBtn, .publishBtn {
            cursor: pointer;
        }

        a {
            text-decoration:none;
        }
    }
    .saveBtn, .previewBtn {
        padding: 0 24px;
        height: 42px;
        line-height: 42px;
        display: inline-block;
        background: rgba(255, 255, 255, 0.2);
        border: 1px solid rgba(255, 255, 255, 0);
        border-radius: 4px;
        color: #fff;
        font-weight: 500;
        &:hover{
            background: rgba(255, 255, 255, 0.4);
            cursor: pointer;
        }
    }

    .saveBtn {
        margin-right: 24px;
        svg{
            margin-right: 5px;
            font-size: 16px;
            position: relative;
            top:-2px;
        }
        .lds-dual-ring {
            display: inline-block;
            width: 25px;
            height: 25px;
            margin-right: 5px;
        }
        .lds-dual-ring:after {
            position: relative;
            top:8px;
            content: " ";
            display: block;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 2px solid #fff;
            border-color: #fff transparent #fff transparent;
            animation: lds-dual-ring 1.2s linear infinite;
        }

    }

    @keyframes lds-dual-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    .previewBtn {
        margin-right: 24px;
        svg {
            margin-right: 12px;
            font-size: 24px;
            position: relative;
            top:-2px;
        }
    }

    .publishBtn {
        padding: 8px 24px;
        background: rgba(255, 255, 255, 0.87);
        border: 1px solid rgba(255, 255, 255, 0.87);
        border-radius: 4px;
        font-weight: 500;
        position: relative;
        svg {
            margin-right: 12px;
            font-size: 24px;
        }
    }
    .loadingDefault{
        opacity:0.3;
        cursor:default !important;
    }
    .disabledSaveBtn {
        background: rgba(255, 255, 255, 0.2);
        color: rgba(255, 255, 255, 0.38);
        border: 0px solid rgba(255, 255, 255, 0.87);

        &:hover {
            background: rgba(255, 255, 255, 0.2);
            cursor: not-allowed;
        }
    }
`;