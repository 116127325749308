import styled from 'styled-components';

export const StyledEnvLight = styled.div`
    padding: 10px 0px;
    width: 100%;
    height: 100%;


    .tabPanel {
        width: 100%;
        height: 40px;
    }

    .tab {
        padding: 10px 0 0 0;
        flex: 1;
        color: rgba(255, 255, 255, 0.2);
        padding-left: 20px;
        cursor: pointer;
        text-align:left;
    }

    .tab.active {
        color:  rgba(255,255,255,0.6);
        font-size: 14px;
    }
    .grid,.uploadHDR{
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 116px 116px;
        grid-gap: 10px;
        margin:0 20px 18px 20px;
        .box{
            cursor: pointer;
            &:hover{
                .img {
                    border: 1px solid rgba(255, 255, 255, 0.8);
                    border-radius: 4px;
                }
                
                .name{
                    color:rgba(255, 255, 255, 0.8);
                }
            }
            .img{
                position: relative;
                width: 116px;
                background-color:rgba(255, 255, 255, 0.2);    
                height: 87px;
                display: flex;
                justify-content: center;
                align-items: center;
                .check{
                    position: absolute;
                    top: 30px;
                    left: 45px;
                    opacity: 0;
                }
            }
            .name{
                padding-top: 5px;
                color:rgba(255,255,255,0.5);
                font-size: 12px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                text-transform: capitalize;
            }
        }
        .active{
            .img{
                .check{
                    opacity: 1;
                }
            }
            .name{
                color:rgba(255,255,255,1);
            }
        }
        .hh{
            position: relative;
            width: 100%;
            .title{
                color: rgba(255,255,255,0.87);
                font-size:14px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            input[type="checkbox"]{
                display: none;
            }
            input[type="checkbox"]:checked + label .checkboxCustom{
                background-color: #8858C8;
                border: 1.4px solid #8858C8;
                img{
                    opacity: 1;
                }
            }
            .checkboxCustom{
                width: 15px;
                height: 15px;
                border: 1.4px solid rgba(255, 255, 255, 0.2);
                border-radius: 1.75px;
                img{
                    opacity: 0;
                    position: relative;
                    top: -5px;
                    left: 2px;
                }
            }
        }
        .uploadPic{
            width: 100%;
            height: 131px;
            border: 1px dashed rgba(255, 255, 255, 0.12);
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
            flex-wrap: wrap;
            background-color: #1b1b1b;
            position: relative;
            margin-top: 10px;
            cursor: pointer;
            &:hover{
                background-color: rgba(0,0,0,0.05);
                border:1px solid  rgba(255,255,255,0.12);
                .fileBtn{
                    background: rgba(255, 255, 255, 0.6);
                }
            }
            .word{
                width: 100%;
                text-align:center;
                font-size: 12px;
                color: rgba(255, 255, 255, 0.5);
                padding-bottom: 10px;
            }
            .fileBtn{
                width: 93px;
                height: 32px;
                background: rgba(255, 255, 255, 0.38);
                border-radius: 40px;
                text-align:center;
                line-height: 32px;
                color: #fff;
                font-size: 14px;
                cursor: pointer;
                font-weight: normal;
                .icon{
                    font-size: 20px;
                    padding-right: 5px;
                    cursor: pointer;
                }
            }
        }
        .modelList{
            width: 100%;
            margin-bottom: 20px;
            .pic{
                width: 100%;
                height: 135px;
                object-fit: cover;
                object-position: center center;
                border:1px solid  rgba(255,255,255,0.12);
            }
            .picOpa{
                opacity: 0.6;
            }
            .ctrlBar{
                color: rgba(255,255,255,0.6);
                padding-top: 5px;
                .icon{
                    cursor: pointer;
                    padding:2px 5px;
                    margin-right: 5px;
                    &:hover{
                        background-color: rgba(255, 255, 255, 0.12);
                        border-radius: 4px;
                    }
                }
            }
        }
        input[type="file"]{
            display: none;
        }
    }
    .uploadHDR{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
`;