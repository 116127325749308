import React from 'react';

// styled components
import { StyledLoadingIcon } from './StyledLoadingIcon';

// react icons
import { MdOutlineDownloading } from 'react-icons/md';

const LoadingIcon = () => {
    return (
        <StyledLoadingIcon>
            <MdOutlineDownloading className="loadingIcon" />
        </StyledLoadingIcon>
    );
};

export default LoadingIcon;
