import React, { useState, useEffect } from 'react';

//redux
import { useSelector } from 'react-redux';

//styled components
import { StyledAnimateController } from './StyledAnimateController';

//i18n
import { useTranslation } from 'react-i18next';

// image
import check from './images/check.svg';

// plugins
import clsx from 'classnames';


const AnimateController = ({ animatePlayStatus, currentPercent, onScrub, handlePlayStatus, setShowAnimateHidden, currentPlayTime, showAnimateHidden, handleHoverTooltips, setShowTootltip, showTootltip, handleChangeAnimate, tootltipItemName, tootltipItemTop, handleshowTooltipTime, setShowTootltipTime, showTootltipTime, tootltipTime, tootltipTimePositionX, setCurrentPlayTime, handleAfterScrub }) => {
    const { t } = useTranslation();
    const store = useSelector(store => store);

    return (
        <StyledAnimateController animatePlayStatus={animatePlayStatus} showAnimateHidden={showAnimateHidden} showTootltip={showTootltip}>
            <div className="animation_conainer">
                <div className="animation_bar">
                    <input
                        type="range"
                        step="0.01"
                        value={currentPercent}
                        min="0"
                        max="100"
                        onMouseMove={(e) => { handleshowTooltipTime(e); }}
                        onClick={() => { setCurrentPlayTime(tootltipTime); }}
                        onMouseLeave={() => { setShowTootltipTime(false); }}
                        onMouseUp={handleAfterScrub}
                        onChange={(e) => { onScrub(e.target.value); }}
                        style={{ backgroundSize: `${currentPercent}% 100%` }} />
                    {
                        showTootltipTime &&
                        <div className="animation_bar_tooltip" style={{ left: `${tootltipTimePositionX}px` }}>
                            {tootltipTime}
                        </div>
                    }
                </div>
                <div className="animation_control">
                    <div className="animation_control_icon_box" onClick={() => { handlePlayStatus(); }}>
                        <div className="icon"></div>
                    </div>
                    <div className="animation_control_chose" onClick={() => { setShowAnimateHidden(!showAnimateHidden); }}>
                        <div className="animation_control_chose_icon">
                        </div>
                        <div className="animation_control_chose_name">
                            {store.animation.currentAnimate?.name}
                        </div>
                    </div>
                    <div className="animation_control_duration">
                        {currentPlayTime}
                    </div>
                </div>
                {
                    store.animation.animateList && showAnimateHidden &&
                    <div className="animation_hidden_list" onMouseLeave={() => { setShowAnimateHidden(false); }}>
                        {store.animation.animateList.map((item) => {
                            return (
                                item.ownSwitch && <div
                                    className={clsx('animation_hidden_list_item', {
                                        'currentPlay': item.name === store.animation.currentAnimate.name,
                                    })}
                                    key={item.name}
                                    onClick={() => { handleChangeAnimate(item); }}>
                                    <div className="check">
                                        {
                                            store.animation.currentAnimate.name === item.name && <img src={check} />
                                        }
                                    </div>
                                    <div className="name" onMouseEnter={(e) => { handleHoverTooltips(item.name, e); }} onMouseLeave={() => setShowTootltip(false)}>{item.name}</div>
                                </div>
                            );
                        })}
                    </div>
                }
                {
                    <div className="animation_hidden_list_tooltip" style={{ top: `${tootltipItemTop}px` }}>
                        {tootltipItemName}
                    </div>
                }
            </div>
        </StyledAnimateController>
    );
};

export default AnimateController;